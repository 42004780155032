
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSats, setsatsCss, setSatsScore } from "../redux/questionSlice";
import { Arithmetic } from "./arithmetic";
import { answerSet } from "./arithmetic";
import { orderUpto8 } from "./reasoning";


const Pre = () =>{ window.scrollTo({top: 0, left: 0,});
    return (
        <div>
          </div>
    )
}

const Post = ()=>{
    const dispatch = useDispatch()
   
    const score = useSelector((state) => state.questionSet.satsScore)
    
    return (
        <div>


        <div className='score'>Score: {score}</div>
        </div>
    )
}

const Reasoning1 = ()=>{

    return (
        <div className='sats'>
            Reasoning1
        </div>
    )
}

const English1 =()=>{
    return (
        <div className='sats'>
            English1
        </div>
    )
}

const Arithmetic1 = ()=> {
    return (
        <div className='sats'>
            Arithmetic1
        </div>
    )
}
const Reasoning = ()=>{

    return (
        <div className='sats'>
            Reasoning
        </div>
    )
}

const English =()=>{
    return (
        <div className='sats'>
            English
        </div>
    )
}

const Science = ()=> {
    return (
        <div className='sats'>
            Science
        </div>
    )
}
// landing page  choose sats tests
export function Sats (){
    const dispatch = useDispatch()
const choice  = useSelector((state) => state.questionSet.sats)


// arrays of optionss
 const sats = [{subject: 'Arithmetic Key Stage 1', image: ''}, {subject: 'Maths reasoning Key Stage 1', image: ''}, {subject: 'English Key Stage 1',
  image: ''}, {subject: 'Arithmetic Key Stage 2', image: ''}, {subject: 'Maths reasoning Key Stage 2', image: ''}, {subject: 'English Key Stage 2',
   image: ''} //, {subject: 'Science Key Stage 2', image: ''}
]

//let output

const ks1Englsh = [ 
    {subject: 'Add one to one digit', image: ''},  {subject:  'Add two to one digit' ,  image: ''} ]
  
const ks2English = [ 
    {subject: 'Determiner' ,image: ''},  {subject: 'conjunction',image: ''},  {subject: 'preposition',image: ''},  {subject: 'pronoun',image: ''},  
    {subject: 'semicolon',image: ''}, {subject: 'comma', image:''},  {subject: 'hyphen',image: ''},  {subject: 'colon',image: ''},  {subject: 'formal',image: ''},  {subject: 'noun',image: ''},  
    {subject: 'statement',image: ''},  {subject: 'synonym',image: ''},  {subject: 'antonym', image: ''},  {subject: 'clauses',image: ''},  
    {subject: 'questions',  image: ''} ]
          
const arithmetic =  [
 {subject: 'Add one and one digit', image: ''}, {subject: 'Add two and one digit', image: ''}, {subject: 'Add two and two digits', image: ''}, 
 {subject: 'Add two and three digits', image:''},{subject: 'Add two and four digits', image: ''}, {subject: 'Add 3 numbers', image: ''},
 {subject: 'Minus one from one digit', image: ''},{subject: 'Minus one from two digits', image: ''} , 
  {subject: 'Minus large numbers', image: ''},  {subject: 'Minus 2 from 3 digits', image: ''},  {subject: 'Minus 3 from 4 digits', image: ''}, 
  {subject: 'minus 3 items', image: ''},        {subject: 'Multiply 1 by 2 digits', image: ''}, {subject: 'Multiply 1 by 3 digits', image: ''}, 
  {subject: 'Multiply 2 by 3 digits', image:''},{subject: 'Multiply 2 by 4 digits', image: ''}, {subject: 'Multiply by 1000', image: ''}, 
  {subject: 'Multiply 3 items', image: ''},     {subject: 'Cube', image: ''},                   {subject: 'Divide 3 digits by 1', image: ''}, 
  {subject: 'Divide 4 digits by 1', image: ''}, {subject: 'Divide 2 digits by 2', image: ''},   {subject: 'Divide 3 digits by 2', image: ''}, 
  {subject: 'Divide 4 digits by 2', image: ''}, {subject: 'Percent Multiply', image: ''},       {subject: 'Decimal Add', image: ''}, 
  {subject: 'Decimal Subtract easy', image: ''},{subject: 'Decimal Subtract hard', image: ''},  {subject: 'Decimal Divide easy', image: ''} , 
  {subject: 'Decimal Divide hard', image: ''},  {subject: 'Decimal Multiply easy', image: ''},  {subject: 'Decimal Multiply hard', image: ''}, 
  {subject: 'BODMAS', image: ''} ,              {subject: 'Add fractions easy', image: ''},     {subject: 'Add fractions hard',  image: ''},  
  {subject: 'Minus fractions easy',  image: ''},{subject: 'Minus fractions hard' ,  image: ''}, {subject: 'Multiply fractions',  image: ''},  
  {subject: 'Divide fractions',  image: ''}  ]
  
const keyStageOne = [ 
 {subject: 'Add one to one digit', image: ''},  {subject:  'Add two to one digit' ,  image: ''}, {subject: 'Add two to two digits' , image: ''}, 
 {subject: 'Add three items' , image: ''},  {subject: 'Subtract one digit from one', image: ''}, {subject: 'Subtract one digit from two', image: ''}, 
 {subject: 'Subtract two digits from two', image: ''}, {subject: 'Subtract two digits from three', image: ''}, {subject: 'Reverse operation',  image: ''},  
 {subject: 'Multiply one digit by one', image: ''},  {subject:  'Multiply by 10', image: ''},  {subject: 'Multiply fractions by whole numbers',  image: ''}, 
 {subject: 'Divide two digits', image: ''} ]

 const ks1Reasoning = [ 
        {subject:  'Place values', image: ''}, {subject: 'Add and Subtract', image: ''}, {subject:  'Times and Divide', image: ''}, {subject:  'Fractions', image: ''}, 
    {subject:  'Measure',  image: ''}, {subject: 'Compare',  image: ''}, {subject: 'Money', image: ''}, {subject:  'Coins', image: ''}, 
    {subject:  'Change', image: ''}, {subject:  'Time',  image: ''}, {subject: 'Minutes Hours Days', image: ''}, {subject:  'Identify Shapes',  image: ''}, 
    {subject: '2D and 3D',  image: ''}, {subject: 'Missing Number',  image: ''}, {subject: 'Angles', image: ''}, {subject:  'Interpret Chart',  image: ''}, 
    {subject: 'Create Chart',  image: ''}, {subject: 'Compare Data', image: ''}]
  
 const reasoning = [
{subject: 'Order numbers', image: ''},          {subject: 'Round Whole Numbers', image: ''},{subject: 'Round Decimal Numbers',  image: ''}, 
{subject: 'Using Bodmas',  image: ''},          {subject: 'Word problems',  image: ''},     {subject: 'Estimating',  image: ''},        {subject: 'Common Factor',  image: ''}, 
{subject: 'Common Multiples',  image: ''},      {subject: 'Compare Fractions',  image: ''}, {subject: 'Order Fractions',  image: ''},  
{subject: 'Decimal Equivalents',  image: ''},   {subject: 'Percent Equivalents',  image: ''},{subject: 'Relative Size',  image: ''}, 
{subject: 'Calculate Percent', image: ''},      {subject:  'Shape Problems', image: ''},    {subject:  'Sharing Problema',  image: ''}, {subject: 'Formula',  image: ''}, 
{subject: 'Linear Sequences', image: ''},       {subject:  'Missing Numbers', image: ''},   {subject: 'Pairs in equations', image: ''}, 
 {subject: 'Converting units: length', image: ''}, {subject: 'Converting units: mass', image: ''}, 
{subject: 'Converting units: time',  image: ''},{subject: 'Converting units: volume', image: ''}, {subject: 'Converting units: miles and kilometres', image: ''}, 
{subject: 'Perimeter and Area', image: ''},     {subject: 'Area Of a Triangle', image: ''}, {subject: 'Area of a Parrallelogram' , image: ''}, 
{subject: 'Volume of a cube/cuboid', image: ''},{subject: 'Compare Volumes of Cuboids', image: ''}, {subject: 'Find an unknown angle', image: ''}, 
//{subject: 'Compare Polygons: area', image: ''}, {subject: 'Compare Polygons: length of side', image: ''}, {subject: 'Compare Polygons: Angles', image: ''}, {subject: 'Naming the parts of a Circle', image: ''}, {subject: 'Possible Combinations', image: ''}, 
//{subject: 'Vertically opposite angles', image: ''}, {subject:  'Translate a Shape',  image: ''}, 
//{subject: 'Pie charts', image: ''},             {subject:  'Line charts', image: ''},      
 {subject:  'Calculating the mean', image: ''} 
 ]

 const handleListClick = (e) => { console.log(e, e.subject) ; dispatch(setSats(e.subject) )}
 const handleSubListClick = (e) => { console.log(e, e.subject) ; dispatch(setSats(e.subject)) }

 const satsChoices = sats.map((entry)=> 
    <li key={entry}><div className='satsList ' onClick={()=> {handleListClick(entry)}} 
        src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list

 const ArithmeticChoices = arithmetic.map((entry)=> 
        <li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
            src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list

const keyStageOneChoices = keyStageOne.map((entry)=> 
            <li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
                src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list

const ReasoningChoices= reasoning.map((entry)=> 
<li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
    src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list

const KS1ReasoningChoices= ks1Reasoning.map((entry)=> 
    <li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
        src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list
        
const KS1EnglishChoices= ks1Englsh.map((entry)=> 
        <li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
            src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list
            
const KS2EnglishChoices= ks2English.map((entry)=> 
            <li key={entry}><div className='satsList ' onClick={()=> {handleSubListClick(entry)}} 
                src={entry.image} value ={entry.subject} >{entry.subject}</div></li>)  // generates the dynamic high list
                
                            
    
 if ( choice=== '') {return ( <div className="satsCategory"> Please choose a test: <ul className="list">  {satsChoices}  </ul><div className="list">
     <p className='chooseList'>Key Stage One Arithmetic:</p> <ul className='chooseListItems'> {keyStageOneChoices}</ul> 
     <p className='chooseList'>Key Stage One Reasoning:</p> <ul className='chooseListItems'> {KS1ReasoningChoices}</ul> 
     <p className='chooseList'>Key Stage One English:</p> <ul className='chooseListItems'> {KS1EnglishChoices}</ul> 
     
     <p className='chooseList'>Key Stage Two arithmetic:</p><ul className='chooseListItems' > {ArithmeticChoices} </ul> 
     <p className='chooseList'>Key Stage Two reasoning</p><ul className='chooseListItems'> {ReasoningChoices}</ul></div> 
     <p className='chooseList'>Key Stage Two English:</p> <ul className='chooseListItems'> {KS2EnglishChoices}</ul> 
     
     </div> ) }
 if (choice === 'Arithmetic Key Stage 1'){  return (<div><Pre/><Arithmetic/><Post/></div>)}
 if (choice === 'Maths reasoning Key Stage 1') { return (<div><Pre/><Arithmetic/><Post/></div>)}
 if (choice === 'English Key Stage 1')   {  return (<div><Pre/><Arithmetic/><Post/></div>)}
 if (choice === 'Arithmetic Key Stage 2'){ return (<div><Pre/><Arithmetic/><Post/></div>)}
 if (choice === 'Maths reasoning Key Stage 2') { return (<div><Pre/><Arithmetic/><Post/></div>)}
 if (choice === 'English Key Stage 2')   {  return (<div><Pre/><Arithmetic/><Post/></div>)}
 //if (choice === 'science Key Stage 2')   { return (<div><Pre/><Science/><Post/></div>)}

 const arithmeticArray = [ 
    'Add one and one digit',    'Add two and one digit',    'Add two and two digits',     'Add two and three digits',    'Add two and four digits', 
    'Add 3 numbers',            'Minus one from one digit',  'Minus one from two digits', 'Minus 2 from 3 digits',      'Minus 3 from 4 digits',   
    'Minus large numbers',      'minus 3 items',             'Multiply 1 by 2 digits',    'Multiply 1 by 3 digits', 
    'Multiply 2 by 3 digits',   'Multiply 2 by 4 digits',    'Multiply by 1000',          'Multiply 3 items',            'Cube', 
    'Divide 3 digits by 1',     'Divide 4 digits by 1',      'Divide 2 digits by 2',      'Divide 3 digits by 2',        'Divide 4 digits by 2', 
    'Percent Multiply',         'Decimal Add',                 'Decimal Subtract easy',   'Decimal Subtract hard',       'Decimal Divide easy',       
    'Decimal Divide hard',        'Decimal Multiply easy',    'Decimal Multiply hard',     'BODMAS', 'Add fractions easy', 'Add fractions hard',  
    'Minus fractions easy',     'Minus fractions hard' ,      'Multiply fractions',         'Divide fractions']
 for (let x = 0 ; x< arithmeticArray.length; x++) { if (choice === arithmeticArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}

 const keyStageOneArray = [ 'Add one to one digit', 'Add two to one digit' , 'Add two to two digits' ,'Add three items' , 'Subtract one digit from one',
    'Subtract one digit from two', 'Subtract two digits from two', 'Subtract two digits from three',  'Reverse operation', 
    'Multiply one digit by one', 'Multiply by 10', 'Multiply fractions by whole numbers', 'Divide two digits' ]

    for (let x = 0 ; x< keyStageOneArray.length; x++) { if (choice === keyStageOneArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}  

const reasoningArray = ['Order numbers', 'Round Whole Numbers', 'Round Decimal Numbers', 'Using Bodmas', 'Word problems', 'Estimating', 
'Common Factor', 'Common Multiples', 'Compare Fractions', 'Order Fractions', 'Decimal Equivalents', 'Percent Equivalents', 'Relative Size', 
'Calculate Percent', 'Shape Problems', 'Sharing Problema', 'Formula', 'Linear Sequences', 'Missing Numbers', 'Pairs in equations' ,
'Converting units: length', 'Converting units: mass', 'Converting units: time', 'Converting units: volume', 
'Converting units: miles and kilometres', 'Perimeter and Area', 'Area Of a Triangle', 'Area of a Parrallelogram' , 'Volume of a cube/cuboid',
'Compare Volumes of Cuboids', 'Find an unknown angle', 
//'Compare Polygons: area', 'Compare Polygons: length of side','Compare Polygons: Angles', 'Naming the parts of a Circle', 'Possible Combinations',
//'Vertically opposite angles', 'Translate a Shape', 'Pie charts', 'Line charts', 
'Calculating the mean']
for (let x = 0 ; x< reasoningArray.length; x++) { if (choice === reasoningArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}  


const KS1ReasonArray = [ 'Place values', 'Add and Subtract', 'Times and Divide', 'Fractions', 'Measure', 'Compare', 'Money', 'Coins',
    'Change', 'Time', 'Minutes Hours Days', 'Identify Shapes', '2D and 3D', 'Missing Number', 'Angles', 'Interpret Chart', 'Create Chart', 'Compare Data' ]
 for (let x = 0 ; x< KS1ReasonArray.length; x++) { if (choice === KS1ReasonArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}  

const KS1EnglishArray = [ 'Add one to one digit', 'Add two to one digit' ]
 for (let x = 0 ; x< KS1EnglishArray.length; x++) { if (choice === KS1EnglishArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}  

const KS2EnglishArray = [ 'determiner', 'conjunction', 'preposition', 'pronoun', 'semicolon', 'comma', 'hyphen', 'colon', 'formal', 'noun', 'statement', 'synonym', 'antonym', 'clauses', 'questions']
 for (let x = 0 ; x< KS2EnglishArray.length; x++) { if (choice === KS2EnglishArray[x]) {return (<div> <Pre/><Arithmetic/><Post/></div>)}}  


}



