import React from "react";
import {Points} from '../knowitcomponents/Points';
import {Navigation} from '../knowitcomponents/Navigation';
import User from "../components/user"; import Home from "../components/Home"; import { useNavigate } from "react-router-dom";

export  function Footer (){
    const navigate = useNavigate();
    const handlePrivacy =( ) => { navigate('./privacy')}

    return (
   <div className='footer'> KnowItRevise.com is provided by Know It All Ltd. A limted company registered in the UK. 
    <button className={"privacyButton"} onClick={()=>{handlePrivacy()}}>Privacy</button>
    </div>
    )
}