import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";

const Title = () => {
    const navigate = useNavigate();
    const handleHome = () => {navigate("/")}

    return (
     <div className="titleKIDiv" >
          <button className="titleKI" onClick={() => { handleHome(); }}>
           <h4 className="titleKnowIt">Know It</h4> 
           <h8 className='rapidRepeat'>Rapid Repeat, Learn: Know!</h8> 
           </button>
    </div>
      );
};

export default Title;
