import React, { useEffect, useState } from "react"; import { useSelector, useDispatch } from 'react-redux'; 
import { decrement, increment, answerToggle, removeNonQuestions, nextQuestion, end, knowIt, revise, revision, Switch, Start, fetchDataFromFirestore, setMasterId, setLevel } from '../redux/questionSlice'
import { ref, getDownloadURL } from "firebase/storage"; import { storage } from "../firebase-config"; import {fetchImage} from '../redux/userSlice'
import { add10Points, addPoints, addTwoPoints, addModule } from "../redux/userFireSlice"; import { auth } from "../firebase-config"; 
import { doc, updateDoc } from "firebase/firestore"; import { db } from "../firebase-config";import { ReviseList } from "../components/Login";
import { RulesOfPlay } from "./RulesOfPlay";

//import { SaveTheStoreToFirebase } from "../components/changeUserElements";

export async function SaveRevisionInFirestore(revise, points){
  const updateRef = doc(db, 'users', auth.currentUser.uid)
  await updateDoc( updateRef, { revise: revise, points: points  }, {merge:true});
   console.log("Document written with ID: ", auth.currentUser.uid);}


export async function SaveTheStoreToFirebase(modulesSet, points){
  console.log('here' + modulesSet, points)
  //const result = useSelector((state) => state.userFireSet);
  //console.log(result) 
  const updateRef = doc(db, 'users', auth.currentUser.uid)
  await updateDoc( updateRef, {
    // first: resultFirst,
    // userId: auth.currentUser.uid,
    points: points,
     //dateJoined: resultDateJoined,
     //lastLogin: resultLastLogin,
     //reviseList: resultReviseList,
     modulesCompleted: modulesSet,
     //testResults: resultTestResults
      }, {merge:true});
   console.log("Document written with ID: ", auth.currentUser.uid);}

export function Answer (){  const dispatch = useDispatch()
    let answer =   useSelector((state) => state.questionSet.answer)       ;let show =     useSelector((state) => state.questionSet.visibility)
    let question = useSelector((state) => state.questionSet.question)     ;let dataLoad = useSelector((state) => state.questionSet.start)
    const count = useSelector((state) => state.questionSet.value)         ;let End = useSelector((state) => state.questionSet.ends)
    let initialSet = useSelector ((state) => state.questionSet.dataSet)   
    let masterHigh = useSelector((state) => state.questionSet.masterHigh) ;let masterLow = useSelector((state) => state.questionSet.masterLow)
    let primarySubject =  useSelector((state) => state.questionSet.primarySubject)   ;let initialisedSet = [...initialSet]
    const KnowItRef  = useSelector((state) => state.questionSet.imageRef)
    let points = useSelector((state)=> state.userFireSet.points)
    //console.log(points)
 if (KnowItRef != ''){
  let KnowItR = "images/" + KnowItRef 
  let imageReference = ref(storage, KnowItR);
    getDownloadURL(ref(storage, KnowItR))
      .then((url) => {
       const img = document.getElementById('myimg');
        img.setAttribute('src', url);
        img.setAttribute('height', 200)
      })
      .catch((error) => { // Handle any errors 
        });}
  else {getDownloadURL(ref(storage, 'images/knowit.jpg'))
    .then((url) => {
     const img = document.getElementById('myimg');
      img.setAttribute('src', url);
      img.setAttribute('height', 200)
    })}

    let KnowItR = 'app/background.jpg'
    let imageReference = ref(storage, KnowItR);
      getDownloadURL(ref(storage, KnowItR))
        .then((url) => {
         const img = document.getElementById('successImg');
          img.setAttribute('src', url);
          img.setAttribute('height', '200px')
        
        })
        .catch((error) => { // Handle any errors 
          });

    let collection = {collection: masterHigh, document: masterLow}
    const handleNext= (e)=>{e.preventDefault(); dispatch(nextQuestion()) ; dispatch(addPoints(points))};  

    const handleStart= (e)=>{e.preventDefault(); dispatch(fetchDataFromFirestore(collection));   }
/*let textA, textQ
    if (question.length<35){textQ = "text4"; console.log(question.length, textQ)}
    else if (question.length<45){textQ = "text3"; console.log(question.length, textQ)}
    else if (question.length<60){textQ = "text2"; console.log(question.length, textQ)}
    else {textQ = "text1"; console.log(question.length, textQ)}

    if (answer.length<35){textQ = "text4"; console.log(answer.length, textA)}
    else if (answer.length<50){textQ = "text3"; console.log(answer.length, textA)}
    else if (answer.length<70){textQ = "text2"; console.log(quesanswertion.length, textA)}
    else {textQ = "text1"; console.log(answer.length, textA)} className={textQ}className={textA} 
*/
if(End){  return (<div className="answer"><h3>{primarySubject}</h3> <img id="successImg" ></img> 
  <h3>You have completed the set,</h3> <h2> please choose another topic</h2>
  <button> Well done</button></div>)}
if(dataLoad){ return (<div className="answer"> <button onClick={handleStart}>Start</button> </div>)}

if (show){    return (<div  className="answer"> <h3>{primarySubject}</h3> 
                      <img id="myimg" ></img> <h5 >Q: {question}</h5> 
                      <h4  >A: </h4>  <button onClick={handleNext}>Show</button> </div> )}
              return (<div  className="answer"> <h3>{primarySubject}</h3> 
                      <img id="myimg" ></img> <h5  >Q: {question}</h5> 
                      <h4 >A: {answer}</h4>  <button onClick={handleNext}>Next</button> </div> )} 

export function NextCategory(){ const dispatch = useDispatch()
  let masterId = useSelector((state) => state.questionSet.masterId)
  let catalogue = useSelector((state) => state.questionSet.master);
  
    let idNext = masterId + 1; if (idNext > catalogue.length) {idNext=0}
    let highNext = catalogue[idNext].HighLevelSubject
    let lowNext = catalogue[idNext].LowLevelCategory
    let idPrev = 0;  if (masterId>1){ idPrev = masterId -1}
    let highPrev = catalogue[idPrev].HighLevelSubject   
    let lowPrev = catalogue[idPrev].LowLevelCategory
    
    let collectionNext = {collection: highNext, document: lowNext }
    let collectionPrev = {collection: highPrev, document: lowPrev }
    const handleNextCat = () => { console.log(collectionNext) ; dispatch(setMasterId({id:idNext, cat: lowNext})); dispatch(setLevel(2)); dispatch(fetchDataFromFirestore(collectionNext))}
    const handlePrevCat = () => {dispatch(setMasterId({id: idPrev, cat: lowPrev})); dispatch(setLevel(2)) ; dispatch(fetchDataFromFirestore(collectionPrev))}
    return ( <section  > <button className="nextCategory" onClick={handleNextCat}>Previous Category</button>
                       <button className="nextCategory" onClick={handlePrevCat}>Next Category</button>  </section>  ) }

export  function KnowItBox (){ const dispatch = useDispatch()
  const count = useSelector((state) => state.questionSet.value)  
  let show =     useSelector((state) => state.questionSet.visibility)
 let modules = useSelector((state) => state.userFireSet.modulesCompleted)
  let description = useSelector((state) => state.questionSet.description)
  let dataSet = useSelector((state) => state.questionSet.dataSet)
  let reviseSet = useSelector((state) => state.questionSet.revise)
  let question = useSelector((state) => state.questionSet.question)
  let primarySubject =  useSelector((state) => state.questionSet.primarySubject) 
  let end = useSelector ((state) => state.questionSet.ends)
  let knowSet = [...dataSet];
  let revisionSet = [...reviseSet]
  let modulesSet = [ ...modules]
  let points = useSelector((state)=> state.userFireSet.points)
  const fireRevList = useSelector((state)=> state.userFireSet.reviseList )
let fireReviseList
if (fireRevList){
  fireReviseList= [...fireRevList]}
const masterId = useSelector((state) => state.questionSet.masterId)
  
  const handleKnowIt=()=>{ knowSet.splice( count, 1); 
    { dispatch(knowIt(knowSet)); if (!end){dispatch(addTwoPoints(points));} 
      if (dataSet.length <=1 && !end){
        modulesSet.push({module: primarySubject, date: Date(), id: masterId}); 
        dispatch(addModule(modulesSet));
        dispatch(add10Points(points));
        points = points +10
        SaveTheStoreToFirebase(modulesSet, points)
        console.log('sent');
      }}  }
  const handleRevise=(e)=>{ e.preventDefault(); console.log( dataSet, count, dataSet[count])
    revisionSet.push(dataSet[count]); dispatch(revise(revisionSet))}
  const handleRevision=(e)=>{e.preventDefault(); dispatch(revision(revisionSet))}
  const handleSaveRevision=(e)=>{e.preventDefault(); SaveRevisionInFirestore(revisionSet, points)}
  
  const handleLoadRevision = (e)=>{e.preventDefault(); console.log('reviselist start')
    const reviseList = fireReviseList.concat(revisionSet)
    dispatch(revise(reviseList))
    console.log('revise list sent'+ reviseList)}

   // const handleSwitch =(e) =>{e.preventDefault(); dispatch(Switch())}   <button className='revisionButtons' onClick={handleSwitch}>Switch</button>    

if (show){
  return (<div className="knowBox"> 
                <section className="knowBoxQuestions" >   <Answer />     <button className='knowAnswer' onClick={handleKnowIt}>Show</button>
                <button className='revisionButtons' onClick={handleRevise}>Show</button>
                <button className='revisionButtons' onClick={handleRevision}>Revision</button> 
                <button className='revisionButtons' onClick={handleSaveRevision}>Save Revision List</button>  
                <button className='revisionButtons' onClick={handleLoadRevision}>Load Revision List</button>             
                </section>
            <NextCategory />  
             <h2>{description}</h2>   
             <RulesOfPlay />
          </div>  )
}

else {
  return (<div className="knowBox"> 
                <section className="knowBoxQuestions" >   <Answer />     <button className='knowAnswer' onClick={handleKnowIt}>Know It</button>
                <button className='revisionButtons' onClick={handleRevise}>Revise</button>
                <button className='revisionButtons' onClick={handleRevision}>Revision</button> 
                <button className='revisionButtons' onClick={handleSaveRevision}>Save Revision List</button>  
                <button className='revisionButtons' onClick={handleLoadRevision}>Load Revision List</button>             
                </section>
            <NextCategory />  
             <h2>{description}</h2>   
             <RulesOfPlay />
          </div>  )
}






}
