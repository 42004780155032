import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import { auth } from "../firebase-config";
import { Routes, Route } from "react-router-dom";
import { KnowItBox } from "../knowitcomponents/KnowItBox";
import { Catalogue } from "../knowitcomponents/Catalogue";
import { useDispatch, useSelector } from "react-redux";
import { fetchToken } from "../redux/userSlice";

const ProtectedRoute = () => {
  const dispatch = useDispatch()
  dispatch(fetchToken)
 
const isAuthenticated = useSelector((state) => state.userSet.user.uid)
 if (isAuthenticated){
   console.log( 'login worked catalogue' + isAuthenticated)
    return <Catalogue />
    }
  
  console.log('login didnt work')
  return (
    <Navigate  to="/" />
   );
}

export default ProtectedRoute;
