import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { nextQuestion } from './questionSlice'

const autoSetSlice = createSlice({
  name: 'autoSet',
  initialState: { 
   auto: false, 
   intervalId: 'ho' ,
   counter: 0  
  },
  reducers: {    
    autoChange: (state) => { if (state.auto === true){ state.auto = false} else {state.auto = true}  },
      },
  extraReducers:   
    (builder) => { builder.addCase(toggleAuto.fulfilled, (state, action)=>{
      if ( action.payload === false){ state.auto = true}    
      else {state.auto = false
        state.counter = action.payload.counter
      clearInterval(action.payload.intervalId)}
     })},   
 } )

export const { autoChange } = autoSetSlice.actions
export default autoSetSlice.reducer

export const toggleAuto = createAsyncThunk( 'autoSet/fetchData', 
    async ( auto, thunkAPI) => { if (auto.auto === false){ 
      let counter = auto.counter
      let intervalId = setInterval( //assign interval to a variable to clear it.
      counter = counter +1
     
      , 1000)
      console.log(counter)
      return {counter: counter, intervalId: intervalId}
    } 
    else {
        
      return false
      }; //This is important
     
    }, [])

    
     
/*
import { toggleAuto } from "../redux/autoSlice"; import {autoSet, autoChange} from '../redux/autoSlice'
//const handleAuto = (e) => {e.preventDefault(); dispatch(toggleAuto({auto: auto, counter: counter, intervalId: autoInterval }));  }
<button onClick={handleAuto}>Auto Play</button>
let auto = useSelector((state) => state.autoSet.auto)
  let autoInterval = useSelector((state) => state.autoSet.intervalId)
  let counter = useSelector((state) => state.autoSet.counter)
console.log(auto, autoInterval, counter)
  
let interval
if (auto){ interval = setInterval(dispatch(nextQuestion()),10000)}
else {clearInterval(interval)}

*/

 