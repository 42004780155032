import { Container, Row, Col } from "react-bootstrap"; import { Routes, Route, Navigate } from "react-router-dom";  
import {AddData} from './knowitcomponents/addingData'
import { UserAuthContextProvider } from "./context/userAuthContext";  import "./App.css";  import Home from "./components/Home"; import Login from "./components/Login"; 
import Signup from "./components/Signup";  import {Header} from './knowitcomponents/Header'; import {RulesOfPlay } from './knowitcomponents/RulesOfPlay';   
import {KnowItBox} from './knowitcomponents/KnowItBox'; import {Catalogue} from './knowitcomponents/Catalogue'; 
import { useDispatch, useSelector } from "react-redux";  import {fetchDataFromFirestore, start, setLevel} from './redux/questionSlice'
import ProtectedRoute from "./components/ProtectedRoute";
import React, {useEffect} from "react"; 
import { login, logout, selectUser } from "./redux/userSlice";  
import {  onAuthStateChanged } from "firebase/auth"; import {auth} from './firebase-config';
import { useNavigate } from "react-router-dom";
import {Points} from './knowitcomponents/Points';
import {Navigation} from './knowitcomponents/Navigation'; import { ref, getDownloadURL } from "firebase/storage"; import { storage } from "./firebase-config";
import User from "./components/user"; 
import { Privacy } from "./components/privacy"; import {Sats} from './Sats/sats';
import {Account} from './components/account'; import {Footer} from './knowitcomponents/footer'
import {fetchUserFromFirestore} from './redux/userFireSlice'
import { ChangePassword,  ChangeName, ChangeSubject} from "./components/changeUserElements";
//import TimerApp from "./timer/components/TimerApp";
import { Welcome } from "./components/welcome";
import Title from "./knowitcomponents/title";
//ChangeEmail, ChangeLogin,

function App() {  
  const user = useSelector((state) => state.userSet.user); let userId
    
  useEffect(() => {
    onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        // user is logged in, send the user's details to redux, store the current user in the state
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
          })
        );
      } else {
        dispatch(logout());
      }
    });
  }, []); 
  
  const navigate = useNavigate();
  //const handlePrivacy =( ) => { navigate('./privacy')}
  //userId = useSelector((state) => state.userfireSet.uid)
  //if(userId === undefined){userId = ''}
  
  const dispatch = useDispatch(); dispatch(fetchDataFromFirestore({collection: 'Master', document: 'Master'})); 
   // if (auth.currentUser != undefined  ){ dispatch(fetchUserFromFirestore(auth.currentUser.uid))}
  let level = useSelector((state) => state.questionSet.level)

  let KnowItR = 'app/background1.jpg'
  let imageReference = ref(storage, KnowItR);
    getDownloadURL(ref(storage, KnowItR))
      .then((url) => {
       const img = document.getElementById('myimg2');
        img.setAttribute('src', url);
        img.setAttribute('height', '800px')
      
      })
      .catch((error) => { // Handle any errors 
        });

     return (
      <div className="appBody" >
        <img id='myimg2'/>
        <Container>
         <div className="headerKnowIt">
            <Navigation />
            <AddData />
            <User/>
            <Points/>
            <Home/>
            <div className="break"></div> 
            <Title/>
         </div>
         <div>
         </div>

           {!user ? (
             <UserAuthContextProvider>
               <div className="login">
                 <Routes>
                  
                   <Route path="/" element={<Login />} />
                   <Route path="/signup" element={<Signup />} />
                 </Routes> 
                 </div>
                 </UserAuthContextProvider>
              ) :  (
              <div className="midzone">
                <Routes>
                  <Route path ='' element={<Welcome /> }/>
                  <Route exact path='/catalogue' element={<ProtectedRoute />} />
                  <Route exact path='/privacy' element={<Privacy />} />
                  <Route  path='/account' element={<Account />}/>
                  <Route  path='/changePassword' element={<ChangePassword />} />
                  <Route  path='/changeName' element={<ChangeName />} />
            
                  <Route  path='/changeSubject' element={<ChangeSubject/>} />
                  <Route path='/sats' element={<Sats/>} />
               </Routes>
              
             </div> )}
          
          <Footer />
       </Container > 
    </div>) ; }
export default App;


/*
      <Route  path='/changeEmail' element={<ChangeEmail/>} />
                  <Route  path='/changeLogin' element={<ChangeLogin/>} />
             */