import React from "react"; import { useDispatch } from "react-redux"; import { useNavigate } from "react-router-dom"; 
import { fetchDataFromFirestore , setLevel, setMasterId} from "../redux/questionSlice"; 

export const Welcome = ()=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSats = () => {navigate("/sats")}
    const handleAccount =()=>{ navigate('/account')}
    const handleLearn = () => {navigate("/catalogue")}
    const handleNumberBonds =()=>{ 
        dispatch(setMasterId({id: 112, cat: "Number bonds"}));
        dispatch(setLevel(2)) 
        dispatch(fetchDataFromFirestore({collection: "Maths", document: 'Number bonds'}) )
        navigate('/catalogue')}
    const handleTimesTables = () => {
        dispatch(setMasterId({id: 112, cat: "Two times table"}));
        dispatch(setLevel(2)) 
        dispatch(fetchDataFromFirestore({collection: "Maths", document: 'Two times table'}) )
        navigate("/catalogue")}
    
  
    return (
        <div>
        <div className="welcomeDiv">
                 
            <ul className='welcome List'>
                <li >Login to keep your progress. Guests can use everything, but progress will not be saved. </li>
                <li onClick={()=>{handleLearn()}}>Use the Learn tab for rapid repeat learning of numeracy, GCSEs and languages  </li>
                <li onClick={()=>{handleAccount()}}>You can keep track of your progress by clicking on your login name</li>
            </ul> 

        </div>
        <div className="welcome Sats">
          <h7>Key stage 1 and 2 with Know It</h7>
          <p onClick={()=>{handleTimesTables()}}>Start with times tables, </p> <p onClick={()=>{handleNumberBonds()}}>number bonds,</p>
        <p onClick={()=>{handleSats()}} >or move straight up to Key stage 1 and 2 mock Sats tests!</p>
        </div>
            <div className="welcome GCSE">
                <h7>GCSE revision on Know It</h7>
                <p>Learn the core factual information for each subject.</p>
                <p>Choose from a wide variety of GCSE subjects.</p>
                <p>Try regular brief revision sessions, in any spare minute you have.</p>

            </div>
            <div className="welcome Other">
                <h7>Trivia on Know It</h7>
                <p>Hundreds of quiz questions and useful facts.</p><p>Historical figures, ancient languages and traval.</p>
            </div>
        </div>
    )
}