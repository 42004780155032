 import React, { useState } from "react";   import { Link, useNavigate } from "react-router-dom";  import { Form, Alert } from "react-bootstrap";
    import { UserAuthContextProvider, useUserAuth } from "../context/userAuthContext";   import {auth, db} from '../firebase-config';  
  import { getAuth, updatePassword, sendPasswordResetEmail, reauthenticateWithCredential, linkWithCredential, GoogleAuthProvider, EmailAuthProvider  } from "firebase/auth";
  import { doc, setDoc, addDoc, updateDoc, getDocs, getDoc, collection } from "firebase/firestore";  import { useDispatch, useSelector } from "react-redux"; import {login} from '../redux/userSlice';
  import {fetchUserFromFirestore, updateName} from '../redux/userFireSlice'; import {googleLogin, emailLoginUpdate} from './Login'


  export async function SaveTheStoreToFirebase(){
    const result = useSelector((state) => state.userFireSet);
    //console.log(result) 
    //const updateRef = doc(db, 'users'. auth.currentUser.uid)
    //await updateDoc( updateRef, {
      // first: resultFirst,
      // userId: auth.currentUser.uid,
      //points: result.points,
       //dateJoined: resultDateJoined,
       //lastLogin: resultLastLogin,
       //reviseList: resultReviseList,
       //modulesCompleted: resultModulesCompleted,
       //testResults: resultTestResults
     //        }, {merge:true});
     //console.log("Document written with ID: ", auth.currentUser.uid);

   // const docRef = doc(db, "users", auth.currentUser.uid);
    
   // const docSnap = await getDoc(docRef)
     // .then((ref)=>{
              
       
    //const resultFirst = useSelector((state) => state.userFireSet.first)
    //const resultPoints = useSelector((state) => state.userFireSet)
   
    /*const resultReviseList = useSelector((state) => state.userFireSet.reviseList)
    const resultModulesCompleted = useSelector((state) => state.userFireSet.modulesCompleted)
    const resultDateJoined = useSelector((state) => state.userFireSet.testResults)
    const resultLastLogin = useSelector((state) => state.userFireSet.testResults)
    const resultTestResults = useSelector((state) => state.userFireSet.testResults)
   */
    //console.log('saving' + result);
    //const dispatch = useDispatch();
    //const auth = getAuth();
    //    const user = auth.currentUser;
   // await fetchUserFromFirestore(user.uid)  // this seems circular getting firestore and then sending back to firestore
   // .then((result)=>{   console.log(result)

  
    
    //})
     
    //} ) .catch ((e)=> {
      //console.error("Error adding document: ", e);
    }  //) ;}
  


export  const ChangeName = () => {
        const [nameUser, setNameUser] = useState("");
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const auth = getAuth();
        const user = auth.currentUser;
       
        const handleSubmit = async (e) => {
          e.preventDefault();
          try {  dispatch(updateName(nameUser))
              .then(SaveTheStoreToFirebase())
              .then(navigate('/'))
              }
          catch {}
          
      }
             
  
        return (
           <div className="accountChange">
            <Form onSubmit={handleSubmit}>
               <Form.Group className="accountForm" controlId="formNameChange">
                  <Form.Control type="text" placeholder="Enter user name"
                    onChange={(e) => setNameUser(e.target.value)}    />
                </Form.Group>
             <button className="accountButton" type="Submit"> <h2>Update Username</h2></button> 
            </Form>
           </div>
        );
  };

export  const ChangePassword = ()=>
  {
    const auth = getAuth();   const email = auth.currentUser.email;
    sendPasswordResetEmail(auth, email)
      .then(() => {  return (<div> Email sent, please go back </div>)    })
      .catch((error) => {  const errorCode = error.code; const errorMessage = error.message;  });  
  }


export const ChangeLogin = ()=>{ 
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const changeToGoogle = async (e)=> { e.preventDefault();
  //const changeToEmail = () => { const credential = EmailAuthProvider.credential(email, password); console.log("Email n" + credential) ;} //linkIt(credential)}
   /*
const auth = getAuth();
  try {
    await googleSignIn()
    .then((userAuth) => {
      const credential = GoogleAuthProvider.credential( userAuth.getAuthResponse().id_token); 
      console.log("Goog,  " + credential) 
      linkWithCredential(auth.currentUser, credential)})
  .then((usercred) => {
    const user = usercred.user;
    console.log("Anonymous account successfully upgraded", user);
      }).catch((error) => {
    console.log("Error upgrading anonymous account", error);
      }) 
  .then((userAuth) => {
    // store the user's information in the redux state
      dispatch(
        login({
          email: userAuth.user.email,
          uid: userAuth.user.uid,
          displayName: userAuth.user.displayName,
          photoUrl: userAuth.user.photoURL,
        })  );
       } )
       googleLogin();
 
  navigate("/home"); } catch(err){}

}
  

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try { await changeToEmail(email, password);  } 
      catch (err) { setError(err.message); }
  };


  return (

<div className="login">
        <h2 > Sign in from a guest account</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="loginForm" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="loginForm" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="loginButton">
            <button variant="primary" type="Submit"> <h2> Sign up </h2> </button>
            <button className="loginButton"  onClick={changeToGoogle} >
               <h2>Login with Google</h2></button>
          </div>
        </Form>
      </div>  
  )
  */}
  }
  


  export const ChangeSubject = ()=>{}