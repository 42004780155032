
function simplify(c,d){let e=0
    console.log(c,d)
    if (d >= c){e = 1; d = d - c} // d is numerator, c is denominator
    while(d >= c){e++; d = d - c} // removes whole numbers from a top heavy fraction
    if(d === 0) {d = 0; c = 0; return [c,d,e]}
    while (c%2 === 0 && d%2 === 0 ){c = c/2; d = d/2}
    while (c%3 === 0 && d%3 === 0 ){ c=c/3; d = d/3}
    while (c%5 === 0 && d%5 === 0 ){c = c/5; d = d/5}
    while (c%7  === 0 && d%7 === 0 ){c = c/7; d = d/7}
    while (c%11  === 0 && d%11 === 0 ){c = c/11; d = d/11}
    console.log(c,d,e)
    return [c,d,e]
}

function numWC(x) {  
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


// set out array of prime numbers
const prime = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97]
const colour = ['red', 'blue', 'green', 'violet', 'purple', 'yellow', 'orange', 'grey', 'cyan']
const easyFractionPairs = [[1,10, 0.1],[1,5, 0.2],[1,4, 0.25],[3,10, 0.3],[1,3, 0.333],[2,5, 0.4], [1,2, 0.5],
 [6,10, 0.6], [2,3, 0.666],[7,10, 0.7],[3,4, 0.75], [4,5, 0.8],[9,10, 0.9] ]
const difficultFractionPairs = [[1,11],[1,10, 0.1],[1,9], [1,7],[1,5, 0.2], [2,9], [1,4, 0.25],[2,7],
[3,10, 0.3],[1,3, 0.333],[2,5, 0.4],[3,7], [4,9], [1,2, 0.5],  [5,9],[4,7], [6,10, 0.6], [2,3, 0.666],
[7,10, 0.7] , [5,7],[3,4, 0.75], [7,9], [4,5, 0.8],[6,7],[8,9],[9,10, 0.9], [10,11] ]
// function for random numbers 
function rnd (range) { let num = (Math.floor(Math.random()* range)); return num}

const nouns = [['chicken', 'chickens'], ['sweet','sweets'], ['drink', 'drinks'], ['car', 'cars'], ['dog', 'dogs'], ['cat', 'cats'], ['hamster', 'hamsters'], ['gerbil', 'gerbils'], ['fish', 'fish'], ['sheep', 'sheep'], ['cow', 'cows'], ['bag', 'bags'], ['fork', 'forks'], ['chair', 'chairs'], ['table', 'tables'], ['phone', 'phones'], ['book', 'books'], ['tree', 'trees'] ]
const people = [ 'Oliver', 'Dawn', 'Joseph', 'Jacob', 'Thomas', 'Christine', 'John', 'Charlotte', 'Amelia', 'Ava', 'Sophia', 'Isabella', 'Mia', 'Eve', 'Liam', 'James', 'William', 'Ben', 'Lucas', 'Jack', 'Owen', 'Molly', 'Naya', 'Kabir', 'Ishaan', 'Jai', 'Amar', 'Aditi', 'Reva', 'Shylah', 'Foluke', 'Chidera', 'Imamu', 'Chioma' , 'Ikem', 'Olatunde', 'Adjoa', 'Kasper', 'Konrad', 'Jadwiga', 'Ludwik', 'Wladyslaw', 'Wanda', 'Sonya', 'Mariya', 'Olena', 'Antonina', 'Victoriya', 'Oleksiy', 'Liliya', 'Maksim', 'Volodymyr', 'Vitali'
  ]
  const commodity = ['flour', ' sugar', 'cement', 'sand', 'berries', 'mushrooms', 'ice', 'butter', 'gravel', 'cereal' ]
  const liquid = ['water', 'juice', 'cola', 'lemonade', 'petrol', 'milk', 'oil']
const activity = ['swims', 'runs', 'walks', 'jogs', 'drives']
const shape = [['triangle', 3], ['square', 4], ['pentagon', 5], ['hexagon', 6], ['heptagon', 7], ['octagon', 8] ]


export function orderUpTo8()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    if (numbers[0] === qu1){answer.push('a')};if (numbers[0] === qu2){answer.push('b')};if (numbers[0] === qu3){answer.push( 'c')}; if (numbers[0] === qu4){answer.push('d')}; if (numbers[0] === qu5){answer.push('e')}
    if (numbers[1] === qu1){answer.push('a')};if (numbers[1] === qu2){answer.push('b')};if (numbers[1] === qu3){answer.push('c')}; if (numbers[1] === qu4){answer.push('d')}; if (numbers[1] === qu5){answer.push('e')}
    if (numbers[2] === qu1){answer.push('a')};if (numbers[2] === qu2){answer.push('b')};if (numbers[2] === qu3){answer.push('c')}; if (numbers[2] === qu4){answer.push('d')}; if (numbers[2] === qu5){answer.push('e')}
    if (numbers[3] === qu1){answer.push('a')};if (numbers[3] === qu2){answer.push('b')};if (numbers[3] === qu3){answer.push('c')}; if (numbers[3] === qu4){answer.push('d')}; if (numbers[3] === qu5){answer.push('e')}
    if (numbers[4] === qu1){answer.push('a')};if (numbers[4] === qu2){answer.push('b')};if (numbers[4] === qu3){answer.push('c')}; if (numbers[4] === qu4){answer.push('d')}; if (numbers[4] === qu5){answer.push('e')}
     console.log(numbers, answer)
    let instruct1= ``
    let instruct2= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an1=`${answer.toString()}`
   let an = an1.replace(',','')
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function orderDownUpTo8()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return b - a});
    let answer =[]
    if (numbers[0] === qu1){answer.push('a')};if (numbers[0] === qu2){answer.push('b')};if (numbers[0] === qu3){answer.push( 'c')}; if (numbers[0] === qu4){answer.push('d')}; if (numbers[0] === qu5){answer.push('e')}
    if (numbers[1] === qu1){answer.push('a')};if (numbers[1] === qu2){answer.push('b')};if (numbers[1] === qu3){answer.push('c')}; if (numbers[1] === qu4){answer.push('d')}; if (numbers[1] === qu5){answer.push('e')}
    if (numbers[2] === qu1){answer.push('a')};if (numbers[2] === qu2){answer.push('b')};if (numbers[2] === qu3){answer.push('c')}; if (numbers[2] === qu4){answer.push('d')}; if (numbers[2] === qu5){answer.push('e')}
    if (numbers[3] === qu1){answer.push('a')};if (numbers[3] === qu2){answer.push('b')};if (numbers[3] === qu3){answer.push('c')}; if (numbers[3] === qu4){answer.push('d')}; if (numbers[3] === qu5){answer.push('e')}
    if (numbers[4] === qu1){answer.push('a')};if (numbers[4] === qu2){answer.push('b')};if (numbers[4] === qu3){answer.push('c')}; if (numbers[4] === qu4){answer.push('d')}; if (numbers[4] === qu5){answer.push('e')}
     console.log(numbers, answer)
    let instruct1= ``
    let instruct2= `Place these numbers in order from highest to lowest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
    let an1=`${answer.toString()}`
    let an = an1.replace(',','')
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2}
return question}

export function roundWhole()  {
    let accuracy = [10,100,1000]; let ans
    let qu1 = (rnd(888999)+11010); let indexQu2 = rnd(3); let qu2 = accuracy[indexQu2]
    let quotient = qu1%qu2; let intermediate = qu1 - quotient; 
    if (quotient===0){ ans = qu1; qu1= qu1+ ((rnd(4)*qu2)/10);} 
    else if (quotient < 0.5*qu2){ans = intermediate}
    else if (quotient >= 0.5*qu2){ans = intermediate + qu2}
    
     console.log(qu1, qu2, quotient, intermediate, ans)
    let instruct1= ` `
    let instruct2= `Round this number to the nearest ${qu2}:`
    let qu=  `${qu1}`
   let an=`${ans}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function roundDecimal()  {
    let accuracy = [10,100,1000]
    let qu1 = (rnd(88899)+111); let indexQu2 = rnd(3); let qu2 = accuracy[indexQu2]
    let quotient = qu1%qu2; let intermediate = qu1 - quotient; let ans
    if (quotient===0){ ans = qu1/(qu2*qu2); qu1= (qu1+ ((rnd(4)*qu2)/10))/(qu2*qu2);} 
    else if (quotient < 0.5*qu2){ans = intermediate/(qu2*qu2); qu1 = qu1/(qu2*qu2)}
    else if (quotient >= 0.5*qu2){ans = (intermediate + qu2)/(qu2*qu2); qu1 = qu1/ (qu2*qu2)}
    
     console.log(qu1, qu2, quotient, intermediate, ans)
    let instruct1= ` `
    let instruct2= `Round this number to the nearest ${1/qu2}:`
    let qu=  `${qu1}`
   let an=`${ans}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function bodmas1_1_1_1()  {
    let type = ['a','b','c','d','e']
    let qu1 = (rnd(9)+1); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]
    let qu3 = (rnd(9)+1);let qu4 = (rnd(19)+10);let qu5 = (rnd(9)+1);
    let qu, an, wO
    if (qu2 === 'a') { qu = `${qu1} + ${qu3} x ${qu4} = `; an= `${(qu4*qu3)+qu1}`; wO=`${qu1} + ${qu4*qu3}` }
    if (qu2 === 'b') {qu= `${qu1} x ${qu3} - ${qu5} + ${qu4} = `; an= `${(qu1*qu3)-qu5+qu4}`; wO =`${qu1*qu3} + ${qu4-qu5} + ` }
    if (qu2 === 'c') {qu= `${qu4} - ${qu3*qu5} ÷ ${qu5} = `; an= `${qu4 - (qu3)}`; wO =`${qu4} - ${qu3}` }
    if (qu2 === 'd') {qu= `${qu1}^2 + (${qu3} - ${qu5}) = `; an = `${(qu3- qu5) + (qu1*qu1)}`; wO =`${qu1*qu1} + ${qu3-qu5}` }
    if (qu2 === 'e') {qu= `${qu1} x ${qu3} - ${qu5} = `; an= `${(qu1*qu3)-qu5}`; wO =`${qu1*qu3} - ${qu5}` }
        
    let instruct1= ``
    let instruct2= ``
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function multistepWord()  {
    let type = ['a','b','c','d','e']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(8)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);
    let qu, an, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let ans; let noun2 = nouns[indexNouns][1]
    if (qu2 === 'a') { ans = ((((qu6 + qu1))-qu4)*qu5)
        qu= `${name} thinks of a number adds ${qu1*qu3} divides the result by ${qu3}. Then subtracts ${qu4} and times's by ${qu5}. The answer is ${ans}; What number did ${name} think of? `
        ;an= qu6*qu3;  wO= `(((( ?  + ${qu1})/${qu3})-${qu4})*${qu5})` }
    if (qu2 === 'b') {ans = (qu1*qu3)+qu4 + qu6;
    qu= `${name} earns £${qu1} a day for ${qu3} days. ${name2} gives ${name} another £${qu4}. How much more money do they need to buy a ${noun} that costs £${ans}? `
    ;an = qu6;  wO =`${ans} - ((${qu1} x ${qu3}) + ${qu4})` }
    
    if (qu2 === 'c') {ans = ((qu3 * qu1)+qu4)
    qu = `${name} wants to buy ${qu3}  ${noun2}. Each ${noun} costs £${qu1}. There is also an additional fixed sales fee of £ ${qu4}. What is the total cost in pounds?`
    ;an = ans;  wO = `(${qu1} x ${qu3}) + ${qu4}`  }

    if (qu2 === 'd') {
    qu = `${name} has £${qu1+qu3} and spends £${qu1} on ${noun2}. ${name2} has £${qu3+ qu4} and spends £${qu4} on a ${noun}. If they share, how much do they have left, in pounds?`
    ;an = qu3+qu3;  wO = `(${qu1+ qu3} - ${qu1}) + (${qu3+ qu4} - ${qu4} )` }
    
    if (qu2 === 'e') {ans = (qu1 + qu3+ qu4 + qu1)
    qu = `${name} has saved £${qu1 + qu3+ qu4}. ${name2} has saved £${qu3} less than ${name}. If they buy a ${noun} for £${qu4} how much do they have left?`
    ;an = ans;  wO =`${qu1 + qu3+ qu4} +  ${qu1 + qu4} - ${qu4}`  }
           
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function estimateCheck ()  {
    let type = ['a','b']
    let qu1 = (rnd(88)+12); let qu2 = (rnd(69)+12); let indexQ = rnd(type.length); let index = type[indexQ]; 
    let qu3 = (rnd(8)+1);let qu4 = (rnd(8)+1);let qu5 = (rnd(7)+2);let qu6 = (rnd(7)+2);
    /*let lowFraction = [' 1/4 ', ' 1/3 ', ' 1/5 ', ' 2/5 ', ' 1/6 ', ' 1/10 ', ' 3/10 ' ]
    //let highFraction= [' 1/2 ', ' 3/5 ', ' 4/5 ', ' 5/6 ', ' 2/3 ', ' 3/4 ', '7/10 ' ]
    let coinFlip = (rnd(1)); let coinFlip2 = (rnd(1)); let frac1; let frac2; let fracIntermediate = 0
    if (coinFlip ===0) {frac1= lowFraction[qu5-2]} else {frac1= highFraction[qu5-2]; fracIntermediate++}  
    if (coinFlip2 ===0) {frac2= lowFraction[qu6-2]} else {frac2= highFraction[qu6-2]; fracIntermediate++} 
    */
    let intermediate = 0
    if(qu3 >= 5){intermediate++}; if (qu4>=5){intermediate++}
   
    let ans; let qu ; let an;   let wO

    if (index === 'a'){ qu= ` ${(qu1*10)+qu3} + ${(qu2*10)+qu4} =`; an= ((qu1+qu2)*10) + intermediate }
    if (index === 'b'){ qu= ` ${((qu1*10)+qu3)/100} + ${((qu2*10)+qu4)/100} =`; an= ((qu1+qu2)/10) + intermediate }
    //if (index === 'c'){ qu= ` ${(qu1)+ frac1} + ${(qu2)+ frac2} =`; an= ((qu1+qu2)) + fracIntermediate }
    
    let instruct1= ``
    let instruct2= `Estimate to 2 significant figures:`

const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  commonFactor()  {
    
    let qu1 = (prime[rnd(2)]); let qu2 = (prime[rnd(2)]);let qu3 = (prime[rnd(2)]); let qu4 = (prime[rnd(2)]); 
    let qu5 = (prime[rnd(2)]); let qu6 = (prime[rnd(3)]); let qu7 = (prime[rnd(3)]);let qu8 = (prime[rnd(3)]); 
    let qu9 = (prime[rnd(3)]); let qu10 = (prime[rnd(4)]); // create 10 prime numbers
    let numberArray = [qu1, qu2, qu3, qu4, qu5, qu6, qu7, qu8, qu9, qu10]
    let common = rnd(2)+1 ; let size1 = rnd(2); let size2 = rnd(2)
    let commonArray= [], array1 =[], array2=[]; let ans =1; let qu11 = 1; let qu12 = 1
   for (let i =0; i< common; i++){commonArray.push(numberArray[rnd(numberArray.length)])}
   for (let i =0; i< size1; i++){array1.push(numberArray[rnd(numberArray.length)])}
   for (let i =0; i< size2; i++){let x = rnd(numberArray.length); if ( -1 === array1.findIndex((e)=> e === x))
    { array2.push(numberArray[x])}}

   for (let i =0; i< commonArray.length; i++){ ans = ans * commonArray[i]}
   for (let i =0; i< array1.length; i++){ qu11 = qu11 * array1[i]}
   for (let i =0; i< array2.length; i++){qu12 = qu12 * array2[i]}

    let instruct1= ` `
    let instruct2= `What is the highest common factor in:`
    let qu=  ` ${qu11*ans} and ${qu12*ans} `
   let an=`${ans}`
   let wO=`${commonArray.toString()}`
   console.log(commonArray, array1, array2, ans)
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function CommonMultip()  {
   
    let qu1 = (prime[rnd(2)+1]); let qu2 = (prime[rnd(2)]);let qu3 = (prime[rnd(2)]); let qu4 = (prime[rnd(2)]); 
    let qu5 = (prime[rnd(2)]); let qu6 = (prime[rnd(2)+1]); let qu7 = (prime[rnd(3)]);let qu8 = (prime[rnd(3)]); 
    let qu9 = (prime[rnd(3)]); let qu10 = (prime[rnd(2)+2]); // create 10 prime numbers
    let numberArray = [qu1, qu2, qu3, qu4, qu5, qu6, qu7, qu8, qu9, qu10]
    let common = rnd(2)+1 ; let size1 = rnd(2); let size2 = size1+1
    let commonArray=[], array1=[], array2=[]; let ans =1; let qu11 = 1; let qu12 = 1
   for (let i =0; i< common; i++){commonArray.push(numberArray[rnd(numberArray.length)])}
   for (let i =0; i< size1; i++){array1.push(numberArray[rnd(numberArray.length)])}
   for (let i =0; i< size2; i++){let x = numberArray[rnd(numberArray.length)]; 
    
    if ( -1 === array1.findIndex((e)=> e === x))
    { array2.push(numberArray[x])} 
    else
        {if(x>numberArray.length-1){array2.push(numberArray[1])}
        else { array2.push(numberArray[x+1])}}}

   for (let i =0; i< commonArray.length; i++){ ans = ans * commonArray[i]}
   for (let i =0; i< array1.length; i++){ qu11 = qu11 * array1[i]}
   for (let i =0; i< array2.length; i++){qu12 = qu12 * array2[i]}

    let instruct1= ` `
    let instruct2= `What is the lowest common multiple of:`
    let qu=  ` ${qu11*ans} and ${qu12*ans} `
   let an=`${ans*qu11*qu12}`
    let wO=``
   const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  compareFraction ()  { 
    let qu1 = (rnd(easyFractionPairs.length)); let qu2 = (rnd(easyFractionPairs.length));let qu3 = (rnd(easyFractionPairs.length));
    let qu4 = (rnd(easyFractionPairs.length)); let qu5 = (rnd(easyFractionPairs.length)); let ans; 
   //make sure none the same
   if(qu1=== qu2){qu1++};   
    let numbers = [easyFractionPairs[qu1], easyFractionPairs[qu2]]
   if (numbers[0][2] === numbers[1][2]){ans = '='}
   if (numbers[0][2] > numbers[1][2]){ans = '>'}
   if (numbers[0][2] < numbers[1][2]){ans = '<'}

   let instruct1= `Answer with < , > or = `
   let instruct2= `Compare these fractions, is (a) bigger smaller or the same as (b)? `
   let qu=  `a: ${numbers[0][0]}/${numbers[0][1]}, b: ${numbers[1][0]}/${numbers[1][1]}`
  let an=`${ans}`
  let wO=''  
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  orderFraction()  {
    let tempArray = [] 
    for (let x=0; x< easyFractionPairs.length; x++){tempArray.push(x)}
    let qu1a = tempArray.splice((rnd(tempArray.length)),1); 
    let qu2a = tempArray.splice((rnd(tempArray.length)),1); 
    let qu3a = tempArray.splice((rnd(tempArray.length)),1); 
    let qu4a = tempArray.splice((rnd(tempArray.length)),1); 
    let qu5a = tempArray.splice((rnd(tempArray.length)),1);  
   //console.log(qu1, qu1a, qu2, qu2a, qu3, qu3a, qu4, qu4a, qu5, qu5a)
    let numbers = [easyFractionPairs[qu1a[0]], easyFractionPairs[qu2a[0]], easyFractionPairs[qu3a[0]],
   easyFractionPairs[qu4a[0]], easyFractionPairs[qu5a[0]]];
   let fractions = [...numbers]; let answer=[]
   // put in order
 numbers.sort(function(a, b){return a[2] - b[2]});
console.log("numbers: " + numbers + ", tempArray:" + tempArray + "fractions: " + fractions)
 if (numbers[0][2] === easyFractionPairs[qu1a[0]][2]){answer.push('a')}
 else if (numbers[0][2] === easyFractionPairs[qu2a[0]][2]){answer.push('b')}
 else if (numbers[0][2] === easyFractionPairs[qu3a[0]][2]){answer.push('c')} 
 else if (numbers[0][2] === easyFractionPairs[qu4a[0]][2]){answer.push('d')}
 else if (numbers[0][2] === easyFractionPairs[qu5a[0]][2]){answer.push('e')}
 
 if (numbers[1][2] === easyFractionPairs[qu1a[0]][2]){answer.push('a')} else if (numbers[1][2] === easyFractionPairs[qu2a[0]][2]){answer.push('b')} else if (numbers[1][2] === easyFractionPairs[qu3a[0]][2]){answer.push('c')}else if (numbers[1][2] === easyFractionPairs[qu4a[0]][2]){answer.push('d')}else if (numbers[1][2] === easyFractionPairs[qu5a[0]][2]){answer.push('e')}
 if (numbers[2][2] === easyFractionPairs[qu1a[0]][2]){answer.push('a')} else if (numbers[2][2] === easyFractionPairs[qu2a[0]][2]){answer.push('b')}else if (numbers[2][2] === easyFractionPairs[qu3a[0]][2]){answer.push('c')}else if (numbers[2][2] === easyFractionPairs[qu4a[0]][2]){answer.push('d')}else if (numbers[2][2] === easyFractionPairs[qu5a[0]][2]){answer.push('e')}
 if (numbers[3][2] === easyFractionPairs[qu1a[0]][2]){answer.push('a')}else if (numbers[3][2] === easyFractionPairs[qu2a[0]][2]){answer.push('b')}else if (numbers[3][2] === easyFractionPairs[qu3a[0]][2]){answer.push('c')}else if (numbers[3][2] === easyFractionPairs[qu4a[0]][2]){answer.push('d')}else if (numbers[3][2] === easyFractionPairs[qu5a[0]][2]){answer.push('e')}
 if (numbers[4][2] === easyFractionPairs[qu1a[0]][2]){answer.push('a')}else if (numbers[4][2] === easyFractionPairs[qu2a[0]][2]){answer.push('b')}else if (numbers[4][2] === easyFractionPairs[qu3a[0]][2]){answer.push('c')}else if (numbers[4][2] === easyFractionPairs[qu4a[0]][2]){answer.push('d')}else if (numbers[4][2] === easyFractionPairs[qu5a[0]][2]){answer.push('e')}
    
   console.log(numbers, answer)
   let instruct1= ``
   let instruct2= `Place these fractions in order from lowest to highest:`
   let qu=  `a: ${fractions[0][0]}/${fractions[0][1]}, b: ${fractions[1][0]}/${fractions[1][1]}, c: ${fractions[2][0]}/${fractions[2][1]},
    d: ${fractions[3][0]}/${fractions[3][1]}, e: ${fractions[4][0]}/${fractions[4][1]}.`
  let an=`${answer.toString()}`
  let wO=''


const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  decimalEquiv()  {
    let qu1 = easyFractionPairs[(rnd(easyFractionPairs.length))];
    let instruct1= `Maximum of three decimal places`
    let instruct2= `Give the decimal equivalent for: `
    let qu=  `${qu1[0]}/${qu1[1]}`
   let an=`${qu1[2]}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  percentEquiv()  {
    let qu1 = easyFractionPairs[(rnd(easyFractionPairs.length))]; 
    
    let instruct2= `Give the percentage equivalent for: `
    let instruct1= `Maximum one decimal place. `
    let qu=  `${qu1[0]}/${qu1[1]}`
   let an=`${qu1[2]*100}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  relativeSize()  {
    let type = ['a','b','c','d']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(8)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans
    if (qu2 === 'a') { an = 2*(qu1*qu3)
        qu= `${name} walks ${qu1} kilometres to work and then walks home again for ${qu3} days. How far does ${name} walk in total? `
        ;  wO= ` ` }
    
    if (qu2 === 'b') {an = (((qu1*50)-50)*qu3)/1000; instruct1='Use a decimal for pence.'
    qu= `${name} buys ${(qu1*50)-50} grams of ${com} at £${qu3} per kilogram. How much does ${name} spend in pounds?` }
    
    if (qu2 === 'c') {ans = (qu4*4)+24 +qu3+10; 
    qu = ` ${name} ${act} for ${(qu4*4)+24} seconds and ${name2} takes ${qu3+10} seconds longer. how long does ${name2} take?`
    ;an = ans;  wO = ` `  }

    if (qu2 === 'd') {
    qu = `${name} has ${qu3} litres of ${liq} and uses ${qu1*20} mililitres per day. How many litres of ${liq} does ${name} use in ${qu4} days?`
    ;an = ((qu3*1000) -(qu1*qu4*20))/1000;  wO = `${qu3} - (${(qu1*qu4*20)/1000} )` }
    

           
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  calculatePercent()  {
    let type = ['a','b']; let indexQu2 = rnd(type.length); let quI = type[indexQu2];let qu; let an; let wO
    let qu1 = (rnd((8)+1)*10); let qu2 = ((rnd(9)+1)*10); let ans; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let indexNouns = rnd(nouns.length); let noun2 = nouns[indexNouns][1];
    let instruct1= ` `
    let instruct2= ``

    if (quI === 'a') {let qu3=qu1*qu2/100 ;qu=  `There are  ${qu1} ${noun2}, ${qu3} of these are ${colour1}. What percentage are ${colour1}?`
    an= qu2;  wO=''}

   if (quI === 'b') { qu=  ` What is ${(qu2)}% of ${qu1} ?`
    an= (qu1*qu2)/100;  wO=''}

const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function shapeProblem()  {
    let type = ['a','b']; let indexQu2 = rnd(type.length); let quI = type[indexQu2];
    let colourIndex = (rnd(shape.length)); let shape1 = shape[colourIndex]
    let qu1 = (rnd(9)+1); let ans; let qu; let an; let wO
    //make sure none the same
    if (quI === 'a') { qu=  `What is the perimeter of a ${shape1[0]} with side ${qu1}cm?`
    an= shape1[1]*qu1
     wO=''}
 
    if (quI === 'b') { qu=  `If the perimeter of a ${shape1[0]} is ${qu1*shape1[1]}cm, what length are all of the sides?`
    an= qu1
    wO=''}
 
    let instruct1= ` `
    let instruct2= ` `
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  shareProblem()  {
    let type = ['a','b']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(8)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); 
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); 
    let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans
    
    if (qu2 === 'a') { an = (qu1*qu3)
        qu= `A mixture requires ${qu1}grams of ${com} for every kilogram of product. How much ${com} is needed for ${qu3} kilograms of product? `
        ;  wO= ` ` }
    
    if (qu2 === 'b') {an = (frac1[1]*frac2[1]) - (frac1[0]*frac2[1])-(frac2[0]*frac1[1])
    qu= `In a collection of ${frac1[1]*frac2[1]} ${noun2}, ${frac2[0]} / ${frac2[1]} are ${colour1} and ${frac1[0]} / ${frac1[1]} are ${colour2}. How many are neither ${colour1} nor ${colour2} ?` }
    
    
               
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  simpleFormula()  {
    let type = ['a','b']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(4)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') { an = qu6; ans = qu3*qu5 + qu4*qu6
        qu= `${name} buys ${qu3} ${noun2} and ${qu4} ${nouns3}, ${name} spends £${ans}. If a ${noun} costs £${qu5} how much does a ${noun3} cost?`
        ;  wO= ` ` }
    
    if (qu2 === 'b') {an = qu6; ans = qu3*qu6 + qu4*qu5
        qu= `${name} buys ${qu3} ${noun2} and ${qu4} ${nouns3}, ${name} spends £${ans}. If a ${noun3} costs £${qu5} how much does a ${noun} cost?`
        ;  wO= ` `}
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  linearSequence()  {
    let type = ['a','b']
    let qu1 = (rnd(89)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qua = (rnd(8)+2); let qu3= qu1+qua; let qu4 = qu3+qua ;let qu5 = qu4+qua ;let qu6 = qu5+qua; let qu, wO; 
    let instruct1
    
    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= `What number comes next in this sequence?`; an = qu6; 
        qu= ` ${qu1}, ${qu3}, ${qu4} , ${qu5} , ?`
        ;  wO= ` ` }
    
        if (qu2 === 'b') { instruct2= `What number comes first in this sequence?`; an = qu1; //ans = qu3*qu5 + qu4*qu6
        qu= ` ?, ${qu3} , ${qu4} , ${qu5}, ${qu6}`
        ;  wO= ` ` }
   
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  missingNumber ()  {
    let type = ['a','b']
    let qu1 = (rnd(89)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qua = (rnd(8)+2); let qu3= qu1+qua; let qu4 = qu3+qua ;let qu5 = qu4+qua;let qu6 = qu5+qua; let qu, wO; 
    let instruct1

    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= `What number is missing in this sequence?`; an = qu3; 
        qu= ` ${qu1}, ? , ${qu4} , ${qu5} `
        ;  wO= ` ` }
    
        if (qu2 === 'b') { instruct2= `What number is missing in this sequence?`; an = qu4; ans = qu3*qu5 + qu4*qu6
        qu= `  ${qu1}, ${qu3} , ? , ${qu5}`
        ;  wO= ` ` }
   
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function equationPair()  {
    let type = ['a','b', 'c']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(4)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') { an = qu1+qu3; 
        qu= `if x - y = ${qu1} and  y = ${qu3}, what is the value of x?`
        ;  wO= ` ` }
    
    if (qu2 === 'b') {an = qu1; 
        qu= `if x + y = ${qu1 + qu3} and  x = ${qu3}, what is the value of y?`
        ;  wO= ` ` }

    if (qu2 === 'c') {an = qu4; 
            qu= `if A x B = ${qu4* qu3} and  A = ${qu3}, what is the value of B?`
            ;  wO= ` ` }
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  possibleCombinations()  { ////////// answers have too many combinations to be useful in this format
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return b - a});
    let answer =[]
    if (numbers[0] === qu1){answer.push('a')};if (numbers[0] === qu2){answer.push('b')};if (numbers[0] === qu3){answer.push( 'c')}; if (numbers[0] === qu4){answer.push('d')}; if (numbers[0] === qu5){answer.push('e')}
    if (numbers[1] === qu1){answer.push('a')};if (numbers[1] === qu2){answer.push('b')};if (numbers[1] === qu3){answer.push('c')}; if (numbers[1] === qu4){answer.push('d')}; if (numbers[1] === qu5){answer.push('e')}
    if (numbers[2] === qu1){answer.push('a')};if (numbers[2] === qu2){answer.push('b')};if (numbers[2] === qu3){answer.push('c')}; if (numbers[2] === qu4){answer.push('d')}; if (numbers[2] === qu5){answer.push('e')}
    if (numbers[3] === qu1){answer.push('a')};if (numbers[3] === qu2){answer.push('b')};if (numbers[3] === qu3){answer.push('c')}; if (numbers[3] === qu4){answer.push('d')}; if (numbers[3] === qu5){answer.push('e')}
    if (numbers[4] === qu1){answer.push('a')};if (numbers[4] === qu2){answer.push('b')};if (numbers[4] === qu3){answer.push('c')}; if (numbers[4] === qu4){answer.push('d')}; if (numbers[4] === qu5){answer.push('e')}
     console.log(numbers, answer)
    let instruct2= `Place these numbers in order from highest to lowest:`
    let instruct1= ``
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function convertUnit3length ()  {
    let type = ['a','b', 'c', 'd', 'e']
    let qu1 = (rnd(1090)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(4)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') { an = qu1/100; 
        qu= `Convert ${qu1} cm to metres?`
        ;  wO= ` ` }
    
    if (qu2 === 'b') {an = qu1/1000; 
    qu= `Convert ${qu1} m to kilometers?`
    ;  wO= ` `  }

    if (qu2 === 'c') {an = qu1; 
    qu= `Convert ${qu1/1000} kilometers to meters?`
    ;  wO= ` `  }

    if (qu2 === 'd') {an = qu1; 
        qu= `Convert ${qu1/100} meters to centimeters?`
        ;  wO= ` `  }

    if (qu2 === 'e') {an = qu1; 
            qu= `Convert ${qu1/1000} meters to millimeters?`
            ;  wO= ` `  }
    if (qu2 === 'e') {an = qu1/1000; 
            qu= `Convert ${qu1} millimeters to meters?`
            ;  wO= ` `  }
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function convertUnit3mass ()  {
    let type = ['b', 'c', 'd', 'e']
    let qu1 = (rnd(1090)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(4)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
   
    
    if (qu2 === 'b') {an = qu1/1000; 
    qu= `Convert ${qu1} kilograms to tonnes?`
    ;  wO= ` `  }

    if (qu2 === 'c') {an = qu1; 
    qu= `Convert ${qu1/1000} kilograms to grams?`
    ;  wO= ` `  }

    if (qu2 === 'd') {an = qu1/1000; 
        qu= `Convert ${qu1} grams to kilograms?`
        ;  wO= ` `  }

    if (qu2 === 'e') {an = qu1; 
            qu= `Convert ${qu1/1000} tonnes to kilograms?`
            ;  wO= ` `  }
  
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  convertUnit3time()  {
    let type = ['b', 'c', 'd', 'e', 'f', 'g']
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(40)+24);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
   
    
    if (qu2 === 'b') {an = qu3; 
    qu= `Convert ${qu3*60} minutes to hours?`
    ;  wO= ` `  }

    if (qu2 === 'c') {an = qu3*60; 
    qu= `Convert ${qu3} hours to minutes?`
    ;  wO= ` `  }

    if (qu2 === 'd') {an = qu3; 
        qu= `Convert ${qu3*24} hours to days?`
        ;  wO= ` `  }

    if (qu2 === 'e') {an = qu3*24; 
            qu= `Convert ${qu3} days to hours?`
            ;  wO= ` `  }

    if (qu2 === 'f') {an = qu4*7; 
            qu= `Convert ${qu4} weeks to days?`
            ;  wO= ` `  }
    
    if (qu2 === 'g') {an = qu4; 
                qu= `Convert ${qu4*7} days to weeks?`
                ;  wO= ` `  }
  
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  convertUnit3vol()  {
    let type = ['a','b', 'c', 'd', 'e']
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(40)+24);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
   
    
    if (qu2 === 'b') {an = qu1/1000; 
    qu= `Convert ${qu1} milliliters to liters?`
    ;  wO= ` `  }

    if (qu2 === 'c') {an = qu1; 
    qu= `Convert ${qu1/1000} liters to milliters?`
    ;  wO= ` `  }

    if (qu2 === 'd') {an = qu1/1000; 
    qu= `Convert ${qu1} cm^3 to liters?`
        ;  wO= ` `  }

    if (qu2 === 'e') {an = qu1/1000; 
    qu= `Convert ${qu1} liters to m^3?`
            ;  wO= ` `  }

    if (qu2 === 'a') {an = qu4*1000; 
            qu= `Convert ${qu4} m^3 to liters?`
            ;  wO= ` `  }
    
   
  
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  milesKilometres()  {
    let type = ['a','b' ]
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(40)+24);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') {an = qu4; 
    qu= `Convert ${(qu4*16)/10} kilometres to miles?`
    ;  wO= `Divide by 1.6`  }
    
    if (qu2 === 'b') {an = (qu4*16)/10; 
    qu= `Convert ${qu4} miles to kilometres?`
    ;  wO= `Times by 1.6 `  }

  
    
   
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  areaPerimeter()  {
    
  
    let qu3 = (rnd(3)+2);let qu4 = (rnd(3)+2);let qu5 = (rnd(4)+2);let qu6;
    let qu, wO; let instruct1= `Use >, < or =`; 
    let instruct2= ``; let an; 
    
   
     let areaA = (qu3 * (qu4+qu5)) ; let areaB = (qu4 *(qu3+qu5)); if (areaA>areaB){an = '>'};
        if (areaA<areaB){an = '<'};if (areaA===areaB){an = '='} qu6 = 2*(qu3 +qu4+ qu5)
    qu= `Rectangles A and B both have a perimeter of ${qu6} cm. Rectangle A has a side of ${qu3} cm. Rectangle B has a side of ${qu4} cm. \n Is area A larger than, smaller than or the same as area B?`
    ;  wO= ` A is ${qu4+ qu5} x ${qu3} = ${areaA}cm^2; B has sides ${qu3+qu5} x ${qu4} = ${areaB}cm^2`  
 
    
   
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  areaOfTriangle ()  {
    let type = ['a','b' ]
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(40)+24);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') {an = qu4*qu3; 
    qu= `What is the area of a right angled triangle with perpendicular sides ${qu4} cm and ${qu3*2} cm ? `
    ;  wO= ` Area = 1/2 base x height`  }
    
    if (qu2 === 'b') {an = qu4*qu3; 
        qu= `What is the area of a right angled triangle with perpendicular sides ${qu4*2} cm and ${qu3} cm? `
        ;  wO= ` `  }

  
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  areaOfParr ()  {
    let type = ['a','b' ]
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(40)+24);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') {an = qu4*qu3*2; 
    qu= `What is the area of a parallelogram with perpendicular height of ${qu4} cm and length ${qu3*2} cm?  `
    ;  wO= ` Area = length x perpendicular height `  }
    
    if (qu2 === 'b') {an = qu4*qu3*2; 
        qu= `What is the area of a parallelogram with perpendicular height of ${qu4*2}cm and length ${qu3} cm? `
        ;  wO= ` `  }

const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  volCuboid()  {
    let type = ['a','b' ]
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') {an = qu4*qu3*qu5; 
    qu= `What is the volume of a box with height ${qu4} cm, width ${qu5} cm and length ${qu3} cm? `
    ;  wO= ` Volume = length x height x width `  }
    
    if (qu2 === 'b') {an = qu4*qu3*qu5; 
        qu= `What is the volume of a cuboid with height ${qu4} cm, width ${qu5} cm and length ${qu3} cm? `
        ;  wO= ` Volume = length x height x width `  }

const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  compareVolCuboid ()  {/////////////////////////////
    let type = ['a','b' ]
    let qu1 = (rnd(8)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(4)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex] 
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= `Use >, < or =`; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    let areaA = (qu3 * (qu5)) ;let areaB = (qu6*qu1); if (areaA>areaB){an = '>'};
    if (areaA<areaB){an = '<'};if (areaA===areaB){an = '='} 
    qu= `Box 1 has a height ${qu4} cm, width ${qu5} cm and length ${qu3} cm. Box 2 has height ${qu4} cm, width ${qu6} cm and length ${qu1} cm. \nIs box 1 bigger, smaller or the same as box 2?`
    ;  wO= ` Volume = length x height x width `  
    
    
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  unknownAngle ()  {
    let type = ['a','b' ]
    let qu1 = (rnd(100)+20); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(8)+2);let qu4 = (rnd(20)+30);let qu5 = (rnd(60)+20);let qu6 = (rnd(8)+2);let indexQuI = rnd(type.length); let indexNouns2 = rnd(nouns.length);
    let frac2 = easyFractionPairs[rnd(5)];let frac1 = easyFractionPairs[rnd(5)]; let colourIndex = (rnd(colour.length)); let colour1 = colour[colourIndex]
    let shapeIndex = (rnd(shape.length)); let shape1 = shape[shapeIndex]; let colourIndex2 = (rnd(colour.length)); let colour2 = colour[colourIndex2]
    let qu, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let an; let noun2 = nouns[indexNouns][1]; let com = commodity[rnd(commodity.length)]; let liq = liquid[rnd(liquid.length)]
    let act = activity[rnd(activity.length)]; let ans; let nouns3 = nouns[indexNouns2][1];let noun3 = nouns[indexNouns2][0];
    
    if (qu2 === 'a') {an = 180 - qu4 -qu5; 
    qu= `A triangle has angles of ${qu4} degrees and ${qu5} degrees. What is the third angle `
    ;  wO= ` 180 degrees in a triangle`  }
    
    if (qu2 === 'b') {let qu7= 180-qu5 ; an = 180 - qu4; 
        qu= `A parallelogram has angles of ${qu5} degrees ${qu4} degrees and ${qu7} degrees. What is the fourth angle `
        ;  wO= ` 360 degrees in a parrallogram` }





const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  compareAreaPolygon ()  {
    }

export function  compareLengthPolygon ()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct1= ``
    let instruct2= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function compareAnglePolygon()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct1= ``
    let instruct2= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  nameCircle()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct1= ``
    let instruct2= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  verticalOpp()  {
   }

export function  translateShape()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct2= `Please put commas between the letters in your answer. Do not use spaces.`
    let instruct1= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  pie()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct2= `Please put commas between the letters in your answer. Do not use spaces.`
    let instruct1= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  line()  {
    let qu1 = (rnd(88899999)+1100000); let qu2 = (rnd(88899999)+100000);let qu3 = (rnd(88899999)+100000); let qu4 = (rnd(88899999)+1100000); 
    let qu5 = (rnd(88899999)+1100000); let ans; 
    //make sure none the same
    if(qu1=== qu2||qu3||qu4||qu5){qu1++};   if(qu2=== qu1||qu3||qu4||qu5){qu2++}    if(qu3=== qu2||qu1||qu4||qu5){qu3++}    if(qu4=== qu2||qu3||qu1||qu5){qu4++}
    if(qu5=== qu2||qu3||qu4||qu1){qu5++}; let numbers = [qu1, qu2, qu3, qu4, qu5]
    // put in order
  numbers.sort(function(a, b){return a - b});
    let answer =[]
    console.log(numbers, answer)
    let instruct2= `Please put commas between the letters in your answer. Do not use spaces.`
    let instruct1= `Place these numbers in order from lowest to highest:`
    let qu=  `a: ${numWC(qu1)}, b: ${numWC(qu2)}, c: ${numWC(qu3)}, d: ${numWC(qu4)}, e: ${numWC(qu5)}.`
   let an=`${answer.toString()}`
   let wO=''
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

export function  mean()  {
    let type = ['a','b']
    let qu1 = (rnd(9)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qu6 = (rnd(8)+2); let qu3=(rnd(9)+2) ; let qu4 = (rnd(9)+2) ;let qu7 = (rnd(9)+1); let qu5 = (rnd(9)+2); let qu, wO; 
    let instruct1= ``

    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { an = (qu6 + qu3 + qu4 +qu5)/4
        qu= ` What is the average of ${qu3},  ${qu4} , ${qu5} , ${qu6}?`
        ;  wO= ` ` }
    
        if (qu2 === 'b') { an = (qu6 + qu3 + qu4 +qu5+ qu7)/5
        qu= ` What is the mean of ${qu3},  ${qu4} , ${qu5} , ${qu6}, ${qu7}?`
        ;  wO= ` ` }
const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}

//// Reasoning 1


export function  KS1missingNumber ()  {
    let type = ['a','b']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qua = (rnd(3)+2); let qu3= qu1+qua; let qu4 = qu3+qua ;let qu5 = qu4+qua;let qu6 = qu5+qua; let qu, wO, img; 
    let instruct1

    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= `What number is missing in this sequence?`; an = qu3; 
        qu= ` ${qu1}, ? , ${qu4} , ${qu5} `
        ;  wO= ` `; img = "" }
    
        if (qu2 === 'b') { instruct2= `What number is missing in this sequence?`; an = qu4; ans = qu3*qu5 + qu4*qu6
        qu= `  ${qu1}, ${qu3} , ? , ${qu5}`
        ;  wO= ` `; img = "" }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function  KS1missingNumber2 ()  {
    let type = ['a','b']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qua = (rnd(8)+2); let qu3= qu1+qua; let qu4 = qu3+qua ;let qu5 = qu4+qua;let qu6 = qu5+qua; let qu, wO, img; 
    let instruct1

    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= `What number is missing in this sequence?`; an = qu3; 
        qu= ` ${qu1}, ? , ${qu4} , ${qu5} `
        ;  wO= ` `; img = "n4.png" }
    
        if (qu2 === 'b') { instruct2= `What number is missing in this sequence?`; an = qu4; ans = qu3*qu5 + qu4*qu6
        qu= `  ${qu1}, ${qu3} , ? , ${qu5}`
        ;  wO= ` `; img = "n2.png" }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function placeValues // use place value and number facts to solve problems.
()  {
    let type = ['a','b','c','d']; let indexQu2 = rnd(type.length); let qua = type[indexQu2];
    let qu1 = (rnd(8)+1); let qu2 = (rnd(6)+3); let qu3 = (rnd(7)+2); let qu4 = (rnd(8)+1); 
    
    let qu, wO, img, instruct1, an, ans
    let instruct2= ``; 
    
    if (qua === 'a') { instruct2= `How many units are in: `; an = qu1.toString();
        let qub = qu1 + qu2*10 + qu3 * 100 + qu4*1000
        qu= ` ${qub.toString()} `
        ;  wO= ``; img = "" }
    
    else if (qua === 'b') { instruct2= `How many tens are in: `; an = qu2.toString();
    let qub = qu1 + qu2*10 + qu3 * 100 + qu4*1000
    qu= ` ${qub.toString()} `
    ;  wO= ``; img = "" }

    else if (qua === 'c') { instruct2= `How many hundreds are in: `; an = qu3.toString();
    let qub = qu1 + qu2*10 + qu3 * 100 + qu4*1000
    qu= ` ${qub.toString()} `
    ;  wO= ``; img = "" }

    else if (qua === 'd') { instruct2= `How many thousands are in: `; an = qu4.toString();
    let qub = qu1 + qu2*10 + qu3 * 100 + qu4*1000
    qu= ` ${qub.toString()} `
    ;  wO= ``; img = "" }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function addSubtractPictures1_2 // 1_2, 2_10, 2_2, 1_1_1  solve problems with addition and subtraction: using concrete objects and pictorial representations, including those involving numbers, quantities and 
//measures; add and subtract numbers using concrete objects, pictorial representations, and mentally, including: a two-digit number and ones a two-digit number and tens two two-digit 
//numbers adding three one-digit numbers
()  {
    let qu1 = (rnd(9)); let qu2 = (rnd(3));  // addSub13.png   1 has variant   3 is the answer  //11,12,13 
    
    
    let img = 'addsub' + qu2.toString() + qu1.toString() + '.png'
    let qu = 'Work out:', wO ='',  instruct1=' ', an = qu1; if (qu2 === 1) {if (qu1=== 1){ an=11} else if(qu1=== 2){ an=12} else if(qu1=== 3){ an=13}}
    let instruct2= ``; 
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function timesDivide // with pictures for objects, 2_1

()  {
    let type = ['a','b']
    let qu1 = (rnd(8)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; let indexPeople = rnd(people.length); let indexNouns = rnd(nouns.length); 
    let qu3 = (rnd(3)+2);let qu4 = (rnd(8)+2);let qu5 = (rnd(8)+2);let qu6 = (rnd(8)+2); let img=''
    let qu, an, wO; let instruct1= ``; let name = people[indexPeople] ; let name2 =  people[indexPeople+1] ; let noun = nouns[indexNouns][0]
    let instruct2= ``; let ans; let noun2 = nouns[indexNouns][1]
    if (qu2 === 'a') { 
        qu= `${name} has ${qu1*qu3} ${noun2}. ${name} shares the ${noun2} with ${qu3} friends. How many ${noun2} does each friend get? `
        ;an= qu1;  wO= `(( ${qu1*qu3})/${qu3})` }
    if (qu2 === 'b') { 
            qu= `${qu1} children each have ${qu3} ${noun2}. How many ${noun2} are there in total? `
            ;an= qu6*qu3;  wO= `${qu1} x ${qu3}` }
            

const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function fractionShapeLengthQuantity // recognise, find, name and write fractions 1/, ¼.1/2. 3/4of a length, shape, set of objects or  quantity
()  {
   
    let fractions = ['1/2','1/4', '3/4'] ; let inverseFractions = ['1/2','3/4', '1/4']
    let type = ['a','b']
    let qu1 = (rnd(3)); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    let qu, wO, img; let qu3= (rnd(3)); let qu4 = fractions[qu3] ; let qu5 = inverseFractions[qu3] ;
    let instruct1
    
    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= `How much has gone?`; an = qu4; 
        qu= ` `
        ;  wO= ` `; img =  "fracShape"+ qu1.toString() + qu3.toString() + ".png" }
    
        if (qu2 === 'b') { instruct2= `How much is left?`; an = qu5
        qu= ``
        ;  wO= ` `; img = "fracShape"+ qu1.toString() + qu3.toString() + ".png" }

     
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function measureLengthMassTempVolTime // choose and use appropriate standard units to estimate and measure length/height in any direction (m/cm); mass (kg/g); temperature (°C); capacity (litres/ml) to the nearest appropriate unit, using rulers, scales, thermometers and measuring vessels
()  {
    
    let qu, wO, img; 
    let instruct1 ='', thing
    let quantum = ['length','mass','volume','time or duration']
    let thinga = ['book','ball','bottle','clock', 'cup','glass','jug','key','knife','laptop','mobile phone','pen','pencil','plate','remote control','scissors','spoon','table','watch']
    let item = [ 'video', 'lesson', 'race']
   
    let qu3 = rnd(quantum.length); let qu4 = quantum[qu3] ;
    let units = ['cm','kg','ml','s']; 
    if (qu3 == 3) {thing = item[rnd(item.length)]}
    else {thing = thinga[rnd(thinga.length)]}
        let instruct2= `Is it cm, kg, ml or s `; let an = units[qu3] ; 
        qu= `What units are used to measure the ${quantum[qu3]} of a ${thing}? `
        wO= ` `; img = "" 

const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2}
return question}


export function compareLengthMassVol // compare and order lengths, mass, volume/capacity and record the results using >, < and =
()  {
    let an, qu, wO, img; let qu1 = rnd(8)+1; let qu3 = rnd(8)+1
    if (qu1 === qu3) { an = '='}
    else if (qu1 > qu3) { an ='>'}
    else { an = '<'}
    let instruct1 ='', thing, compare, compareAlt
    let quantum = ['length','mass','volume','time or duration']
    let comparea = ['longer','heavier','bigger','longer']
    let compareb = ['shorter','lighter','smaller','shorter']
    let thinga = ['book','ball','bottle','clock', 'cup','glass','jug','key','laptop','mobile phone','pen','pencil','plate','remote control','scissors','spoon','table','watch']
    let item = [ 'video', 'lesson', 'race']
    let units = ['cm','kg','ml','s']; 
    let qua = rnd(quantum.length)
   
  compare = comparea[qua]; compareAlt = compareb[qua]
      if (qua == 3) {thing = item[rnd(item.length)]}
    else if (qua == 1) {thing = thinga[rnd(thinga.length)]}
    else if (qua == 2) {thing = thinga[rnd(thinga.length)]}
    else if (qua == 0) {thing = thinga[rnd(thinga.length)]} 
    else {thing = thinga[thinga.length]}
        let instruct2= `answer using < , > or = `; 
        qu= `Is  a  ${thing} of ${qu1} ${units[qua]} or a ${thing} of ${qu3} ${units[qua]} ${compare}, ${compareAlt} or the same? `
        wO= ` `; img = "" 
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2}
return question}


export function addSubtractMoney // recognise and use symbols for pounds (£) and pence (p); combine amounts to make a particular value
()  {// create two random numbers between 1 and 10 for pounds and two random numbers between 1 and 99 for pence
    let qu, wO, img; let qu1 = rnd(5)+1; let qu2 = rnd(49)+1; let qu3 = rnd(7)+1; let qu4 = rnd(48)+1; qu1 = qu1+qu3; qu2 = qu2+qu4


    let type = ['a','b']
    let indexQu2 = rnd(type.length); let qua = type[indexQu2];
    let thinga = ['book','ball','bottle','clock', 'cup','glass','jug','key','laptop','mobile phone','pen','pencil','plate','remote control','scissors','spoon','table','watch'] 
    let thing = thinga[rnd(thinga.length)]
    let name = people[rnd(people.length)]
    let instruct2
    let instruct1= ``; let an; instruct2= `Give your answer as a decimal if necessary.`; wO= ` `; img = ``
    if (qua === 'a') { ; an = (qu1*100 + qu3*100 + qu2 + qu4)/100     
    qu= `Add ${qu1} pounds and ${qu2} pence to ${qu3} pounds and ${qu4} pence.`
        }
    
    if (qua === 'b') { an = (qu1*100 - qu3*100 + qu2 - qu4)/100
        qu= `${name} has ${qu1} pounds and ${qu2} pence. ${name} spends ${qu3} pounds and ${qu4} pence on a ${thing}. How much money does ${name} have left?`
     }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2}
return question}


export function coinAdd // find different combinations of coins that equal the same amounts of money
()  {
   let answers = [50, 55, 64, 68, 73, 80, 85,89, 91, 95, 99 ]
    let qu, wO, img; let qu1 = rnd(answers.length); let qu3 = answers[qu1];
    let instruct1
    
    let instruct2= ``; let an = qu3; 
        qu= `How much is there in total? `
        ;  wO= ` `; img =  "coinAdd"+ qu3.toString() + ".png" 
    
        
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function coinChange // solve simple problems in a practical context involving addition and subtraction of money of the same unit, including giving change
()  {
    let answers = [11,12,15,19, 20, 23, 27, 28, 30, 36, 42]
    let qu, wO, img; let qu1 = rnd(answers.length); let qu3 = answers[qu1];
    let instruct1
    let instruct2= ``; let an = qu3;  
    qu= `How much is there in total? `
    wO= ` `; img =  "coinAdd"+ qu3.toString() + ".png" 
    
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function tellTime // compare and sequence intervals of time; tell and write the time to five minutes, including quarter past/to the hour and draw the hands on a clock face to show these times
()  {
   
    let times =['10:00', '10:15', '10:30', '10:45', '11:00', '11:15', 
    '11:30', '12:00', '1:00', '2:00', '2:30', '2:45', '3:30', '4:45', 
    '5:00', '5:15', '5:30', '5:45', '6:00', '6:15', '6:30', '6:45']
    let qua = (rnd(times.length)); let qu, wO, img; 
    let instruct2
    let instruct1= ``; let an; let ans; 

    instruct2= `use the format of hour:minutes, eg 7:30`; an = times[qua]; 
        qu= `What time is this? `
        ;  wO= ` `; img = "tellTime" + qua +".png" 
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2}
return question}


export function minutesHourDay// know the number of minutes in an hour and the number of hours in a day.
()  {
    let type = ['a','b','c','d','e','f','g']
    let qu1 = (rnd(19)+2); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    
    let qua = (rnd(8)+2); let qu3= qu1+qua; let qu4 = qu3+qua ;let qu5 = qu4+qua;let qu6 = qu5+qua; let qu, wO, img; 
    let instruct1

    let instruct2= ``; let an; 
    
    if (qu2 === 'a') { instruct2= ``; an = '60'; 
        qu= ` How many minutes are there in an hour?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'b') { instruct2= ``; an = '24'; 
        qu= ` How many hours are there in a day?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'c') { instruct2= ``; an = '7'; 
        qu= ` How many days are there in a week?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'd') { instruct2= ``; an = '14'; 
        qu= ` How many days are there in 2 weeks?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'e') { instruct2= ``; an = '30'; 
        qu= ` How many minutes are there in a half an hour?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'f') { instruct2= ``; an = '48'; 
        qu= ` How many hours are there in 2 days?`
        ;  wO= ` `; img = "" }
    if (qu2 === 'g') { instruct2= ``; an = '15'; 
        qu= ` How many minutes are there in a quarter of an hour?`
        ;  wO= ` `; img = "" }   
        
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function IdentifyShape // identify and describe the properties of 2-D shapes, including the number of sides and line symmetry in a vertical line; identify and describe the properties of 3-D shapes, including the number of edges, vertices and faces
()  {
    let type = ['a','b','c']
  
    let shape =  ['circle','triangle', 'square', 'rectangle',  'pentagon', 'hexagon', 'heptagon', 'octagon', 'nonagon', 'decagon']
    let linesofSymmetry = ['0','1','4','2','5','6','7','8','9','10']
    let vertices = ['0','3','4','4','5','6','7','8','9','10']
    

    let qu1 = (rnd(shape.length)); let indexQu2 = rnd(type.length); let qu2 = type[indexQu2]; 
    let qu, wO, img; 
    let instruct1
    let instruct2= ``; let an; let ans; 
    
    if (qu2 === 'a') { instruct2= ``; an = shape[qu1]; 
        qu= `Name this shape?`
        ;  wO= ` `; img = "shape"+ shape[qu1]+ ".png" }
    
    if (qu2 === 'b') { instruct2= ``; an = vertices[qu1];
        qu= `How many sides are there in a ${shape[qu1]}?`
        ;  wO= ` `; img = "" }

    if (qu2 === 'c') { 
        if (qu1===0){qu1=2} 
        else if (qu1===1){qu1=2} 
        instruct2= ``; an = linesofSymmetry[qu1];  
        qu= `How many lines of symmetry are there in a ${shape[qu1]}?`
        ;  wO= ` `; img = "" }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function TwoDonThreeD // identify 2-D shapes on the surface of 3-D shapes, [for example, a circle on a cylinder and a triangle on a pyramid] compare and sort common 2-D and 3-D shapes and everyday objects

()  {
  
    let threeDShapes = ['cube', 'cuboid', 'cylinder', 'cone', 'triangular pyramid', 'pyramid']
   let twodShapes = ['square', 'rectangle', 'circle', 'circle', 'triangle', 'square']
   
    let qu1 = (rnd(threeDShapes.length)); 
    let qu, wO, img, an, instruct1; let instruct2= ``; 
    an = twodShapes[qu1];; 
    qu= ` What shape is on the base of a ${threeDShapes[qu1]}? `
    wO= ` `; img = "" 
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function turnAngle // use mathematical vocabulary to describe position, direction and movement, including movement in a straight line and distinguishing between rotation as a turn and interms of right angles for quarter, half and three-quarter turns (clockwise and anticlockwise).

()  {
    let direction = ['turns clockwise by a quarter turn','turns anticlockwise by a quarter turn','turns clockwise by a half turn','turns anticlockwise by a half turn',
    'turns clockwise by three-quarter turn','turns anticlockwise by three-quarter turn','turns clockwise by a full turn','turns anticlockwise by a full turn' ]
    let degree = ['2', '4', '3', '3', '4', '2', '1', '1']
    let qu1 = (rnd(direction.length)); 
    let qu, wO, img, an; 
    let instruct1
    let instruct2= ``; an = degree[qu1];; 
        qu= ` What number will the arrow point at if it ${direction[qu1]}? `
        ;  wO= ` `; img = "turn000.png"
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function createChart // ask and answer simple questions by counting the number of objects in each category and sorting the categories by quantity
()  {
    let type = ['a','b','c']; let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    let qu1 = (rnd(19)+2); 
    
    let qu, wO, img; 
    let objects = ['pens', 'pencils', 'erasers', 'rulers', 'sharpeners', 'books', 'bags', 'pencil cases', 'notebooks', 'folders', 'calculators', 'laptops', 'phones', 'watches', 'glasses', 'headphones', 'lunch boxes', 'tissues' ]    
    let qua1 = rnd(objects.length); let thing1 = objects.splice(qua1,1); let qua2 = rnd(objects.length); let thing2 = objects.splice(qua2,1); 
    let qua3 = rnd(objects.length); let thing3 = objects.splice(qua3,1); let qua4 = rnd(objects.length); let thing4 = objects.splice(qua4,1); 
    let qua5 = rnd(objects.length); let thing5 = objects.splice(qua5,1);

    let tally = [" ", "/","//", "///", "////", "/////", "///// /", "///// //", "///// ///", "///// ////", "///// /////"]
    let numbers = [0,1,2,3,4,5,6,7,8,9,10]
    let qub1 = numbers.splice([rnd(numbers.length)],1); let qub2 = numbers.splice([rnd(numbers.length)],1); 
    let qub3 = numbers.splice([rnd(numbers.length)],1); let qub4 = numbers.splice([rnd(numbers.length)],1); 
    let qub5 = numbers.splice([rnd(numbers.length)],1);
    qub1=qub1[0]; qub2=qub2[0]; qub3=qub3[0]; qub4=qub4[0]; qub5=qub5[0];

    let tally1 = tally[qub1]; let tally2 = tally[qub2]; let tally3 = tally[qub3]; let tally4 = tally[qub4]; let tally5 = tally[qub5];
    let thingsArray = [thing1, thing2, thing3, thing4, thing5]
    let answersArray = [qub1, qub2, qub3, qub4, qub5]
    console.log("arrays" + thingsArray + answersArray)
    let quc1 =rnd(thingsArray.length); 
    let things = thingsArray[quc1]
    
    let instruct1 

    let instruct2= `This is a tally chart of items in the classroom`; let an; let ans; 
    
   
       
    let line = `Item  |  tally`
    let line1= ` ____________________`
    let line2= `${thing1} | ${tally1}`
    let line3 = `____________________`
    let line4= `${thing2} | ${tally2}`
    let line5=` ____________________ `
    let line6=`${thing3} | ${tally3}`
    let line7=`____________________`
    let line8=`${thing4} | ${tally4}`
    let line9=`____________________`
    let line10=`${thing5} | ${tally5}`

    qu = [line, line1, line2, line3, line4, line5, line6, line7, line8, line9, line10]

        
        
        if (qu2 == 'a') { instruct1= `What is the least common item in the room?`;
        let num = Math.min( qub1, qub2, qub3, qub4, qub5)
        console.log("wahay", num, qub1, qub2, qub3, qub4, qub5)
        if (num == qub1) {an = thing1}
        else if (num == qub2) {an = thing2}
        else if (num == qub3) {an = thing3}
        else if (num == qub4) {an = thing4}
        else if (num == qub5) {an = thing5}
        else {an = 'error'; console.log(num, qub1, qub2, qub3, qub4, qub5)}
       ;  wO= ` `; img = " " }
    
        if (qu2 == 'b') { instruct2= `What is the most common item in the room?`; 
        let num = Math.max( qub1, qub2, qub3, qub4, qub5)
        console.log("wtf", num, qub1, qub2, qub3, qub4, qub5)
        if (num == qub1) {an = thing1}
        else if (num == qub2) {an = thing2}
        else if (num == qub3) {an = thing3}
        else if (num == qub4) {an = thing4}
        else if (num == qub5) {an = thing5}
        else {an = 'error'; console.log(num, qub1, qub2, qub3, qub4, qub5)}
         ;  wO= ` `; img = " " }

        if (qu2 === 'c') { instruct2= `How many ${things} are there?`; an = answersArray[quc1].toString();
        ;  wO= ` `; img = " " }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function interpretChart // interpret and construct simple pictograms, tally charts, block diagrams and simple tables 
()  {
    let type = ['c']; let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    let qu1 = (rnd(19)+2); 
    
    let qu, wO, img; 
    let objects = ['pens', 'pencils', 'erasers', 'rulers', 'sharpeners', 'books', 'bags', 'pencil cases', 'notebooks', 'folders', 'calculators', 'laptops', 'phones', 'watches', 'glasses', 'headphones', 'lunch boxes', 'tissues' ]    
    let qua1 = rnd(objects.length); let thing1 = objects.splice(qua1,1); let qua2 = rnd(objects.length); let thing2 = objects.splice(qua2,1); 
    let qua3 = rnd(objects.length); let thing3 = objects.splice(qua3,1); let qua4 = rnd(objects.length); let thing4 = objects.splice(qua4,1); 
    let qua5 = rnd(objects.length); let thing5 = objects.splice(qua5,1);

    let tally = [" ", "/","//", "///", "////", "/////", "///// /", "///// //", "///// ///", "///// ////", "///// /////"]
    let numbers = [0,1,2,3,4,5,6,7,8,9,10]
    let qub1 = numbers.splice([rnd(numbers.length)],1); let qub2 = numbers.splice([rnd(numbers.length)],1); 
    let qub3 = numbers.splice([rnd(numbers.length)],1); let qub4 = numbers.splice([rnd(numbers.length)],1); 
    let qub5 = numbers.splice([rnd(numbers.length)],1);
    qub1=qub1[0]; qub2=qub2[0]; qub3=qub3[0]; qub4=qub4[0]; qub5=qub5[0];
    let tally1 = tally[qub1]; let tally2 = tally[qub2]; let tally3 = tally[qub3]; let tally4 = tally[qub4]; let tally5 = tally[qub5];
    let thingsArray = [thing1, thing2, thing3, thing4, thing5]
    let answersArray = [qub1, qub2, qub3, qub4, qub5]
    let quc1 = rnd(thingsArray.length); 
    let things = thingsArray[quc1]

    let instruct1 

    let instruct2= `This is a tally chart of items in the classroom`; let an; let ans; 
   
    let line = `Item  |  tally`
    let line1= ` ____________________`
    let line2= `${thing1} | ${tally1}`
    let line3 = `____________________`
    let line4= `${thing2} | ${tally2}`
    let line5=` ____________________ `
    let line6=`${thing3} | ${tally3}`
    let line7=`____________________`
    let line8=`${thing4} | ${tally4}`
    let line9=`____________________`
    let line10=`${thing5} | ${tally5}`

    qu = [line, line1, line2, line3, line4, line5, line6, line7, line8, line9, line10]
 
       

        if (qu2 === 'c') { instruct2= `How many ${things} are there?`; an = answersArray[quc1].toString();
        ;  wO= ` `; img = " " }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


export function compareData  // ask and answer questions about totalling and comparing categorical data.
()  {
    let type = ['a','b']; let indexQu2 = rnd(type.length); let qu2 = type[indexQu2];
    let qu1 = (rnd(19)+2); let qu, wO, img; 
    let objects = ['pens', 'pencils', 'erasers', 'rulers', 'sharpeners', 'books', 'bags', 'pencil cases', 'notebooks', 'folders', 'calculators', 'laptops', 'phones', 'watches', 'glasses', 'headphones', 'lunch boxes', 'tissues' ]    
    let qua1 = rnd(objects.length); let thing1 = objects.splice(qua1,1); let qua2 = rnd(objects.length); let thing2 = objects.splice(qua2,1); 
    let qua3 = rnd(objects.length); let thing3 = objects.splice(qua3,1); let qua4 = rnd(objects.length); let thing4 = objects.splice(qua4,1); 
    let qua5 = rnd(objects.length); let thing5 = objects.splice(qua5,1);

    let tally = [ " ", "/","//", "///", "////", "/////", "///// /", "///// //", "///// ///", "///// ////", "///// /////"]
    let numbers = [0,1,2,3,4,5,6,7,8,9,10]
    let qub1 = numbers.splice([rnd(numbers.length)],1); let qub2 = numbers.splice([rnd(numbers.length)],1); 
    let qub3 = numbers.splice([rnd(numbers.length)],1); let qub4 = numbers.splice([rnd(numbers.length)],1); 
    let qub5 = numbers.splice([rnd(numbers.length)],1);
    qub1=qub1[0]; qub2=qub2[0]; qub3=qub3[0]; qub4=qub4[0]; qub5=qub5[0];
    let tally1 = tally[qub1]; let tally2 = tally[qub2]; let tally3 = tally[qub3]; let tally4 = tally[qub4]; let tally5 = tally[qub5];
    let thingsArray = [thing1, thing2, thing3, thing4, thing5]
    let answersArray = [qub1, qub2, qub3, qub4, qub5]
    let quc1 = rnd(thingsArray.length); 
    let things = thingsArray[quc1]

    let instruct1 

    let instruct2= `This is a tally chart of items in the classroom`; let an; let ans; 
    
   
        let line = `Item  |  tally`
        let line1= ` ____________________`
        let line2= `${thing1} | ${tally1}`
        let line3 = `____________________`
        let line4= `${thing2} | ${tally2}`
        let line5=` ____________________ `
        let line6=`${thing3} | ${tally3}`
        let line7=`____________________`
        let line8=`${thing4} | ${tally4}`
        let line9=`____________________`
        let line10=`${thing5} | ${tally5}`

        qu = [line, line1, line2, line3, line4, line5, line6, line7, line8, line9, line10]

        if (qu2 === 'a') { instruct1= `What is the total number of items in the room?`; 
        an = parseInt(qub1) + parseInt(qub2) + parseInt(qub3) + parseInt(qub4) + parseInt(qub5);
        ;  wO= ` `; img = " " }
    
        if (qu2 === 'b') { instruct2= `How many ${things} are there?`; an = answersArray[quc1].toString();
        ;  wO= ` `; img = " " }
   
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct2, instruct2: instruct1}
return question}


/*
//Reasoning 1
placeValues, addSubtractPictures1_2, timesDivide, fractionShapeLengthQuantity, measureLengthMassTempVolTime, compareLengthMassVol, addSubtractMoney, coinAdd,
 coinChange, tellTime, minutesHourDay, IdentifyShape, TwoDonThreeD, KS1missingNumber, turnAngle, interpretChart, createChart, compareData 

placeValues(), addSubtractPictures1_2(), timesDivide(), fractionShapeLengthQuantity(), measureLengthMassTempVolTime(), compareLengthMassVol(), addSubtractMoney(), 
coinAdd(), coinChange(), tellTime(), minutesHourDay(), IdentifyShape(), TwoDonThreeD(), KS1missingNumber(), turnAngle(), interpretChart(), createChart(), compareData() 
*/
/*  
orderUpTo8, roundWhole, roundDecimal, bodmas1_1_1_1, multistepWord, estimateCheck, commonFactor, 
CommonMultip, compareFraction, orderFraction, decimalEquiv, percentEquiv, relativeSize, calculatePercent, 
shapeProblem, shareProblem, simpleFormula, linearSequence, missingNumber, equationPair , possibleCombinations
convertUnit3length, convertUnit3mass, convertUnit3time, convertUnit3vol, milesKilometres, areaPerimeter, a
reaOfTriangle, areaOfParr , volCuboid, compareVolCuboid, unknownAngle, compareAreaPolygon, compareLengthPolygon, 
compareAnglePolygon, nameCircle, verticalOpp, translateShape, pie, line, mean
*/

/*  
orderUpTo8, roundWhole, roundDecimal, bodmas1_1_1_1, multistepWord, estimateCheck, commonFactor, 
CommonMultip, compareFraction, orderFraction, decimalEquiv, percentEquiv, relativeSize, calculatePercent, 
shapeProblem, shareProblem, simpleFormula, linearSequence, missingNumber, equationPair , possibleCombinations
convertUnit3length, convertUnit3mass, convertUnit3time, convertUnit3vol, milesKilometres, areaPerimeter, a
reaOfTriangle, areaOfParr , volCuboid, compareVolCuboid, unknownAngle, compareAreaPolygon, compareLengthPolygon, 
compareAnglePolygon, nameCircle, verticalOpp, translateShape, pie, line, mean
*/
