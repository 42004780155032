import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/userAuthContext";
import { auth } from "../firebase-config"; import { useSelector, useDispatch } from "react-redux";

const User = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const reviseList = useSelector((state) => state.questionSet.revise);
     
   const user = useSelector((state) => state.userSet.user);
   const userFireStore = useSelector((state)=> state.userFireSet.first)
    let name = 'Guest'
 if (user != null) { if (userFireStore != "") {name = userFireStore} else if (user.displayName != undefined){name = user.displayName}}

const handleAccount =()=>{ navigate('/account')}
  
    return (
    
        
        <button className='navi' onClick={()=>{handleAccount()}}>
        {name} is logged in 
        </button>
      
    
  );
};

export default User;



