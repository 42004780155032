import React from "react"; import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; import { setLevel } from "../redux/questionSlice";

const Item = () => {
        let history = useNavigate();
        return (
        <button className={"navi"} onClick={() => history(-1)}> Back</button>
        ); };

export function Navigation (){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let level = useSelector((state) => state.questionSet.level)
    const handleUpLevel = ()=>{let newLevel = level -1; if (newLevel < 0) {newLevel = 0}; dispatch(setLevel(newLevel)); }
   
    const handleCatalogue = () => {navigate("/catalogue")}
    const handleTest = () => {navigate("/")}
    const handleSats = () => {navigate("/sats")}
   
    const handleHome = () => {navigate("/")}
    return (
        < >
            <Item />
            <button className={"navi"} onClick={() => { handleCatalogue(); } }>Learn</button>  
              <button className={"navi"} onClick={() => { handleUpLevel(); } }>Back Up</button>  
              <button className={"navi"} onClick={() => { handleTest(); } }>Test</button>  
              <button className={"navi"} onClick={() => { handleSats(); } }>Sats</button>  
     
        </>
    )
}

/*<NavLink to="/learn">Revise</NavLink>
<div className='navi' ><NavLink  to="/">Home</NavLink></div>
            <div className='navi' ><NavLink to="/catalogue"> Learn </NavLink></div>
            <div className='navi' ><NavLink  to="/">Test</NavLink></div>

            */