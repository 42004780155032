import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, FormGroup } from "react-bootstrap"; import { addUserFirestore } from "./Signup";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { UserAuthContextProvider, useUserAuth } from "../context/userAuthContext";
import {auth, db} from '../firebase-config'
import { doc, setDoc, addDoc, updateDoc, getDocs, getDoc, collection } from "firebase/firestore"; 
import { useDispatch, useSelector } from "react-redux"; import {login} from '../redux/userSlice';
import {fetchUserFromFirestore, addLoginMethod} from '../redux/userFireSlice'; 
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; import { revise } from "../redux/questionSlice";
import { Welcome } from "./welcome";
 
export function ReviseList(){
  console.log('reviselist start')
  const fireReviseList = useSelector((state)=> state.userFireSet.reviseList ) 
  console.log('reviselist ' + fireReviseList)
  const userRevise = useSelector((state)=> state.questionSet.revise)
  console.log('reviselist ' + userRevise)
  const reviseList = fireReviseList.concat(userRevise)
  const dispatch = useDispatch();
  dispatch(revise(reviseList))
  console.log('revise list sent'+ reviseList)
}

export async function emailLoginUpdate (){
  const userRef = doc(db, 'users', auth.currentUser.uid);
  await updateDoc(userRef, { lastLogin: Date() }, { merge: true });} /// add save data on loading here

export async function GoogleLogin (initialAchievements){
  
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      

      if (!docSnap.exists()){
        try {
          await setDoc(doc(db, "users", auth.currentUser.uid), {
            first: auth.currentUser.displayName,
            userId: auth.currentUser.uid,
            points: initialAchievements.points,
            dateJoined: Date(),
            lastLogin: Date(),
            reviseList: initialAchievements.reviseList,
            modulesCompleted: initialAchievements.modulesCompleted,
            testResults: initialAchievements.testResults,
            sats: initialAchievements.SatsComplete

                });
          console.log("New User Document written with ID: ", auth.currentUser.uid);
         
        } catch (e) {
          console.error("Error adding document: ", e);
        }
        ;}
      else { 
        const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, { lastLogin: Date() }, { merge: true });}  /// add elements to save on logging in here
      }



export const Login = () => {
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      const [error, setError] = useState("");
      const { logIn, googleSignIn, anonymousSignIn } = useUserAuth();
      const navigate = useNavigate();
      const dispatch = useDispatch();
       
      const handleForgot = async (e) => {
        e.preventDefault();
          const auth = getAuth();
          console.log('email'+ email + auth);
        try {await sendPasswordResetEmail(auth, email);     alert("Password reset link sent!"); }
            catch(err){ console.log('email'+ email + err)       
          }
        }

      const handleSignUp = () => {  navigate("/signup")  }
   
      const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
          await logIn(email, password)
          .then((userAuth) => {
            // store the user's information in the redux state
              dispatch(
                login({
                  email: userAuth.user.email,
                  uid: userAuth.user.uid,
                  displayName: userAuth.user.displayName,
                  photoUrl: userAuth.user.photoURL,
                })
              );
              dispatch(addLoginMethod('email'))
              dispatch(fetchUserFromFirestore(userAuth.user.uid))
              console.log(userAuth.user.uid, userAuth.user)
              ReviseList()
            })
            ReviseList()
          emailLoginUpdate()
          navigate("/home")          
          

        }
        catch (err) {
          setError(err.message);
        }
      };
      const initialAchievements = useSelector((state) => state.userFireSet)

      const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
          await googleSignIn()
          .then((userAuth) => {
            // store the user's information in the redux state
              dispatch(
                login({
                  email: userAuth.user.email,
                  uid: userAuth.user.uid,
                  displayName: userAuth.user.displayName,
                  photoUrl: userAuth.user.photoURL,
                })  );
                dispatch(addLoginMethod('Google'))
              
               GoogleLogin(initialAchievements );
               dispatch(fetchUserFromFirestore(userAuth.user.uid))
             
             // console.log(userAuth.user.uid, userAuth.user)
             ReviseList()
            })
            ReviseList()
            GoogleLogin(initialAchievements )
         
          navigate("/home");
          
        } catch (error) {
          console.log(error.message);
        }
      };

      const handleAnonSignIn = async (e) => {
        e.preventDefault();

        try { 
          await anonymousSignIn();
          GoogleLogin().then((userAuth) => {
            // store the user's information in the redux state
              dispatch(
                login({
                  email: userAuth.user.email,
                  uid: userAuth.user.uid,
                  displayName: userAuth.user.displayName,
                  photoUrl: userAuth.user.photoURL,
                })
              );})
              dispatch(addLoginMethod('anon'))
          console.log('calling googleLogin from anon');
          navigate("/home");
        }
        catch (error)  { console.log(error.message);}
      }

      const autoSignIn = async () =>{
        try { 
          await anonymousSignIn().then((userAuth) => {
            // store the user's information in the redux state
              dispatch(
                login({
                  email: 'not set',
                  uid: userAuth.user.uid,
                  displayName: 'Guest',
                  photoUrl: 'not set',
                })
              );})
          GoogleLogin();
          console.log('calling googleLogin from anon');
          navigate("/home");
         
        }
        catch (error)  { console.log(error.message);}
      }

//autoSignIn()

      return (  <div>
         <div className="login">
          
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <></>
              <Form.Group className="loginForm" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  className="email"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="loginForm" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  className="email"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
<></>
     <button className="loginButton" type="Submit"> Agree to essential cookies, privacy policy and <h2>Log In </h2></button>
          
            </Form>
            <button className='loginButton' onClick={handleForgot}>Forgotten Password</button>
          <div>
              <button className="loginButton"  onClick={handleGoogleSignIn} >
                Agree to essential cookies, privacy policy and <h2>Login with Google</h2></button>
             <button className="loginButton"   onClick={handleAnonSignIn}>
              Agree to essential cookies, privacy policy and <h2>Proceed as Guest</h2></button>
          </div>
          
          <div className="loginButton">
            Don't have an account? 
            <button className='loginButton' onClick={()=>{handleSignUp()}}>Sign Up</button>
            
          </div>
        </div></div>
      );
};

export default Login;
