import React from "react"; import { useDispatch, useSelector } from "react-redux";
import { holdTheQuestions, holdTheFractions } from "../redux/questionSlice";
import { determiner, conjunction, preposition, pronoun, semicolon, comma, hyphen, colon, formal, noun, statement, synonym, antonym, clauses, questions} from "./englishSats";
import {orderUpTo8, roundWhole, roundDecimal,  bodmas1_1_1_1,  multistepWord, estimateCheck, commonFactor, 
    CommonMultip, compareFraction, orderFraction, decimalEquiv, percentEquiv, relativeSize, calculatePercent, 
    shapeProblem, shareProblem, simpleFormula, linearSequence, missingNumber, equationPair ,
    convertUnit3length, convertUnit3mass, convertUnit3time, convertUnit3vol, milesKilometres, areaPerimeter, areaOfTriangle, 
    areaOfParr , volCuboid, compareVolCuboid, unknownAngle, possibleCombinations, KS1missingNumber2 ,
    //compareAreaPolygon, compareLengthPolygon, compareAnglePolygon, nameCircle, 
    //verticalOpp, translateShape, pie, line, 
    mean, placeValues, addSubtractPictures1_2, timesDivide, fractionShapeLengthQuantity, measureLengthMassTempVolTime, compareLengthMassVol, addSubtractMoney, coinAdd,
    coinChange, tellTime, minutesHourDay, IdentifyShape, TwoDonThreeD, KS1missingNumber, turnAngle, interpretChart, createChart, compareData  } from './reasoning'
import { settingQuestions } from "./arithmeticQuestionsEasy";

//console.log( settingQuestions(), setQuestions2Easy)  // 
// console.log(setFractionsEasy)

function simplify(c,d){let e=0
    console.log(c,d)
    if (d >= c){e = 1; d = d - c} // d is numerator, c is denominator
    while(d >= c){e++; d = d - c} // removes whole numbers from a top heavy fraction
    if(d === 0) {d = 0; c = 0; return [c,d,e]}
    while (c%2 === 0 && d%2 === 0 ){c = c/2; d = d/2}
    while (c%3 === 0 && d%3 === 0 ){ c=c/3; d = d/3}
    while (c%5 === 0 && d%5 === 0 ){c = c/5; d = d/5}
    while (c%7  === 0 && d%7 === 0 ){c = c/7; d = d/7}
    while (c%11  === 0 && d%11 === 0 ){c = c/11; d = d/11}
    console.log(c,d,e)
    return [c,d,e]
}

function fix(dec, place) {  /// 23 - 33, ultiply by integer comes out upside down; subtract complex simple - incorrect answer
    // limit to decimal places
   let x = dec.toFixed(place)

x = parseFloat(x)
if(x===0){x=0.1}
    //remove trailling 0s
    if (place === 2 ) { let a = x%0.1; if ( a > 0) {x = x.toFixed(1); x = parseFloat(x);let b = x%1; 
        if ( b > 0) {x = x.toFixed(0); x = parseFloat(x); }}}
    if (place === 3 ) { let c = x%0.01 ; if ( c > 0) {x = x.toFixed(2); x = parseFloat(x); let a = x%0.1; 
        if ( a > 0) { x = x.toFixed(1) ; x = parseFloat(x);
                        let b = x%1; if ( b > 0) {x = x.toFixed(0); x = parseFloat(x); }}}}
    if (place === 4 ) {  let d = x%0.001; if ( d > 0) {x= x.toFixed(3); x = parseFloat(x);let c = x%0.01; 
        if ( c > 0) {x.toFixed(2); x = parseFloat(x); 
        let a = x%0.1; if ( a > 0) { x = x.toFixed(1); x = parseFloat(x);let b = x%1; if ( b > 0) {x = x.toFixed(0); x = parseFloat(x) }}}}}

    return x

}

// set out array of prime numbers
    const prime = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97]

    const easyFractionPairs = [[1,10, 0.1],[1,5, 0.2],[1,4, 0.25],[3,10, 0.3],[1,3, 0.333],[2,5, 0.4], [1,2, 0.5],
     [6,10, 0.6], [2,3, 0.666],[7,10, 0.7],[3,4, 0.75], [4,5, 0.8],[9,10, 0.9] ]
    const difficultFractionPairs = [[1,11],[1,10, 0.1],[1,9], [1,7],[1,5, 0.2], [2,9], [1,4, 0.25],[2,7],
    [3,10, 0.3],[1,3, 0.333],[2,5, 0.4],[3,7], [4,9], [1,2, 0.5],  [5,9],[4,7], [6,10, 0.6], [2,3, 0.666],
    [7,10, 0.7] , [5,7],[3,4, 0.75], [7,9], [4,5, 0.8],[6,7],[8,9],[9,10, 0.9], [10,11] ]
// function for random numbers 
    function rnd (range) { let num = (Math.floor(Math.random()* range)); return num}

// react components + functions for full choice of possible questions
        // answers to state
        // send question, answer and working out to state  ?? send array for each question

        //list subgroups of questions

      
    function add1_1(){
            let qu1 = (rnd(8)+1); let qu2 = rnd(7)+1; let ans = qu1 + qu2;
             let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question}

        function add1_1a(){
            let qu1 = (rnd(8)+1); let qu2 = rnd(7)+1; let ans = qu1 + qu2;
             let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question}

        


    function add1_2(){
            let qu1 = (rnd(8)+1); let qu2 = rnd(88)+10; let ans = qu1 + qu2;
            
            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question}

        function add1_2a(){
            let qu1 = (rnd(8)+1); let qu2 = rnd(88)+10; let ans = qu1 + qu2;
            
            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question}

    function add2_2(){
            let qu1 = (rnd(49)+10); let qu2 = rnd(98)+10; let ans = qu1 + qu2;
            let wO2 = qu1%10 // units from the first number
            
            let wO4 = qu2%100 // tens and units from the second
            let wO3 = wO4%10  // units from the second
            let wO5 = qu2-wO3 // tens from the second number

            let wO1 = `${qu2} \n ${qu1} \n `

            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question} 

        function add2_2a(){
            let qu1 = (rnd(49)+10); let qu2 = rnd(98)+10; let ans = qu1 + qu2;
            let wO2 = qu1%10 // units from the first number
            
            let wO4 = qu2%100 // tens and units from the second
            let wO3 = wO4%10  // units from the second
            let wO5 = qu2-wO3 // tens from the second number

            let wO1 = `${qu2} \n ${qu1} \n `

            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question} 

        function add2_2b(){
            let qu1 = (rnd(49)+10); let qu2 = rnd(98)+10; let ans = qu1 + qu2;
            let wO2 = qu1%10 // units from the first number
            
            let wO4 = qu2%100 // tens and units from the second
            let wO3 = wO4%10  // units from the second
            let wO5 = qu2-wO3 // tens from the second number

            let wO1 = `${qu2} \n ${qu1} \n `

            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question} 

    function add2_3digits (){
            let qu1 = (rnd(49)+10); let qu2 = rnd(898)+100; let ans = qu1 + qu2;
            let wO2 = qu1%10 // units from the first number
            
            let wO4 = qu2%100 // tens and units from the second
            let wO3 = wO4%10  // units from the second
            let wO5 = qu2-wO3 // tens from the second number

            let wO1 = `${qu2} \n ${qu1} \n `

            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 1, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function add2_4digits (){
            let qu1 = (rnd(88)+10); let qu2 = (rnd(899)+100)*10; let ans = qu1 + qu2;
            let qu= `${qu1} + ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 2, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function add3items2_1digits (){
            let qu1 = (rnd(88)+10); let qu2 = (rnd(8)+1); let qu3 = (rnd(8)+1); let ans = qu1 + qu2 + qu3;
            let qu= `${qu1} + ${qu2} + ${qu3} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 3, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function add3items2_3digits (){
            let qu1 = (rnd(88)+10); let qu2 = (rnd(889)+100)*10; let qu3 = (rnd(50)+1); let ans = qu1 + qu2 + qu3;
            let qu= `${qu1} + ${qu2} + ${qu3} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 3, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function subtract7digitplaceholder (){
let a = (rnd(98)+1)*100000; let b = (rnd(8)+1)* 10; let c = (rnd(8)+1)*1000; let d = a + b + c; let e= a+b
            let qu=`${d} - ${c} = `
            let an=`${e}`
            let wO=''
        const question = {id: 4, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        
        function subtract1_1(){
            let qu2 = (rnd(4)+1); let qu1 = (rnd(4)+1); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 


        function subtract1_2(){
            let qu2 = (rnd(8)+1); let qu1 = (rnd(80)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract1_2a(){
            let qu2 = (rnd(8)+1); let qu1 = (rnd(80)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract2_2(){
            let qu2 = (rnd(20)+10); let qu1 = (rnd(70)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract2_2a(){
            let qu2 = (rnd(20)+10); let qu1 = (rnd(70)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract2_2b(){
            let qu2 = (rnd(20)+10); let qu1 = (rnd(70)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract2_2c(){
            let qu2 = (rnd(20)+10); let qu1 = (rnd(70)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
       
        function subtract2_2d(){
            let qu2 = (rnd(20)+10); let qu1 = (rnd(70)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 


        function subtract3_3(){  /// KS 1 version
            let qu2 =  (rnd(80)+10); let qu1 = 100 - qu2; let ans = 100;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function subtract2_3digits(){
            let qu2 = (rnd(40)+11); let qu1 = (rnd(989)+10); let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 5, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function subtract3_4digits(){
            let qu2 = (rnd(979)+11); let qu1 = (rnd(889)+10)*10; let ans = qu1 + qu2;
            let qu= `${ans} - ${qu2} = `
            let an=`${qu1}`
            let wO=''
        const question = {id: 6, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function subtract3items1_3digits(){
            let qu2 = (rnd(97)+2); let qu1 = (rnd(989)+1)*10; let qu3 = (rnd(50)+1); let ans = qu1 - qu2 - qu3;
            let qu= `${qu1} - ${ans} - ${qu3} = `
            let an=`${qu2}`
            let wO=''
        const question = {id: 7, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function reverseOp2_2(){
            let qu2 = (rnd(88)+10); let qu1 = (rnd(89)+10); let ans = qu1 + qu2
            let qu= ` ??? + ${qu1} = ${ans} `
            let an=`${qu2}`
            let wO=''
        const question = {id: 7, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
      
        function halforQuarterof2 (){
            let ans = rnd(3)+2; let qu2 = (rnd(2)+1)*2; let qu1 = ans * qu2;
            let qu= `1/${qu2} of ${qu1} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 8, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function divide1_2 (){
            let ans = rnd(8)+2; let qu2 = rnd(3)+2; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 8, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function divide2by10 (){
            let ans = rnd(8)+2; let qu2 = 10; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 8, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function divide2by5 (){
            let ans = rnd(8)+2; let qu2 = 5; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 8, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function divide1_3 (){
            let ans = rnd(89)+10; let qu2 = rnd(7)+2; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 8, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function divide1_4(){
            let ans = (rnd(94)+5)*10; let qu2 = rnd(7)+2; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 9, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function divide2_2(){
            let ans = rnd(8)+1; let qu2 = rnd(89)+10; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 10, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function divide2_3 (){
            let ans = rnd(30)+5; let qu2 = rnd(30)+5; let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 11, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function divide2_4(){
            let ans = rnd(40)+50; let qu2 = (rnd(49)+1); let qu1 = ans * qu2;
            let qu= `${qu1} ÷ ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 12, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
      
              
        function multiply1_1(){
            let qu1 = rnd(8)+2; let qu2 = rnd(8)+2; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 13, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function multiply1by10(){
            let qu1 = rnd(8)+1; let qu2 = 10; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 13, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function multiply1_2digits(){
            let qu1 = rnd(8)+1; let qu2 = rnd(89)+10; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 13, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function multiply1_3digits(){
            let qu1 = rnd(8)+1; let qu2 = rnd(899)+100; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 14, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function multiply2_3digits(){
            let qu1 = rnd(50)+10; let qu2 = rnd(899)+100; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 15, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function multiply2_4(){
            let qu1 = rnd(50)+10; let qu2 = rnd(7999)+1000; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 16, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function multiply3vs1000(){
            let qu1 = rnd(899)+100; let qu2 = 1000; let ans = qu1*qu2
            let qu=`${qu1} X ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 17, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function multiply3items1_2 (){
            let qu1 = rnd(8)+1; let qu2 = rnd(8)+1; let qu3 = rnd(5)+1; let ans = qu1*qu2*qu3
            let qu=`${qu1} X ${qu2} X ${qu3} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 18, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function cube(){
            let qu1 = rnd(8)+2;  let ans = qu1*qu1*qu1
            let qu=`${qu1}^3  = `
            let an = `${ans}`
            let wO=''
        const question = {id: 19, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function percentMultiply2_3(){
            let qu1 = (rnd(13)+1)*5; let qu2 = (rnd(50)+15) *10;  let ans = (qu2 *qu1)/ 100
           
            let qu=`${qu1} % of ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 20, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function percentMultiply2_4(){
            let qu1 = (rnd(13)+1)*5; let qu2 = (rnd(80)+15)*100;  let ans = (qu2*qu1)
            //if (qu2%0.01 > 0 ){ qu2 = qu2.toFixed(2)}
            let qu=`${qu1} % of ${qu2} = `
            let an = `${ans/100}`
            let wO=''
        const question = {id: 21, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function percentMultiply1_4(){
            let qu1 = (rnd(8)+1); let qu2 = (rnd(80)+15)*100;  let ans = (qu2*qu1)/ 100
            let qu=`${qu1} % of ${qu2} = `
            let an = `${ans}`
            let wO=''
        const question = {id: 22, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalAdd2_3(){
            let qu1 = (rnd(99)+15); let qu2 = (rnd(989)+1); let ans = qu1 + qu2;  // 0.35 + 0.537 = 0.887
            let qu= `${qu1/1000} + ${qu2/1000} = `
            let an=`${ans/1000}`                
            let wO=''
        const question = {id: 23, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalAdd3_4(){
            let qu1 = (rnd(989)+1); let qu2 = (rnd(9899)+100); let ans = qu1 + qu2;
            let qu= `${qu1/1000} + ${qu2/1000} = `
            let an=`${ans/1000}`
            let wO=''
        const question = {id: 24, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalSubtract1_3(){
            let qu1 = (rnd(989)+10); let qu2 = (rnd(8)+1); let ans = qu1 + qu2;
            let qu= `${ans/1000} - ${qu2/1000} = `
            let an=`${qu1/1000}`
            let wO=''
        const question = {id: 25, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalSubtract1_4(){
            let qu1 = (rnd(9899)+100); let qu2 = (rnd(8)+1); let ans = qu1 + qu2*100;  /// 0.9909 -  0.9 = 0.0909
            let qu= `${ans/10000} - ${qu2/100} = `
            let an=`${qu1/10000}`
            let wO=''
        const question = {id: 26, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        


        function decimalSubtract2_2(){
            let qu1 = (rnd(59)+40); let qu2 = (rnd(39)+1); let ans = qu1 - qu2;
            let qu= `${qu1/100} - ${qu2/100} = `
            let an=`${ans/100}`
            let wO=''
        const question = {id: 27, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalSubtract2_4(){
            let qu2 = (rnd(99)+13); let qu1 = (rnd(899)+100); let ans = qu1 - qu2;
            let qu= `${qu1/1000} - ${qu2/1000} = `
            let an=`${ans/1000}`
            let wO=''
        const question = {id: 28, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function decimalDivide1_2(){
            let ans = (rnd(46)+5); let qu2 = rnd(9)+1; let qu1 = ans * qu2*100;
            let qu= `${qu1/1000} ÷ ${qu2/10} = `
            let an=`${ans}`
            let wO=''
        const question = {id: 29, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalDivide2_3(){
            let ans = (rnd(499)+5); let qu2 = (rnd(9)+1); let qu1 = ans * qu2*10;///////////////////////////
            let qu= `${qu1/1000} ÷ ${qu2/10} = `
            let an=`${ans/10}`
            let wO=''
        const question = {id: 30, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalMultiply2_3(){
            let qu1 = (rnd(980)+15); let qu2 = (rnd(9)+1)*10; let ans = qu1 * qu2;
            let qu= `${qu1/1000} X ${qu2/1000} = `
            let an=`${ans/1000000}`
            let wO=''
        const question = {id: 31, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalMultiply2_2(){
            let qu1 = (rnd(93)+5); let qu2 = (rnd(9)+1)*10; let ans = qu1 * qu2;
            let qu= `${qu1/100} X ${qu2/100} = `
            let an=`${ans/10000}`
            let wO=''
        const question = {id: 32, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function decimalMultiiply3_4(){
            let qu1 = (rnd(93)+5); let qu2 = (rnd(9)+1)*10; let ans = qu1 * qu2;
            let qu= `${qu1/1000} X ${qu2/1000} = `
            let an = `${ans/1000000}`
            let wO=''
        const question = {id: 33, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function bodmasSquareAddSubtract(){ //6 (^2) + 10 =
            let a = rnd(9)+1; let b = (rnd(29)+1); 
            let qu=`${a}^2 + ${b} = `
            let an=`${(a*a) + (b)}`
            let wO=`${a*a} + ${b} `

        const question = {id: 34, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function bodmasAddSubtractDivide(){  //9 (^2) - 36  / 9  =
            let d = (rnd(3)+3); let a = (rnd(4)+d); let c = (rnd(3)+1); let b = (a*a - (d*c))
            let qu=`(${a}^2 - ${b}) ÷ ${c} = `
            let an=`${((a)*(a) - (b))/(c)}`
            let wO=`(${(a)*(a)} - ${b}) ÷ ${c} = (${(a)*(a) - (b)})/${c}`
        const question = {id: 35, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 


        
        function fractionAddSimpleSimple(){
let a = easyFractionPairs[rnd(easyFractionPairs.length)]; let b = easyFractionPairs[rnd(easyFractionPairs.length)]
console.log(a,b)
let c  // c is the denominator in the answer
let d // d is the numerator in the answer
  
c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1])) // or if not this makes an unsimplfied fraction
let f = simplify(c,d)


        let qu= {a: a[0] , b: a[1] , c:'', d: b[0] , e: b[1], f:'' , g:'+' }
             let an
             if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
             else if (f[1] === 0 || undefined){an= `${f[2]}`}
                  else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                  else { an= `${f[2]} ${f[1]}/${f[0]}`}//{a: f[2], b: f[1], c: f[0]}
                  let wO=` ( ${b[1]} X ${a[0]}  + ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 36, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionAddComplexSimple(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; let b = easyFractionPairs[rnd(easyFractionPairs.length)]; let int = rnd(8)+1
            console.log(a,b); let c ; let d // d is the numerator in the answer
                if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
                else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f:'' , g:'+' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${int+f[2]}`}
                else if (f[2] === 0 || undefined){ an = `${f[1]}/${f[0]}`}
                else { an= `${int+f[2]} ${f[1]}/${f[0]}`}
                //{a: f[2]+int, b: f[1], c: f[0]}
                let wO=`${int} + ( ${b[1]} X ${a[0]}  + ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 37, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionAddComplexComplex(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; let b = easyFractionPairs[rnd(easyFractionPairs.length)]; let int = rnd(8)+1; let int2 = rnd(8)+1
            console.log(a,b); let c ; let d // d is the numerator in the answer
                if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
                else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f: int2 , g:'+' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${int+ int2 +f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${int+ int2 +f[2]} ${f[1]}/${f[0]}`}
            
                let wO=`${int} + ${int2} + ( ${b[1]} X ${a[0]}  + ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 38, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionAddSimpleInteger(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; let b = easyFractionPairs[rnd(easyFractionPairs.length)]; let int = rnd(8)+1
            console.log(a,b); let c ; let d // d is the numerator in the answer
                if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
                else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: '', d: '' , e: '', f:int, g:'+' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (a[0] === 0 || undefined){an= `${int}`}
                else if (int === 0 || undefined){ an = `${a[0]}/${b[1]}`}
                else { an= `${int} ${a[0]}/${b[1]}`} 
         
            let wO=''
        const question = {id: 39, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionSubtractSimpleSimple(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = easyFractionPairs[y]; let b = easyFractionPairs[x]; let int = rnd(8)+1
            console.log(a,b); let c ; let d // d is the numerator in the answer
                if (a[1]=== b[1]){ c = a[1]; d = a[0]-b[0]} // same denominator just add
                else {c = a[1]*b[1]; d = ((b[1]*a[0]) - (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: '', d: b[0] , e: b[1], f:'', g:'-'  }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
               //let an= `${f[1]}/${f[0]}`//{a: '', b: f[1], c: f[0]}
               let wO=`${b[1]} X ${a[0]}  - ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} `
        const question = {id: 40, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionSubtractComplexSimple(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = easyFractionPairs[x]; let b = easyFractionPairs[y]; let int = rnd(8)+1
         // d is the numerator in the answer
             let c = a[1]*b[1]; let d = ((b[1]*(a[0]+a[1]*int)) - (b[0]*a[1])) // makes an unsimplfied top heavy fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f:'' , g:'-' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
               
            let wO=`${int} + ( ${b[1]} X ${a[0]}  - ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 41, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionSubtractComplexComplex(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = easyFractionPairs[y]; let b = easyFractionPairs[x]; let int = rnd(5)+4; 
            let int2 = int - rnd(int-2)
                
                let c = a[1]*b[1]; let d = ((b[1]*(a[0]+a[1]*int)) - (a[1]*(b[0] + b[1]*int2))) // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f:int2 , g:'-' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] == 0 || undefined){an= `${f[2]}`}
                else if (f[2] == 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
                let wO=`${int} - ${int2} + ( ${b[1]} X ${a[0]}  - ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 42, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionSubtractComplexInteger(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = [0,1]; let b = easyFractionPairs[y]; let int = rnd(5)+4; let int2 = int - rnd(int-2)
      
            let c = a[1]*b[1]; let d = ((b[1]*(a[0]+a[1]*int)) - (a[1]*((int2*b[1])+b[0])))
                let f = simplify(c,d)
                let qu= {a: '' , b: '' , c: int, d: b[0] , e: b[1], f:int2 ,g:'-' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
               
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
                //let an= `${int-int2-f[2]} ${f[1]}/${f[0]}`//{a: int-int2 -f[2], b: f[1], c: f[0]}
                let wO=`(${int} X ${b[1]} - ${int2} X ${b[1]}  -  ${b[0]}) / ${b[1]} )`
        const question = {id: 43, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionMultiplyComplexInteger(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; 
          //  let b = easyFractionPairs[rnd(easyFractionPairs.length)]; 
            let int = rnd(8)+1; let int2 = rnd(4)+1
            
            let x = (int * a[1]) + a[0]  // create the numerator for a top heavy fraction from the complex / mixed fraction
            let c = x* int2  // the multiplcation
            let d =  a[1]
           //   if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
             //   else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(d,c)
                let qu= {a: a[0] , b: a[1] , c: int, d: '' , e: '', f: int2 ,g:'X' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}//{a: f[2], b: f[1], c: f[0]}
            let wO=''
        const question = {id: 44, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionMultiplySimpleSimple(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; 
              let b = easyFractionPairs[rnd(easyFractionPairs.length)]; 
              let c = a[1]*b[1]
              let d = a[0]*b[0]
             // let int = rnd(8)+1; let int2 = rnd(4)+1
              
            //  let x = (int * a[1]) + a[0]  // create the numerator for a top heavy fraction from the complex / mixed fraction
           //   let c = x* int2  // the multiplcation
             // let d =  a[1]
             //   if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
               //   else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                  let f = simplify(c,d)
                  let qu= {a: a[0] , b: a[1] , c: '', d: b[0] , e:b[1] , f: '', g:'X'  }
                  let an
                  if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
               else if (f[1] === 0 || undefined){an= `${f[2]}`}
                  else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                  else { an= `${f[2]} ${f[1]}/${f[0]}`}//{a: f[2], b: f[1], c: f[0]}
            let wO=''
        const question = {id: 45, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionDivideSimpleInteger(){
            let a = easyFractionPairs[rnd(easyFractionPairs.length)]; 
           // let b = easyFractionPairs[rnd(easyFractionPairs.length)]; 
           let int = rnd(8)+1; 
           let c = a[1]*int; 
            let d = a[0]
            //let int2 = rnd(4)+1
            
          //  let x = (int * a[1]) + a[0]  // create the numerator for a top heavy fraction from the complex / mixed fraction
         //   let c = x* int2  // the multiplcation
           // let d =  a[1]
           //   if (a[1]=== b[1]){ c = a[1]; d = a[0]+b[0]} // same denominator just add
             //   else {c = a[1]*b[1]; d = ((b[1]*a[0]) + (b[0]*a[1]))} // or if not this makes an unsimplfied fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: '', d: '' , e:'' , f: int, g: '÷'  }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
               else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
            let wO=''
        const question = {id: 46, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 
        
        function fractionSubtractComplexSimple1(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = easyFractionPairs[x]; let b = easyFractionPairs[y]; let int = rnd(8)+1
         // d is the numerator in the answer
             let c = a[1]*b[1]; let d = ((b[1]*(a[0]+a[1]*int)) - (b[0]*a[1])) // makes an unsimplfied top heavy fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f:'' , g:'-' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
               
            let wO=`${int} + ( ${b[1]} X ${a[0]}  - ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 47, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 

        function fractionSubtractComplexSimple2(){
            let x = rnd(easyFractionPairs.length-1); let y = x+ rnd(easyFractionPairs.length-x)
            let a = easyFractionPairs[x]; let b = easyFractionPairs[y]; let int = rnd(8)+1
         // d is the numerator in the answer
             let c = a[1]*b[1]; let d = ((b[1]*(a[0]+a[1]*int)) - (b[0]*a[1])) // makes an unsimplfied top heavy fraction
                let f = simplify(c,d)
                let qu= {a: a[0] , b: a[1] , c: int, d: b[0] , e: b[1], f:'' , g:'-' }
                let an
                if ((f[2] === 0 || undefined || '' ) && (f[1] === 0 || undefined) ){ an = 0}
                else if (f[1] === 0 || undefined){an= `${f[2]}`}
                else if (f[2] === 0 || undefined || ''){ an = `${f[1]}/${f[0]}`}
                else { an= `${f[2]} ${f[1]}/${f[0]}`}
               
            let wO=`${int} + ( ${b[1]} X ${a[0]}  - ${b[0]} X ${a[1]} / ${a[1]} X ${b[1]} )`
        const question = {id: 48, question: qu, answer: an, workingOut: wO, marks: 1}
        return question
        } 


//let setQuestions = []


export const SettingQuestions = ()=> { // taking the array above and creating the questions and answers
    const dispatch = useDispatch();
  const choice  = useSelector((state) => state.questionSet.sats)

  const QEF = settingQuestions(choice)
  console.log(QEF)
  const setQuestions2Easy = QEF[0]
  const setFractionsEasy = QEF[1]

            if (choice === 'Arithmetic Key Stage 2'){
          
              let  setQuestions=[  subtract7digitplaceholder(), subtract2_3digits(), subtract3_4digits(), subtract3items1_3digits(),divide2_3 (), divide2_4(),
                     divide1_3(), divide1_4(), divide2_2(), multiply1_2digits(), multiply1_3digits(), multiply2_3digits(), multiply2_4(), 
                     percentMultiply2_3(), percentMultiply2_4(), percentMultiply1_4(), 
                             //  add2_3digits(), add2_4digits(), add3items2_3digits(),divide1_4(), divide2_2(),  multiply3vs1000(), multiply3items1_2 (), 
                //    cube(), decimalAdd2_3(), decimalAdd3_4(), decimalSubtract1_3(), decimalSubtract1_4(), decimalSubtract2_2(), decimalSubtract2_4(),
                 //    decimalDivide1_2(), decimalDivide2_3(), decimalMultiply2_3(), decimalMultiply2_2(), decimalMultiiply3_4(), bodmasSquareAddSubtract(), 
                 //    bodmasAddSubtractDivide()
                    ]
             
            const add = [add2_3digits(), add2_4digits(), add3items2_3digits()] //2
            const divide = [ divide1_4(), divide2_2()] // 1
            const multiply = [ multiply3vs1000(), multiply3items1_2 (), cube()]  // 2
            const decimalAdd = [ decimalAdd2_3(), decimalAdd3_4()] // 1
            const decimalSub = [ decimalSubtract1_3(), decimalSubtract1_4(), decimalSubtract2_2(), decimalSubtract2_4()] //2
            const decimaldiv = [ decimalDivide1_2(), decimalDivide2_3()] //1
            const decimalMul = [ decimalMultiply2_3(), decimalMultiply2_2(), decimalMultiiply3_4()]
               const bodmas = [  bodmasSquareAddSubtract(), bodmasAddSubtractDivide()] //1
    
            let a=add.splice(rnd(3),1); setQuestions.push(a[0]);  setQuestions.push( add[rnd(2)])
            let m=multiply.splice(rnd(3),1); setQuestions.push(m[0]);   setQuestions.push( multiply[rnd(2)])
            let d=decimalSub.splice(rnd(4),1); setQuestions.push(d[0]); setQuestions.push( decimalSub[rnd(3)])
           
            setQuestions.push( divide[rnd(2)]); setQuestions.push( decimalAdd[rnd(2)]); setQuestions.push( decimaldiv[rnd(2)]); 
            setQuestions.push( decimalMul[rnd(3)]);  setQuestions.push( bodmas[rnd(2)]) // the final array of non fraction questions
            
            
            let setFractions =[ 
                fractionAddSimpleSimple(),          fractionAddComplexComplex(),            fractionSubtractComplexSimple(),        
                fractionSubtractComplexSimple1(),   fractionSubtractComplexSimple2(),       fractionMultiplyComplexInteger(),       
                fractionMultiplySimpleSimple(),     fractionDivideSimpleInteger(),          
             ]
            
            const fractionsu = [ fractionSubtractSimpleSimple(),  fractionSubtractComplexComplex(), fractionSubtractComplexInteger()]//2
            let f=fractionsu.splice(rnd(3),1); setFractions.push(f[0]);   setFractions.push( fractionsu[rnd(2)])
              
            for ( let xy = 0; xy < setFractions.length ; xy++){setFractions[xy].id = xy+1}
            for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}

            
            const handleGo1 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions(setFractions))}
            const handleGoEasy = () => {dispatch(holdTheQuestions(setQuestions2Easy)); dispatch(holdTheFractions(setFractionsEasy))}  /// fractions
            return (<p>{choice} <button className="go" onClick={handleGo1} > Exam questions</button>
            {choice} <button className="go" onClick={handleGoEasy} >Slightly easier practice questions</button></p>)
    }

    if (choice === 'Arithmetic Key Stage 1'){
          
        let  setQuestions=[  add1_1(), add1_1a(), add1_2(), add1_2a(), add2_2(), add2_2a(), add2_2b(), add3items2_1digits(), 
            subtract1_1(), subtract1_2(), subtract1_2a(), subtract2_2(),subtract2_2a(),subtract2_2b(),subtract2_2c(),subtract2_2d(),
            subtract3_3(), reverseOp2_2(), multiply1_1(), multiply1by10(), halforQuarterof2(), divide1_2(), divide2by10(), 
            divide2by5()
           ]
            
           for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}
      const handleGo3 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions([]))}
      return (<p>{choice} <button className="go" onClick={handleGo3} >Go</button></p>)
}

if (choice === 'Maths reasoning Key Stage 1'){
          
    let  setQuestions=[  placeValues(), addSubtractPictures1_2(), timesDivide(), fractionShapeLengthQuantity(), measureLengthMassTempVolTime(), compareLengthMassVol(), addSubtractMoney(), 
       coinAdd(), coinChange(), tellTime(), minutesHourDay(), IdentifyShape(), TwoDonThreeD(), KS1missingNumber(), turnAngle(), interpretChart(), createChart(), compareData()  ]
        
       for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}
  const handleGo3 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions([]))}
  return (<p>{choice} <button className="go" onClick={handleGo3} >Go</button></p>)
}

if (choice === 'English Key Stage 1'){
          
    let  setQuestions=[  determiner(), determiner(), determiner(), 
       ]
        
       for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}
  const handleGo3 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions([]))}
  return (<p>{choice} <button className="go" onClick={handleGo3} >Go</button></p>)
}

if (choice === 'English Key Stage 2'){
          
    let  setQuestions=[  determiner(), conjunction(), preposition(), pronoun(), semicolon(), comma(), hyphen(), colon(), formal(), noun(), statement(), synonym(), 
        antonym(), clauses(), questions() ]
      
        
       for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}
  const handleGo3 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions([]))}
  return (<p>{choice} <button className="go" onClick={handleGo3} >Go</button></p>)
}

if (choice === 'Maths reasoning Key Stage 2'){
          
    let  setQuestions=[  orderUpTo8(), roundWhole(), roundDecimal(),  bodmas1_1_1_1(), multistepWord(),estimateCheck(), commonFactor(), 
        CommonMultip(), compareFraction(), orderFraction(), decimalEquiv(), percentEquiv(), relativeSize(), calculatePercent(), 
        shapeProblem(), shareProblem(), simpleFormula(), linearSequence(), missingNumber(), equationPair() , //possibleCombinations
        convertUnit3length(), convertUnit3mass(), convertUnit3time(), convertUnit3vol(), milesKilometres(), areaPerimeter(),
        areaOfTriangle(), areaOfParr() , volCuboid(), compareVolCuboid(), unknownAngle(), 
        //compareAreaPolygon, compareLengthPolygon, compareAnglePolygon, nameCircle, verticalOpp, translateShape, pie, line, 
        mean()
       ]
        
       for ( let xy = 0; xy < setQuestions.length; xy++){setQuestions[xy].id = xy+1}
  const handleGo4 = () => {dispatch(holdTheQuestions(setQuestions)); dispatch(holdTheFractions([]))}
  return (<p>{choice} <button className="go" onClick={handleGo4} >Go</button></p>)
}

      
else  { 
let setQuestions2 = []; 

const arithmeticArray = [
    'Add one and one digit',    'Add two and one digit',    'Add two and two digits',     'Add two and three digits',    'Add two and four digits', 
    'Add 3 numbers',            'Minus one from one digit',  'Minus one from two digits', 'Minus 2 from 3 digits',      'Minus 3 from 4 digits',   
    'Minus large numbers',      'minus 3 items',               'Multiply 1 by 3 digits', 
    'Multiply 2 by 3 digits',   'Multiply 2 by 4 digits',    'Multiply by 1000',          'Multiply 3 items',            'Cube', 
    'Divide 3 digits by 1',     'Divide 4 digits by 1',      'Divide 2 digits by 2',      'Divide 3 digits by 2',        'Divide 4 digits by 2', 
    'Percent Multiply',         'Decimal Add',                 'Decimal Subtract easy',   'Decimal Subtract hard',       'Decimal Divide easy',       
    'Decimal Divide hard',        'Decimal Multiply easy',    'Decimal Multiply hard',     'BODMAS',                    'Multiply 1 by 2 digits', ] 
let z = arithmeticArray.findIndex((element) => element === choice); //console.log( choice, z)

const arithmeticQuestions = { 
    a: add1_1,             b: add1_2,                          c: add2_2,               d: add2_3digits,        e: add2_4digits, 
    f: add3items2_3digits, g: subtract1_1,                     h: subtract1_2,          i: subtract2_3digits,   j: subtract3_4digits,   
    k: subtract7digitplaceholder, l: subtract3items1_3digits,   m: multiply1_3digits,   
    n: multiply2_3digits,  o: multiply2_4,                     p: multiply3vs1000,      q: multiply3items1_2,   r: cube,
    s: divide1_3,          t: divide1_4,                       u: divide2_2,            v: divide2_3,           w: divide2_4,
    x: percentMultiply2_3, y: percentMultiply2_4,              z: percentMultiply1_4,   
    aa: decimalAdd2_3,      ab: decimalAdd3_4,        
    
    ac: decimalSubtract1_3,  ad: decimalSubtract1_4,           ae: decimalSubtract2_2,  af: decimalSubtract2_4, 
    ag: decimalDivide1_2,    ah: decimalDivide2_3,    
    ai: decimalMultiply2_2, aj: decimalMultiply2_3, 
    ak: decimalMultiiply3_4, al: bodmasSquareAddSubtract,      am: bodmasAddSubtractDivide ,  an: multiply1_2digits, } 
    
const keyStageOneArray = [ 'Add one to one digit', 'Add two to one digit' , 'Add two to two digits' ,'Add three items' , 
'Subtract one digit from one', 'Subtract one digit from two', 'Subtract two digits from two', 'Subtract two digits from three',  
'Reverse operation', 'Multiply one digit by one', 'Multiply by 10', 'Multiply fractions by whole numbers', 'Divide two digits' ]
let w = keyStageOneArray.findIndex((element) => element === choice); //console.log( choice, w)
const keyStageOneQuestions = {
    a: add1_1, b: add1_2, c: add2_2, d: add3items2_1digits, e: subtract1_1, f: subtract1_2, g: subtract2_2, 
    h: subtract3_3,  i:  reverseOp2_2, j: multiply1_1,  k: multiply1by10,  l: halforQuarterof2, m: divide1_2,
    n: divide2by10, o: divide2by5
} //add3items2_1digits

const KS1ReasoningArray = [ 'Place values', 'Add and Subtract', 'Times and Divide', 'Fractions', 'Measure', 'Compare', 'Money', 'Coins',
'Change', 'Time', 'Minutes Hours Days', 'Identify Shapes', '2D and 3D', 'Missing Number', 'Angles', 'Interpret Chart', 'Create Chart', 'Compare Data' ]
let a = KS1ReasoningArray.findIndex((element) => element === choice); //console.log( choice, a)
const kS1ReasoningQuestions = {
   
    a: placeValues, b: addSubtractPictures1_2, c: timesDivide, d: fractionShapeLengthQuantity, e: measureLengthMassTempVolTime, f: compareLengthMassVol, g: addSubtractMoney, h: coinAdd,
    i: coinChange, j: tellTime, k: minutesHourDay, l: IdentifyShape, m: TwoDonThreeD, n: KS1missingNumber, o: turnAngle, p: interpretChart, q: createChart, r: compareData 
   
} 

const KS1EnglishArray = [ 'Add one to one digit'  ]
let b = KS1EnglishArray.findIndex((element) => element === choice); //console.log( choice, b)
const kS1EnglishQuestions = {
    a: add1_1, 
} 

const KS2EnglishArray = [ 'Determiner' , 'conjunction', 'preposition', 'pronoun', 'semicolon', 'comma', 'hyphen', 'colon', 'formal', 'noun', 'statement', 'synonym', 'antonym', 
'clauses', 'questions'  ]
let c = KS2EnglishArray.findIndex((element) => element === choice); console.log( choice, c)
const kS2EnglishQuestions = {
    a: determiner, b: conjunction, c: preposition, d: pronoun, e: semicolon, f: comma, g: hyphen, h: colon, i: formal, j: noun, k: statement, l: synonym, m: antonym, 
    n: clauses, o: questions
} 

const reasoningArray = [ 'Order numbers', 'Round Whole Numbers', 'Round Decimal Numbers', 'Using Bodmas', 'Word problems', 'Estimating', 
'Common Factor', 'Common Multiples', 'Compare Fractions', 'Order Fractions', 'Decimal Equivalents', 'Percent Equivalents', 'Relative Size', 
'Calculate Percent', 'Shape Problems', 'Sharing Problema', 'Formula', 'Linear Sequences', 'Missing Numbers', 'Pairs in equations' ,
'Converting units: length', 'Converting units: mass', 'Converting units: time', 'Converting units: volume', 
'Converting units: miles and kilometres', 'Perimeter and Area', 'Area Of a Triangle', 'Area of a Parrallelogram' , 'Volume of a cube/cuboid',
'Compare Volumes of Cuboids', 'Find an unknown angle', 
//'Compare Polygons: area', 'Compare Polygons: length of side','Compare Polygons: Angles', 'Naming the parts of a Circle', 'Possible Combinations',
//'Vertically opposite angles', 'Translate a Shape', 'Pie charts', 'Line charts', 
'Calculating the mean']

let v = reasoningArray.findIndex((element) => element === choice); //console.log( choice, v)

const reasoningQuestions = {
   a: orderUpTo8, b: roundWhole, c: roundDecimal, d: bodmas1_1_1_1, e: multistepWord, f: estimateCheck, g:commonFactor, 
  h: CommonMultip, i: compareFraction, j: orderFraction, k: decimalEquiv, l: percentEquiv, m: relativeSize, n: calculatePercent, 
   o: shapeProblem, p: shareProblem, q: simpleFormula, r: linearSequence, s: missingNumber, t: equationPair , 
  v:  convertUnit3length, w: convertUnit3mass, x: convertUnit3time, y: convertUnit3vol, z: milesKilometres, aa: areaPerimeter, 
  ab: areaOfTriangle, ac: areaOfParr , ad: volCuboid, ae: compareVolCuboid, af: unknownAngle, 
  //ag:compareAreaPolygon, ah: compareLengthPolygon,   ai: compareAnglePolygon, aj: nameCircle, u: possibleCombinations,
  //ak: verticalOpp, al: translateShape, am: pie, an: line, 
  ao: mean
} 
  
let letters = ['a', 'b', 'c' , 'd', 'e' , 'f', 'g' , 'h' , 'i', 'j', 'k' , 'l' , 'm' , 'n', 'o' , 'p' , 'q', 'r' , 's'
  ,'t' ,  'v' , 'w' ,'x', 'y', 'z', 'aa', 'ab', 'ac', 'ad', 'ae', 'af', 
  //'ag', 'ah', 'ai', 'aj', 'u',
  //'ak', 'al', 'am' ,   'an', 
  'ao', 'ap', 'aq', 'ar' ,'as', 'at' , 'au' , 'av', 'aw' , 'ax' , 'ay' , 'az' , 'aaa' , 'aab' , 'aac' , 'aad'] 

    
if (letters[z] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.a() )}}
if (letters[z] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.b() )}}
if (letters[z] === 'c'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.c() )}}
if (letters[z] === 'd'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.d() )}}
if (letters[z] === 'e'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.e() )}}
if (letters[z] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.f() )}}
if (letters[z] === 'g'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.g() )}}
if (letters[z] === 'h'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.h() )}}
if (letters[z] === 'i'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.i() )}}
if (letters[z] === 'j'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.j() )}}
if (letters[z] === 'k'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.k() )}}
if (letters[z] === 'l'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.l() )}}
if (letters[z] === 'm'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.m() )}}
if (letters[z] === 'n'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.n() )}}
if (letters[z] === 'o'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.o() )}}
if (letters[z] === 'p'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.p() )}}
if (letters[z] === 'q'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.q() )}}
if (letters[z] === 'r'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.r() )}}
if (letters[z] === 's'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.s() )}}
if (letters[z] === 't'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.t() )}}
if (letters[z] === 'u'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.u() )}}
if (letters[z] === 'v'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.v() )}}
if (letters[z] === 'w'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.w() )}}
if (letters[z] === 'x'){for (let xx = 0 ; xx < 7 ; xx++){ setQuestions2.push(arithmeticQuestions.x()); setQuestions2.push(arithmeticQuestions.x()); setQuestions2.push(arithmeticQuestions.x()); }}
//if (letters[z] === 'y'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.y() )}}
//if (letters[z] === 'z'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.z() )}}
if (letters[z] === 'y'){for (let xx = 0 ; xx < 10 ; xx++){ setQuestions2.push(arithmeticQuestions.aa() );setQuestions2.push(arithmeticQuestions.ab() )}}
//if (letters[z] === 'ab'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ab() )}}
if (letters[z] === 'z'){for (let xx = 0 ; xx < 10 ; xx++){ setQuestions2.push(arithmeticQuestions.ac() );setQuestions2.push(arithmeticQuestions.ad() )}}
//if (letters[z] === 'ad'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ad() )}}
if (letters[z] === 'aa'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ae() );setQuestions2.push(arithmeticQuestions.af() )}}
//if (letters[z] === 'af'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.af() )}}
if (letters[z] === 'ab'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ag() )}}
if (letters[z] === 'ac'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ah() )}}
if (letters[z] === 'ad'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ai() )}}
if (letters[z] === 'ae'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.aj() );setQuestions2.push(arithmeticQuestions.ak() )}}
//if (letters[z] === 'ak'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.ak() )}}
if (letters[z] === 'af'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.al() ); setQuestions2.push(arithmeticQuestions.am() )}}
//if (letters[z] === 'am'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.am() )}}
if (letters[z] === 'an'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(arithmeticQuestions.an() )}}


let setFractions = [];    
const fractionArray = [   'Add fractions easy', 'Add fractions hard',  'Minus fractions easy', 'Minus fractions hard' ,'Multiply fractions', 
    'Divide fractions']
    let y = fractionArray.findIndex((element) => element === choice); console.log( choice, y)
const fractionQuestions = { a:  fractionAddSimpleSimple,  b: fractionAddComplexComplex, c: fractionSubtractSimpleSimple,  
    d: fractionSubtractComplexSimple, e: fractionSubtractComplexInteger,  f: fractionSubtractComplexComplex,         
      g: fractionMultiplyComplexInteger, h:  fractionMultiplySimpleSimple, i: fractionDivideSimpleInteger }

if (letters[y] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setFractions.push(fractionQuestions.a() )}}
if (letters[y] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setFractions.push(fractionQuestions.b() )}}
if (letters[y] === 'c'){for (let xx = 0 ; xx < 10 ; xx++){ setFractions.push(fractionQuestions.c()); setFractions.push(fractionQuestions.e() )}}
if (letters[y] === 'd'){for (let xx = 0 ; xx < 10 ; xx++){ setFractions.push(fractionQuestions.d() );setFractions.push(fractionQuestions.f() )}}
if (letters[y] === 'e'){for (let xx = 0 ; xx < 10 ; xx++){ setFractions.push(fractionQuestions.g() );setFractions.push(fractionQuestions.h() )}}
if (letters[y] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setFractions.push(fractionQuestions.i() )}}


// KS1
if (letters[w] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.a() )}}
if (letters[w] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.b() )}}
if (letters[w] === 'c'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.c() )}}
if (letters[w] === 'd'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.d() )}}
if (letters[w] === 'e'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.e() )}}
if (letters[w] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.f() )}}
if (letters[w] === 'g'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.g() )}}
if (letters[w] === 'h'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.h() )}}
if (letters[w] === 'i'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.i() )}}
if (letters[w] === 'j'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.j() )}}
if (letters[w] === 'k'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.k() )}}
if (letters[w] === 'l'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.l() )}}
if (letters[w] === 'm'){for (let xx = 0 ; xx < 5 ; xx++){ setQuestions2.push(keyStageOneQuestions.m() );
     setQuestions2.push(keyStageOneQuestions.m() ); setQuestions2.push(keyStageOneQuestions.n() ); 
     setQuestions2.push(keyStageOneQuestions.o() )}}


// Reasoning 2
if (letters[v] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.a() )}}
if (letters[v] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.b() )}}
if (letters[v] === 'c'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.c() )}}
if (letters[v] === 'd'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.d() )}}
if (letters[v] === 'e'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.e() )}}
if (letters[v] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.f() )}}
if (letters[v] === 'g'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.g() )}}
if (letters[v] === 'h'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.h() )}}
if (letters[v] === 'i'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.i() )}}
if (letters[v] === 'j'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.j() )}}
if (letters[v] === 'k'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.k() )}}
if (letters[v] === 'l'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.l() )}}
if (letters[v] === 'm'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.m() )}}
if (letters[v] === 'n'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.n() )}}
if (letters[v] === 'o'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.o() )}}
if (letters[v] === 'p'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.p() )}}
if (letters[v] === 'q'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.q() )}}
if (letters[v] === 'r'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.r() )}}
if (letters[v] === 's'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.s() )}}
if (letters[v] === 't'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.t() )}}
//if (letters[v] === 'u'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.u() )}}
if (letters[v] === 'v'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.v() )}}
if (letters[v] === 'w'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.w() )}}
if (letters[v] === 'x'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.x() )}}
if (letters[v] === 'y'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.y() )}}
if (letters[v] === 'z'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.z() )}}
if (letters[v] === 'aa'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.aa() )}}
if (letters[v] === 'ab'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ab() )}}
if (letters[v] === 'ac'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ac() )}}
if (letters[v] === 'ad'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ad() )}}
if (letters[v] === 'ae'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ae() )}}
if (letters[v] === 'af'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.af() )}}
//if (letters[v] === 'ag'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ag() )}}
//if (letters[v] === 'ah'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ah() )}}
//if (letters[v] === 'ai'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ai() )}}
//if (letters[v] === 'aj'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.aj() )}}
//if (letters[v] === 'ak'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ak() )}}
//if (letters[v] === 'al'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.al() )}}
//if (letters[v] === 'am'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.am() )}}
//if (letters[v] === 'an'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.an() )}}
if (letters[v] === 'ao'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(reasoningQuestions.ao() )}}

//if (letters[w] === 'o'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.aac() )}}
//if (letters[w] === 'p'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(keyStageOneQuestions.aad() )}}

//Reasonong1
if (letters[a] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.a() )}}
if (letters[a] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.b() )}}
if (letters[a] === 'c'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.c() )}}
if (letters[a] === 'd'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.d() )}}
if (letters[a] === 'e'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.e() )}}
if (letters[a] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.f() )}}
if (letters[a] === 'g'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.g() )}}
if (letters[a] === 'h'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.h() )}}
if (letters[a] === 'i'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.i() )}}
if (letters[a] === 'j'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.j() )}}
if (letters[a] === 'k'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.k() )}}
if (letters[a] === 'l'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.l() )}}
if (letters[a] === 'm'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.m() )}}
if (letters[a] === 'n'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.n() )}}
if (letters[a] === 'o'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.o() )}}
if (letters[a] === 'p'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.p() )}}
if (letters[a] === 'q'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.q() )}}
if (letters[a] === 'r'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1ReasoningQuestions.r() )}}

//English1
if (letters[b] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1EnglishQuestions.a() )}}
if (letters[b] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS1EnglishQuestions.b() )}}


//English2 
if (letters[c] === 'a'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.a() )}}
if (letters[c] === 'b'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.b() )}}
if (letters[c] === 'c'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.c() )}}
if (letters[c] === 'd'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.d() )}}
if (letters[c] === 'e'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.e() )}}
if (letters[c] === 'f'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.f() )}}
if (letters[c] === 'g'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.g() )}}
if (letters[c] === 'h'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.h() )}}
if (letters[c] === 'i'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.i() )}}
if (letters[c] === 'j'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.j() )}}
if (letters[c] === 'k'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.k() )}}
if (letters[c] === 'l'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.l() )}}
if (letters[c] === 'm'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.m() )}}
if (letters[c] === 'n'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.n() )}}
if (letters[c] === 'o'){for (let xx = 0 ; xx < 20 ; xx++){ setQuestions2.push(kS2EnglishQuestions.o() )}}






console.log(setQuestions2)
for ( let xy = 0; xy < setQuestions2.length; xy++){setQuestions2[xy].id = xy+1}
for ( let xy = 0; xy < setFractions.length; xy++){setFractions[xy].id = xy+1}

console.log(setQuestions2)
const handleGo = () => {dispatch(holdTheQuestions(setQuestions2)); dispatch(holdTheFractions(setFractions))}
const handleGoEasy = () => {dispatch(holdTheQuestions(setQuestions2Easy)); dispatch(holdTheFractions(setFractionsEasy))}
let abc=true

        return (<p>{choice} <button className="go" onClick={handleGo} > Exam questions</button>
        {choice} <button className="go" onClick={handleGoEasy} >Slightly easier practice questions</button></p>)
   

}}
