function rnd (range) { let num = (Math.floor(Math.random()* range)); return num}

const nouns = [['chicken', 'chickens'], ['sweet','sweets'], ['drink', 'drinks'], ['car', 'cars'], ['dog', 'dogs'], ['cat', 'cats'], ['hamster', 'hamsters'], ['gerbil', 'gerbils'], ['fish', 'fish'], ['sheep', 'sheep'], ['cow', 'cows'], ['bag', 'bags'], ['fork', 'forks'], ['chair', 'chairs'], ['table', 'tables'], ['phone', 'phones'], ['book', 'books'], ['tree', 'trees'] ]
const people = [ 'Oliver', 'Dawn', 'Joseph', 'Jacob', 'Thomas', 'Christine', 'John', 'Charlotte', 'Amelia', 'Ava', 'Sophia', 'Isabella', 'Mia', 'Eve', 'Liam', 'James', 'William', 'Ben', 'Lucas', 'Jack', 'Owen', 'Molly', 'Naya', 'Kabir', 'Ishaan', 'Jai', 'Amar', 'Aditi', 'Reva', 'Shylah', 'Foluke', 'Chidera', 'Imamu', 'Chioma' , 'Ikem', 'Olatunde', 'Adjoa', 'Kasper', 'Konrad', 'Jadwiga', 'Ludwik', 'Wladyslaw', 'Wanda', 'Sonya', 'Mariya', 'Olena', 'Antonina', 'Victoriya', 'Oleksiy', 'Liliya', 'Maksim', 'Volodymyr', 'Vitali' ]
const commodity = ['flour', ' sugar', 'cement', 'sand', 'berries', 'mushrooms', 'ice', 'butter', 'gravel', 'cereal' ]
const liquid = ['water', 'juice', 'cola', 'lemonade', 'petrol', 'milk', 'oil']
const activity = ['swims', 'runs', 'walks', 'jogs', 'drives']
const shape = [['triangle', 3], ['square', 4], ['pentagon', 5], ['hexagon', 6], ['heptagon', 7], ['octagon', 8] ]

export function determiner // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["Joe has three dogs", "They play a game of chess",  "Oliver has won twice", "This apple is mouldly", "I ate some chips", "My cat has died", "Seven people won the lottery", 
    "I peeled an orange",  "Thank you for my present", "I climbed the stairs",  "John made his bed",  "Rio cried for the third time", "This game is boring",  "That holiday was amazing",  "I drank some tea", 
    "It was the first world war",  "I played with our dog",  "I bought some sweets",  "I brought my mobile phone",   "This song is brilliant" ]
   let answers = [ "three", "a", "twice", "This", "some", "My", "Seven", "an", "my", "the", "his", "third", "This", "That",  "some", "first", "our", "some", "my", "This"]
   let instruct2= `Highlight the determiner in the sentence`; let answerNum = [2, 2, 3, 0, 2, 0, 0, 2, 3, 2, 2, 4, 0, 0, 2, 3, 3, 2, 2, 0     ]
    
    let instruct1 ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    let eng = 0; let questionArrayed = []; let wO = answers[qu1]
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function conjunction // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["After finishing her tea, Sophie went out to play.", "She complained to the manager after eating her meal,", 
   "Once her homework was completed, Raman went to bed.", "He went home after the football match as he felt sick.", 
   "The ghost train is a great ride although it is for children.", "I wanted to go out for a walk but it was too cold.","I cried all day long because I lost my purse.",
   "We played on the beach until the sun went down.","I put up my umbrella but it was not raining.", "Although I was terrified I climbed up the ladder.", 
   "Sit down at the table before the food arrives.", "The girls were tired as they had not been to sleep.",  "We slammed the door shut as the man stared furiously.", 
   "This is the child who fell in the lake.", "This is the place where my parents got married.", "I know a girl whose brother is a famous actor.", 
   "Raj watched a film which was really scary.", "I found a playground which is by a lake.", "That is the girl who is joining the England team.", 
   "I was sitting in the park when the thunder storm started.", "I am friends with a girl who has a tattoo.", "This is the café where I had a huge milkshake.", 
   "We can have a drink while we are waiting." ]
   let answers = [ "After", "after", "Once", "after", "although", "but", "because", "until", "but", "Although", "before", "as", "as", "who", "where", "whose", 
   "which", "which", "who", "when", "who", "where", "while" ]
   let instruct2= `Highlight the conjunction`; //let answerNum = [2, 2, 3, 0, 2, 0, 0, 2, 3, 2, 2, 4, 0, 0, 2, 3, 3, 2, 2, 0     ]
    
    let instruct1 ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    let eng = 0; let questionArrayed = []; let wO = answers[qu1]
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}


export function preposition // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["JI stood beside my brother for the photograph", "We flew above the clouds", "Sara dived under the huge wave", 
   "The snow was soft beneath our feet.", "Phylis walked slowly along the lane.", "I climbed over the rocks with care", "We could both fit underneath the big.", 
   "The friends sheltered under the cover of a tree.", "The pub is beyond the next field.", "He put the jacket over the thick jumper.", 
   "I looked through the dirty indow.", "I walked beside Suki the whole way.", "We were warm under the red banket.", "It is much further along this main road.", 
   "The new shop is opposite the church.", "Gerard tried to run around the whole lake.", "The ground beneath this tree is still dry.", 
   "The flour is on the shelf above the cereal.", "Mum put the baby under a warm blanket.", "Spring road is just beyond the playing field." ]
   let answers = [ "beside", "above", "under", "beneath", "along", "over", "underneath", "under", "beyond", "over", "through", "beside", "under", "along", "opposite", 
   "around", "beneath", "above", "under", "beyond" ]
   let instruct2= `Highlight the preposition`; let answerNum = [2, 2, 3, 0, 2, 0, 0, 2, 3, 2, 2, 4, 0, 0, 2, 3, 3, 2, 2, 0     ]
    
    let instruct1 ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    let eng = 0; let questionArrayed = []; let wO = answers[qu1]
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function pronoun // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["This is the child who fell in the lake.", "This is the place where my parents got married.", "I know a girl whose brother is a famous actor.", 
   "Raj watched a film which was really scary.", "I found a playground which is by a lake.", "That is the girl who is joining the England team.", 
   "I was sitting in the park when the thunder storm started.", "I am friends with a girl who has a tattoo.", "This is the café where I had a huge milkshake.", 
   "The black dog is mine.", "My brother is friends with yours.", "The red gloves are yours.", "This is my bedroom, yours is along the corridor.",
   "He is a very old friend of mine.", "His name is Vinod.", "Their garden is huge.", "The trainers are hers.", "Her house is at the top of the hill.", 
    "This car is much better than ours.", "Give them back, they are mine."]
   let answers = [ "who", "where", "whose", "which", "which", "who", "when", "who", "where", "mine", "yours", "yours", "yours", "mine", "his", "their", "hers",
   "hers", "ours", "mine" ]
  
   let instruct2= `Highlight the pronoun in the sentence`; let answerNum = [2, 2, 3, 0, 2, 0, 0, 2, 3, 2, 2, 4, 0, 0, 2, 3, 3, 2, 2, 0     ]
    
    let instruct1 ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    let eng = 0; let questionArrayed = []; let wO = answers[qu1]
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}










export function semicolon // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["May loves ice cream her favourite flavour is vanilla.", "Ravir was really tired he has travelled for 2 days.", 
   "It was a cold day I put on my gloves as soon as I could.", "Amit hates cheese he always asks for ham.", "Ravi is feeling sad he wants to be alone.", 
   "The sandwich tasted horrible she put it in the bin. ", "I opened the box it was full of sweets.", "I put up my umbrella it was not raining.", 
   "The girls were tired they had not been to sleep.", "We watched in awe the fireworks were amazing.", "I made the tea Priti made the coffee.", 
   "I like pink my sister likes purple."]   
   let answers = [ "cream", "tired", "day", "cheese", "sad", "horrible", "box", "umbrella", "tired", "awe", "tea", "pink"]
   let instruct2= `Place the appropriate semi-colon, in this sentence`; let questionArrayed = [];

    let instruct1 =`choose the button for the word before the semi-colon`; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
    //let qu=  questionPhrase[qu1]; 
    let eng = 3; let wO = ""            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function comma // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["Yesterday we had roast beef for dinner. ", "Last week I stayed at my cousins house.",
   "Before breakfast Suki ate a bar of chocolate.", "We visited the house in London where I lived when I was was young.", 
   "My favourite pizza toppings are cheese ham and pepperoni. ", "My best friends are Orlenka Julia and Mikala.", "I need to buy socks gloves and hats.", 
   "I am going to ask for clothes music and soaps", "On the list are apples pears and oranges", "At last I've finally won a race.", 
   "Gary is a baker who makes bread for the village.", "I enjoy swimming running and cycling."]   
   let answers = [ "Yesterday", "week",  "breakfast", "London", "cheese", "Orlenka", "socks", "clothes", "apples", "last", "baker", "swimming"]
   let instruct2= `Place the appropriate comma, in this sentence`; let questionArrayed = [];

    let instruct1 =`choose the button for the word before the comma`; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; 
    for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
    let qu=  questionArrayed[qu1]; 
    //let qu=  questionPhrase[qu1]; 
    let eng = 3; let wO = ""            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}


export function hyphen // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["A well behaved dog is welcome here.", "A well known footballer is playing in this match.", "Twenty two men attended the meeting.", 
   "I am scared of man eating sharks.", "I only want to work part time at the moment.", "I always drink sugar free Cola.", 
   "The dress was old fashioned and a horrible colour.", "I've got fifty five football cards in my collection.", "He was sporty and very good looking.", 
   "The fish is coated in batter and then deep fried.", "I love pink my sister loves blue.", "This book is really good I have read it twice.", 
   "The rule is simple don't talk in class.", "I got the result of my test it's ninety percent.", "I cook the food he eats it all.", 
   "I had my favourite drink at the café hot chocolate.", "I have two sisters Siny and Irene.", "Musa is scared of only one thing spiders.", 
   "She has two brothers George and Tim.", "It is common sense don't play with knives.", "I drink coffee my brother drinks tea."]   
   let answers = [ "well", "well", "Twenty", "man", "part", "sugar", "old", "fifty", "very", "deep", "pink", "good", "simple", "test", "food", 
   "café", "sisters", "thing", "brothers", "sense", "coffee"]
      let instruct2= `Place the appropriate hyphen or dash in this sentence`; let questionArrayed = [];
    
   let instruct1=`choose the button for the word before the hyphen` ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; for (let i = 0; i < questionPhrase.length; i++) 
   { questionArrayed[i] = questionPhrase[i].split(" ")}
   let qu=  questionArrayed[qu1];  let eng = 3; let wO = ""    
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function colon // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["Asma hated christmas food brussels sprouts, christmas pudding, mince pies.", "My cousins are American their names are Dylan and James.", 
   "Lucy has two hobbies dancing and playing netball.", "I need to buy toilet roll, flour, eggs.", "Pierre has three sisters Camille, Sofia and Sara.", 
   "The room needed two more things a chair and a television.", "My shopping list tea, dog food, light bulb.", "Joe has two balls a basketball and a football.", 
   "I need to remember my wallet, my keys and my phone.", "I want to get T-shirts, jumpers and socks.", "Sally has two pets a dog and a cat.", 
   "We must buy stamps, envelopes and tape.", "Chen has 3 best friends Siny, Dylan and Rama.", "My favoutite colours are red, purple and blue.", 
   "I couldn't decide what sort of film to watch comedy or adventure."]   
   //let answersA = [ "Asma hated christmas food: brussels sprouts, christmas pudding, mince pies.", "My cousins are American: their names are Dylan and James.",    "Lucy has two hobbies: dancing and playing netball.", "I need to buy: toilet roll, flour, eggs.", "Pierre has three sisters: Camille, Sofia and Sara.",    "The room needed two more things: a chair and a television.", "My shopping list: tea, dog food, light bulb.", "Joe has two balls: a basketball and a football.",    "I need to remember: my wallet, my keys and my phone.", "I want to get: T-shirts, jumpers and socks.", "Sally has two pets: a dog and a cat.",    "We must buy: stamps, envelopes and tape.", "Chen has 3 best friends: Siny, Dylan and Rama.", "My favoutite colours are: red, purple and blue.",  "I couldn't decide what sort of film to watch: comedy or adventure."]
   let answers = [ "food", "American", "hobbies", "buy", "sisters", "things", "list", "balls", "remember", "get", "pets", "buy", "friends", "are", "watch"]
      let instruct2= `Place the appropriate colon in this sentence`; let questionArrayed = [];
      let instruct1 =`choose the button for the word before the colon` ; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; for (let i = 0; i < questionPhrase.length; i++) { questionArrayed[i] = questionPhrase[i].split(" ")}
      let qu=  questionArrayed[qu1];  let eng = 3; let wO = ""    
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function formal // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["I wasn't happy so I left early.", "I had an awesome time!", "I am totally in love with you!", "The burgers that Dad cooked were gross!", 
   "The movie wasn't very good.", "We all had a boogie on the dance floor.", "I didn't even read my texts.", "The kids loved playing in the pool.", 
   "We went to my room to listen to some tunes.", "I wasn't feeling great.", "I couldn't find my mobile.", "I finished my text with an emoji.", 
   "I apologise for my rude behaviour.", "I look forward to meeting your mother.", "I regret to inform you that there is a delay.", "We appreciate your advice.", 
   "They completed the task promptly.", "I greeted my cousins when they arrived.", "I would like to remind you walk slowly due to ice.", 
   "We would be delighted to attend.", "The children played quietly in the living room.", "The seat at the end of the row is vacant.", 
   "The manager attempted to rectify the problem.", "I do not appreciate your negative attitude."]   
   let answers = [ "informal", "informal", "informal", "informal", "informal", "informal", "informal", "informal", "informal", "informal", "informal", "informal", 
   "formal", "formal", "formal", "formal", "formal", "formal", "formal", "formal", "formal", "formal", "formal", "formal"]
      let instruct2= `Is this sentence written in formal or informal English?`; 
      let instruct1 = ["formal", "informal"]; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""    
            
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function noun // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["We must not jump in the pool.", "I take off the wrapping paper carefully.", "The lake looks peaceful in the this picture.", 
   "The cold, white snow covered the garden.", "The children sat quietly for a change.", "He ate his burger quickly.", "Sarah and Matt are going to ice the cake.", 
   "I will knit you a jumper.", "A large suitcase will not fit in my car.", "It was a delicious meal.", "I ordered chicken and chips.", "Leo tried hard to be kind.", 
   "We must light the camp fire.", "The lights twinkled brightly.", "The snowy mountains looked beautiful but imposing.", 
   "Kasja patiently waited for the results of the test.", "Oscar wrote an important email.", "Taj needed to glue her mug back together.", 
   "The fast runners quickly ran past me.", "She always tried her best in her exams."]   
   let answers = [ "verb", "adverb", "noun", "adjective", "verb", "adverb", "verb", "noun", "adjective", "adjective", "noun", "adverb", "verb", "noun", "adjective", 
   "adverb", "noun", "verb", "adjective", "abverb"]
      let instruct2a= ["Is the word jump a verb, noun, adjective or adverb?", "Is the word carefully a verb, noun, adjective or adverb?", 
      "Is the word lake a verb, noun, adjective or adverb?", "Is the word white a verb, noun, adjective or adverb?", "Is the word sit a verb, noun, adjective or adverb?", 
      "Is the word quickly a verb, noun, adjective or adverb?", "Is the word ice a verb, noun, adjective or adverb?", 
      "Is the word jumper a verb, noun, adjective or adverb?", "Is the word large a verb, noun, adjective or adverb?", 
      "Is the word delicious a verb, noun, adjective or adverb?", "Is the word chicken a verb, noun, adjective or adverb?", 
      "Is the word hard a verb, noun, adjective or adverb?", "Is the word light a verb, noun, adjective or adverb?", "Is the word lights a verb, noun, adjective or adverb?", 
      "Is the word snowy a verb, noun, adjective or adverb?", "Is the word patiently a verb, noun, adjective or adverb?", "Is the word email a verb, noun, adjective or adverb?", 
      "Is the word glue a verb, noun, adjective or adverb?", "Is the word fast a verb, noun, adjective or adverb?", "Is the word always a verb, noun, adjective or adverb?"]; 
      let instruct1 = ["verb", "noun", "adjective", "adverb"]; let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""    
          let instruct2 = instruct2a[qu1]  
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function statement // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["Are you coming out later.", "Put the tins in the cupboard.", "It's lovely outside this afternoon.", "What an amazing concert that was.", 
   "Take the top off the milk.", "How dare you speak to me like that.", "How many goals did you score.", "I have made an apple pie.", "What an absolute disaster.", 
   "When will it be dinner time.", "Come over here and see this.", "Go and take a shower before tea.", "What are you wearing to the party.", 
   "You have ruined everything.", "Where are going this afternoon.", "Be quiet while I'm talking.", "The dog is very dirty.", "What is the date today.", 
   "You must stop when you come to the main road.", "The dog wants some dinner.", "Do you want to go to bed.", "I can't believe this is happening.", 
   "Look what you've done.", "You must get ready now.", "Chess is a difficult game.", "You can never have too many pairs of shoes.", "What is the meaning of life.", 
   "Will you let me help you.", "Listen to what I'm telling you.", "Be careful with those sharp scissors."]   
   let answers = [ "question", "command", "statement", "exclamation", "command", "exclamation", "question", "statement", "exclamation", "question", "command", "command",
    "question", "exclamation", "question", "exclamation", "statement", "question", "command", "statement", "question", "exclamation", "exclamation", "command", 
    "statement", "statement", "question", "question", "command", "command"]
    let instruct1 = ["question", "command", "statement", "exclamation"]
        let instruct2= `Is this sentence a question, command, statement or exclamation?`;
    let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""    
          
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function synonym // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["stop", "optimistic", "error", "quiet", "ruin", "accurate", "pause", "shy", "unique", "dirty", "obese", "happy", "sad", "gather", "inquire", 
   "ancient", "vast", "thought", "young", "huge", "dawn", "love", "ugly", "upset", "small", "yell", "jump", "regular", "busy", "mend"]   
   let answers = [ "halt", "hopeful", "mistake", "tranquil", "destroy", "precise", "stop", "timid", "rare", "smudged", "fat", "cheerful", "miserable", "collect", "ask", 
   "old", "big", "idea", "youthful", "enourmous", "sunrise", "adore", "hideous", "tearful", "tiny", "bellow", "leap", "often", "hectic", "fix"]
    let instruct1a = [["halt", "move", "motion", "speed"], ["hopeful", "happy", "negative", "lazy"], ["mistake", "practice", "attempt", "complete"], 
    ["tranquil", "flat", "countryside", "rough"], ["destroy", "hurt", "build", "attack"], ["precise", "neat", "delicate", "smooth"], ["stop", "move", "follow", "repeat"], 
    ["timid", "nervous", "quiet", "scared"], ["rare", "bright", "different", "alone"], ["smudged", "dirty", "foolish", "creased"], ["fat", "shiny", "unkempt", "grand"], 
    ["cheerful", "elegant", "quiet", "quaint"], ["miserable", "grumpy", "serious", "fierce"], ["collect", "search", "hide", "hunt"], ["ask", "look", "make", "advise"], 
    ["old", "broken", "fragile", "grand"], ["big", "small", "stong", "thick"], ["idea", "plan", "diary", "build"], ["youthful", "baby", "early", "start"], 
    ["enourmous", "giant", "multiple", "building"], ["sunrise", "start", "early", "twilight"], ["adore", "enjoy", "consider", "pray"], 
    ["hideous", "giant", "tired", "broken"], ["tearful", "loud", "shout", "tired"], ["tiny", "narrow", "low", "dark"], ["bellow", "loud", "noise", "speak"], 
    ["leap", "run", "high", "skip"], ["often", "occasional", "many", "sometimes"], ["hectic", "lazy", "crowd", "full"], ["fix", "flatten", "improve", "grow"]]
    
       let qu1 = rnd(questionPhrase.length); let instruct2= `Highlight the correct synonym of: ` ;
    let img = "";  let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""    
          let instruct1 = instruct1a[qu1]
          // put the instruct1 array in a random order
            let instruct1b = []
            for (let i = 0; i < instruct1.length; i++) {
                let r = rnd(instruct1.length)
                if (instruct1b.indexOf(instruct1[r]) === -1) {
                    instruct1b.push(instruct1[r])
                } else {
                    i--
                }
            }
            instruct1 = instruct1b
 
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}



export function antonym // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= ["boy", "tranquil", "rich", "finish", "rough", "enemy", "entrance", "foolish", "artificial", "front", "unusual", "absent", "make", "over", 
   "stunning", "shy", "arrogant", "kind", "warm", "begin", "birth", "heat", "repair", "simple", "perfect", "whispered", "many", "huge"]   
      let answers = [ "girl", "busy", "destitute", "begin", "gentle", "friend", "exit", "wise", "real", "rear", "common", "present", "destroy", "under", "ugly", "bold", 
      "modest", "mean", "chilly", "conclude", "death", "cool", "break", "hard", "faulty", "bellowed", "few", "tiny"]
    let instruct1a = [["girl", "female", "man", "child"], ["busy", "quiet", "full", "empty"], ["destitute", "popular", "wealthy", "popular"], 
    ["begin", "terminate", "win", "complete"], ["gentle", "waves", "sharp", "boisterous"], ["friend", "war", "battle", "helper"], ["exit", "doorway", "gate", "escape"],
    ["wise", "brave", "coward", "trick"], ["real", "fake", "plastic", "pretend"], ["rear", "exit", "door", "begin"], ["common", "rare", "unique", "precious"],
    ["present", "missing", "descent", "holiday"], ["destroy", "find", "formula", "create"], ["under", "opposite", "cover", "across"], 
    ["ugly", "dull", "sparkling", "shine"], ["bold", "timid", "fearless", "embarrassed"], ["modest", "bold", "stupid", "clever"], ["mean", "nice", "angry", "selfish"],
    ["chilly", "tepid", "scalding", "snow"], ["conclude", "start", "race", "index"], ["death", "old", "begin", "end"], ["cool", "ice", "warm", "weather"], 
    ["break", "fix", "collapse", "ruined"], ["hard", "easy", "spot", "soft"], ["faulty", "ugly", "old", "ancient"], ["bellowed", "silence", "loud", "noise"], 
    ["few", "lots", "small", "crowded"], ["tiny", "massive", "few", "several"]]
   
    let qu1 = rnd(questionPhrase.length); let instruct2= `Highlight the correct antonym of: `;
    let img = ""; let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""    
          let instruct1 = instruct1a[qu1]
          // put the instruct1 array in a random order
            let instruct1b = []
            for (let i = 0; i < instruct1.length; i++) {
                let r = rnd(instruct1.length)
                if (instruct1b.indexOf(instruct1[r]) === -1) {
                    instruct1b.push(instruct1[r])
                } else {
                    i--
                }
            }
            instruct1 = instruct1b
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}



export function clauses // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= [["After finishing her tea, ","Sophie went out to play."], ["She complained to the manager", "after eating her meal."], 
   ["Once her homework was completed, ","Raman went to bed."], [ "He went home after the football match"," as he felt sick."], 
   ["The ghost train is a great ride"," although it is for children."], [ "I wanted to go out for a walk"," but it was too cold."], 
   [ "I cried all day long ","because I lost my purse."], ["We played on the beach ","until the sun went down."], ["I put up my umbrella ","but it was not raining."], 
   [ "Although I was terrified"," I climbed up the ladder."], ["Sit down at the table ","before the food arrives."], 
   [ "The girls were tired ","as they had not been to sleep."], [ "We slammed the door shut ","as the man stared furiously."], 
   ["This is the child ","who fell in the lake."], [ "This is the place ","where my parents got married."], 
   [ "I know a girl ","whose brother is a famous actor."], ["Raj watched a film ","which was really scary."], [ "I found a playground ","which is by a lake."], 
   ["That is the girl ","who is joining the England team."], ["I was sitting in the park ","when the thunder storm started."], ["I am friends with a girl ","who has a tattoo."], 
   ["This is the café ","where I had a huge milkshake."]  ] 
   let answers = [ "Sophie went out to play", "She complained to the manager", "Raman went to bed", "He went home after the football match", 
   "The ghost train is a great ride", "I wanted to go out for a walk", "because I lost my purse", "until the sun went down", "but it was not raining", 
   "Although I was terrified", "before the food arrives", "as they had not been to sleep", "as the man stared furiously", "who fell in the lake", 
   "where my parents got married", "whose brother is a famous actor", "which was really scary", "which is by a lake", "who is joining the England team", 
   "when the thunder storm started", "who has a tattoo", "where I had a huge milkshake"]
    let instruct1a = ["Highlight the main clause in the following sentence.", "Highlight the main clause in the following sentence.", 
    "Highlight the main clause in the following sentence.", "Highlight the main clause in the following sentence.", 
    "Highlight the main clause in the following sentence.", "Highlight the main clause in the following sentence.", 
    "Highlight the subordinate clause in the following sentence.", "Highlight the subordinate clause in the following sentence.", 
    "Highlight the subordinate clause in the following sentence.", "Highlight the subordinate clause in the following sentence.", 
    "Highlight the subordinate clause in the following sentence.", "Highlight the subordinate clause in the following sentence.", 
    "Highlight the subordinate clause in the following sentence.", "Highlight the relative clause in the following sentence.", 
    "Highlight the relative clause in the following sentence.", "Highlight the relative clause in the following sentence.", 
    "Highlight the relative clause in the following sentence.", "Highlight the relative clause in the following sentence.", 
    "Highlight the relative clause in the following sentence.", "Highlight the relative clause in the following sentence.", 
    "Highlight the relative clause in the following sentence.", "Highlight the relative clause in the following sentence"]
        let qu1 = rnd(questionPhrase.length); let instruct2= instruct1a[qu1] + "  " + questionPhrase[qu1][0] + questionPhrase[qu1][1];
    let img = ""; let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 0; let wO = ""    ; let instruct1 = ""
          
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

export function questions // find different combinations of coins that equal the same amounts of money
()  {
   let questionPhrase= [""]   
   let answers = [ "What are you going to wear to the party","Who do you think will win","Have you read this book","Why can't they play football later",
   "What happened in the end","Where is the ipad","Shall we buy a lottery ticket","How many fish are in this pond","Why is the sky blue","What is in my pocket",
   "Do you want to watch the ski race","Have you decorated your christmas tree","What did you have to eat","Are they going to the hairdressers","Why are they crying"]
       let instruct1a = [["What are you going to wear to the party","The party is at the end of the month","We are going to the party together"], 
         ["Who do you think will win","The best team will win","They were delighted to win"], 
         ["Have you read this book","This is my favourite book","I like the author of this book"],
            ["Why can't they play football later","They wanted to play football after school","They both played football for the team"],
            ["What happened in the end","They wanted to know what happened in the end","They already knew what happened in the end"],
            ["Where is the ipad","She knew where the ipad was kept","She wanted to know where the ipad was kept"],
            ["Shall we buy a lottery ticket","We should buy a lottery ticket","We will buy a lottery ticket"],
            ["How many fish are in this pond","There are fish is this pond","you can fish in this pond"],
            ["Why is the sky blue","The sky is blue","I prefer it when the sky is blue"],
            ["What is in my pocket","There are many things in my pocket","I told him what is in my pocket"],
            ["Do you want to watch the ski race","You watched the ski race","You should watch the ski race"],
            ["Have you decorated your christmas tree","Decorate your christmas tree","you must decorate your christmas tree"], 
            ["What did you have to eat","You must have something to eat","You have to eat something"],
            ["Are they going to the hairdressers","They went to the hairdressers","They need to go to the hairdressers"],
            ["Why are they crying","They are still crying","I hope they have stopped crying"]]
    
        let instruct2= `Which one of the following sentences should have a question mark at the end?`;
    let img = ""; let qu1 = rnd(questionPhrase.length); let an = answers[qu1]; let qu=  questionPhrase[qu1]; let eng = 2; let wO = ""; let instruct1 = instruct1a[qu1]   
          
const question = {id: 1, image:img, question: qu, answer: an, workingOut: wO, marks: 1, instruct1: instruct1, instruct2: instruct2, eng: eng}
return question}

//// Need to check the code for the last one
 // and need to move the lists of questions through sats.js and arithQuestions so they all link up 