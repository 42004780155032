import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../context/userAuthContext";
import app, {auth, db} from '../firebase-config'
import { setDoc, getFirestore, doc,  getDocs, addDoc, collection, querySnapshot  } from "firebase/firestore";
import {fetchUserFromFirestore} from '../redux/userFireSlice'
import { useDispatch, useSelector } from "react-redux";

export async function addUserFirestore (name, initialAchievements){
  
  console.log('add user')
  try {
    const docRef = await setDoc(doc(db, "users", auth.currentUser.uid), {
      first: name,
      userId: auth.currentUser.uid,
      points: initialAchievements.points,
      dateJoined: Date(),
      lastLogin: Date(),
      reviseList: initialAchievements.reviseList,
      modulesCompleted: initialAchievements.modulesCompleted,
      testResults: initialAchievements.testResults,
      sats: []

          });
    console.log("new user written with ID: ", auth.currentUser.uid);
   
  } catch (e) {
    console.error("Error adding document: ", e);
  }   ;}




const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const initialAchievements = useSelector((state) => state.userFireSet)
  const handleLogin = () => {
    navigate("/home")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password);
      navigate("/");
      addUserFirestore(email, initialAchievements);
      dispatch(fetchUserFromFirestore(auth.currentUser.uid)) 
      console.log(auth.currentUser.uid, auth.currentUser)
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="login">
        <h2 >Signup</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="loginForm" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="loginForm" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="loginButton">
            <button className="loginButton">
            Agree to essential cookies, privacy policy and <h2> Sign up </h2>
            </button>
          </div>
        </Form>
      </div>  
      <div className="loginForm">  Already have an account? </div>
      <button className='loginButton' onClick={()=>{handleLogin()}}>Log in</button>
    
    </>
  );
};

export default Signup;
