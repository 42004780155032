import React from 'react';

export function RulesOfPlay (){

    return (
    <div  className="rules">
        <ul>
            <li>Click Show to see the answer, and Next to move on </li>
            <li>When you have learnt a Question and Answer, click Know It </li>
            <li>Tricky questions can be added to your Revise list</li>
            <li>Log in to keep progress between sessions</li>
        </ul>
    </div>
    )
}
