import React from "react"; 
import { useDispatch, useSelector } from "react-redux";
import {fetchDataFromFirestore, setCatalogueF, setLevel, setMasterHigh, setMasterLow, setMasterId} from '../redux/questionSlice'
import { KnowItBox } from "../knowitcomponents/KnowItBox"; import { auth } from "../firebase-config";
import { Navigate } from "react-router-dom";

export function Catalogue (){  
         const dispatch = useDispatch();
   ; // get the master body document into an Array. 
    const catalogue = useSelector((state) => state.questionSet.master);  // {HighLevelSubject: "Anatomy" , LowLevelCategory: "Bones"  , categoryId: 1 }, 
    const High = useSelector((state) => state.questionSet.masterHigh); // this is the high level subject held in state. 
    const modulesCompleted = useSelector((state) => state.userFireSet.modulesCompleted)
    //const modulesCompleted =[...modulesComplete]
    console.log (modulesCompleted)
    let idCompleted = []
    for (let x =0 ; x< modulesCompleted.length; x++)
        {let g = idCompleted.push(modulesCompleted[x].id)} 
    console.log(idCompleted)
    

let ListItems = useSelector((state) => state.questionSet.catalogueF)
let level = useSelector((state) => state.questionSet.level)

    let unique = [    ];
        for (let e = 0; e< catalogue.length; e++) { unique.push(catalogue[e].HighLevelSubject)}

    function onlyUnique(value, index, self) { return self.indexOf(value) === index; }
          let uniqueCatalogue = unique.filter(onlyUnique);
    
    const highListItems = uniqueCatalogue.map((entry)=> 
        <li key={entry}><div className='highList ' onClick={()=> {handleListClick(entry)}} value ={entry} >{entry}</div></li>)  // generates the dynamic high list
        
    const handleSubListClick = (e)=>{ // e= e-1
       ; 
        let f= catalogue[e].LowLevelCategory; 
        dispatch(setMasterId({id: e, cat: f}));
        console.log(' e: ' + e + ', cat: ' + f + 'f: ' + catalogue[e] ) //low list returns the category  -- NB wont work properly until array is 0 indexed
       ;dispatch(setLevel(2)) 
       dispatch(fetchDataFromFirestore({collection: catalogue[e].HighLevelSubject, document: catalogue[e].LowLevelCategory})
       )
     }                           // moves on to KNOWIt box

    const handleListClick = (e)=>{ dispatch(setMasterHigh(e)); dispatch(setLevel(1)) // on clicking the list a new Master High category is dispatched.    
           //let catalogueFocus = catalogue.filter((entry) => {return entry.HighLevelSubject === High}) // this compares with the high catalogue and returns the lows from that high category 
           //dispatch(setCatalogueF(catalogueFocus));
         }
    let lowList
    const lowListItems = ListItems.map((entry)=> {
        
        let f = idCompleted.filter((e) => e === entry.categoryId ); 
        if (f.length === 0){ //console.log(f) ; 
            return (   ////////////////works but is one out
        <li  key={entry.categoryId}><div className={'lowList highlight'} onClick={()=> {handleSubListClick(entry.categoryId)}}>{entry.LowLevelCategory}</div></li>) }
            else {  return(
        <li  key={entry.categoryId}><div className={'lowList'} onClick={()=> {handleSubListClick(entry.categoryId)}}>{entry.LowLevelCategory}</div></li>)}})
               
        const user = auth.currentUser;
        if(user === null) {return ( <Navigate  to="/home" /> );}
          // const lowListItems = catalogueFocus.map((entry)=> 
           //     <li  key={entry.categoryId}><div onClick={()=> {handleSubListClick(entry.LowLevelCategory)}}>{entry.LowLevelCategory}</div></li>)  // generates the dynamic low list
                         // this brings up the low level list
         
if (level === 0) { return ( <div className="category"> Please choose a subject: <ul className="list">  {highListItems}   </ul></div> ) }
else if (level === 1)  { return ( <div className="category"> Please choose a category <ul className="list"> {lowListItems} </ul> </div> ) }  
else if (level === 2)  { return ( <div className="category"><KnowItBox/></div>)} 
else {return <div className="category">something has gong wrong</div>}  }           // the default return jsx is the high level list
   